/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import dateUtils from "../../utils/dateUtils";

export default class TableBody extends Component {
  createKey = (item, column) => {
    return item._id + (column.path || column.key);
  };

  addDisplay(display) {
    if (display) {
      let d = display.split(" ");
      let ret = " d-none";
      for (let i = 0; i < d.length; i++) {
        ret += " d-" + d[i] + "-table-cell";
      }
      return ret;
    } else {
      return "";
    }
  }

  render() {
    const { data, columns, totalsColumns } = this.props;
    let totals = {};
    let totalsFraction = {};
    let isMinutes = {};
    data.forEach(item => {
      if (totalsColumns) {
        totalsColumns.forEach(totalsColumn => {
          let strValue = item[totalsColumn];
          let value = 0;
          if (strValue === "" || isNaN(strValue)) {
            if (strValue && strValue.indexOf("hours") !== -1) {
              let parts = strValue.split(" ");
              if (parts.length === 2 && parts[1] === "hours") {
                value = parseFloat(parts[0]) * 60;
                isMinutes[totalsColumn] = true;
              } else if (
                parts.length === 4 &&
                parts[1] === "hours" &&
                parts[3] === "min"
              ) {
                value = parseFloat(parts[0]) * 60 + parseFloat(parts[2]);
                isMinutes[totalsColumn] = true;
              }
            }
          } else {
            value = parseFloat(strValue);
          }
          if (totals[totalsColumn] === undefined) {
            totals[totalsColumn] = 0;
          }
          totals[totalsColumn] += value;
          let parts = String(strValue).split(".");
          if (parts.length === 2) {
            if (
              totalsFraction[totalsColumn] === undefined ||
              totalsFraction[totalsColumn] < parts[1].length
            ) {
              totalsFraction[totalsColumn] = parts[1].length;
            }
          }
        });
      }
    });
    return (
      <tbody>
        <tr key="totals">
          {columns.map((column, index) => (
            <th key={column.path + "total"}>
              {index === 0 ? "Total: " : ""}
              {dateUtils.roundFraction(
                isMinutes[column.path]
                  ? Math.floor(totals[column.path] / 60) +
                      " hours" +
                      (totals[column.path] % 60 === 0
                        ? ""
                        : " " + (totals[column.path] % 60) + " min")
                  : totals[column.path],
                totalsFraction[column.path] || 2
              ) || <React.Fragment>&nbsp;</React.Fragment>}
            </th>
          ))}
        </tr>
      </tbody>
    );
  }
}
