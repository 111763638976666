/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2020
 */

import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export default class ImageUpload extends Component {
  state = {
    src: null,
    crop: {
      unit: "%",
      width: 30
      //aspect: 16 / 9
    },
    modifiedImageUrl: null
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.makeClientCrop(45, this.state.crop);
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
    console.log("loaded");
  };

  onCropComplete = crop => {
    this.makeClientCrop(this.state.rotate, crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(rotate, crop) {
    if (this.imageRef && crop.width && crop.height) {
      const modifiedImageUrl = await this.getCroppedImg(
        this.imageRef,
        rotate,
        crop,
        "newFile.jpeg"
      );
      this.setState({ modifiedImageUrl });
      console.log("Modified");
    }
  }

  getCroppedImg(image, rotate, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    ctx.rotate((45 * Math.PI) / 180);

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, modifiedImageUrl, src } = this.state;

    if (this.state.src === null) {
      return (
        <div>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
        </div>
      );
    } else {
      return (
        <div className="App">
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              circularCrop={true}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}
          {modifiedImageUrl && (
            <img
              alt="Crop"
              style={{ maxWidth: "100%" }}
              src={modifiedImageUrl}
            />
          )}
        </div>
      );
    }
  }
}
