/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import authService from "../../services/authService";
import { userService } from "../../services/userService";

function twoDigits(value) {
  if (value < 10) {
    return "0" + String(value);
  } else {
    return String(value);
  }
}
function unix2localDate(unixtime) {
  let date = new Date(unixtime * 1000);
  return (
    twoDigits(date.getMonth() + 1) +
    "/" +
    twoDigits(date.getDate()) +
    "/" +
    twoDigits(date.getFullYear())
  );
}
function unix2localTime(unixtime) {
  let date = new Date(unixtime * 1000);
  let pm = "am";
  let hour = date.getHours();
  if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    pm = "pm";
  } else if (hour > 12) {
    pm = "pm";
    hour -= 12;
  }
  return twoDigits(hour) + ":" + twoDigits(date.getMinutes()) + " " + pm;
}

export default class Workflow extends Component {
  state = { userNames: undefined };
  async componentDidMount() {
    let users = await userService.getAllAsync();
    let userNames = {};
    for (let i = 0; i < users.length; i++) {
      userNames[users[i]._id] = users[i].name;
    }
    this.setState({ userNames });
  }
  renderAuthorButtons() {
    return (
      <div className="form-group d-print-none">
        <label>&nbsp;</label>
        <button onClick={() => {}} className="btn btn-secondary btn-space">
          Save Comment
        </button>
        &nbsp;
        <button
          onClick={() => {
            this.props.setWorkflowFields({
              index: 0,
              action: "draft",
            });
          }}
          className="btn btn-secondary btn-space"
        >
          Make Changes
        </button>
        &nbsp;
        <button
          onClick={() => {
            this.props.setWorkflowFields({
              index: 0,
              action: "cancel",
            });
          }}
          className="btn btn-secondary btn-space"
        >
          Cancel Request
        </button>
      </div>
    );
  }

  renderStageButtons(index, rule) {
    return (
      <div className="form-group d-print-none">
        <label>&nbsp;</label>
        {(rule.type === "approve" ||
          rule.type === "accept" ||
          rule.type === "received" ||
          rule.type === "approve_one") && (
          <React.Fragment>
            <button
              onClick={() => {
                this.props.setWorkflowFields({
                  index: index,
                  action: rule.type,
                });
              }}
              className="btn btn-secondary btn-space"
              disabled={
                rule.status === "Approved" ||
                rule.status === "Accepted" ||
                rule.status === "Received"
              }
            >
              {rule.type === "approve" || rule.type === "approve_one"
                ? "Approve"
                : rule.type === "received"
                ? "Received"
                : "Accept"}
            </button>
            &nbsp;
            <button
              onClick={() => {
                this.props.setWorkflowFields({
                  index: index,
                  action: "reject",
                });
              }}
              className="btn btn-secondary btn-space"
              disabled={rule.status === "Rejected"}
            >
              Reject
            </button>
            &nbsp;
          </React.Fragment>
        )}
        <button onClick={() => {}} className="btn btn-secondary btn-space">
          Save Comment
        </button>
      </div>
    );
  }

  renderRule(rule, index) {
    let matchLoggedIn =
      rule.email === authService.getProfile().email ||
      rule.user === authService.getProfile().token;
    let { name, value, setWorkflowFields } = this.props;
    return (
      <React.Fragment key={index}>
        <div className="form-group">
          <label>
            <b>
              {rule.status || (rule.type === "notify" && "Notify") || "Pending"}
            </b>
            {rule.lastStatusChange && (
              <span style={{ fontSize: "10pt" }}>
                <br />
                {unix2localDate(rule.lastStatusChange)}
                <br />
                {unix2localTime(rule.lastStatusChange)}
              </span>
            )}
          </label>
          {rule.name ||
            (this.state.userNames && this.state.userNames[rule.user]) ||
            rule.user}
          <br />
          {rule.email}
          {(rule.status === "Draft" || !matchLoggedIn) && (
            <React.Fragment>
              <br />
              {rule.comment}
            </React.Fragment>
          )}
        </div>
        {rule.status !== "Draft" && matchLoggedIn && (
          <React.Fragment>
            <div className="form-group">
              <label>&nbsp;</label>
              <input
                {...this.props.rest}
                placeholder="Comment"
                name={name}
                id={name}
                value={this.props.value[index].comment}
                className="form-control"
                style={{ align: "right" }}
                onChange={(currentTarget) => {
                  setWorkflowFields({
                    index: index,
                    action: undefined,
                    comment: currentTarget.target.value,
                  });
                }}
              />
            </div>
            {value[0].status === "Submitted" &&
              (index === 0
                ? this.renderAuthorButtons()
                : this.renderStageButtons(index, rule))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    let { value } = this.props;
    let titleShown = {};
    let maxStep = 0;
    let approveOneStep = -1;
    value &&
      value.forEach((rule, index) => {
        if (index > 0) {
          let step = parseInt(rule.step);
          if (step > maxStep) {
            maxStep = step;
          }
          if (rule.type === "approve_one") {
            approveOneStep = rule.step;
          }
        }
      });

    return (
      <React.Fragment>
        <br />
        <div
          style={{
            border: "2px solid currentColor",
            backgroundColor: "#cccccc",
            padding: "5px",
          }}
        >
          <h3 style={{ borderBottom: "2px solid currentColor" }}>Workflow</h3>
          <div className="form-group">
            <b>
              <label>Overall Status</label>
            </b>
            <div>
              <b>{Workflow.getOverallStatus(value)}</b>
            </div>
          </div>
          {value && !value.stages && value[0] && this.renderRule(value[0], 0)}
          {value &&
            !value.stages && // stages was old format
            ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((stage) =>
              value.map((rule, index) => {
                if (index > 0 && stage === rule.step) {
                  let title = stage
                    ? "Step " + stage + "/" + maxStep
                    : "Submission";
                  if (stage === approveOneStep) {
                    title += " (only one approval needed)";
                  }
                  return [
                    Workflow.showTitleOnce(titleShown, title),
                    this.renderRule(rule, index),
                  ];
                }
                return null;
              })
            )}
        </div>
      </React.Fragment>
    );
  }

  static showTitleOnce(priorEntries, title) {
    if (priorEntries[title]) {
      return null;
    } else {
      priorEntries[title] = true;
      return (
        <label
          key={title}
          style={{
            borderBottom: "2px solid currentColor",
            fontWeight: "bold",
            width: "100%",
          }}
        >
          {title}
        </label>
      );
    }
  }

  static getOverallStatus(value) {
    if (!value) {
      return null;
    }
    let fullyApproved = true;
    let fullyAccepted = true;
    let fullyReceived = true;
    let hasApproveType = false;
    let hasAcceptType = false;
    let hasReceivedType = false;
    let hasApproveOneType = false;
    let anyApproved = false;
    let anyAccepted = false;
    let anyReceived = false;
    let anyRejected = false;
    let anyApprovedOne = false;
    for (let i = 1; i < value.length; i++) {
      let stage = value[i];
      if (stage.type === "approve") {
        hasApproveType = true;
        if (stage && stage.status) {
          if (stage.status !== "Approved") {
            fullyApproved = false;
          } else {
            anyApproved = true;
          }
          if (stage.status === "Rejected") {
            anyRejected = true;
          }
        } else {
          fullyApproved = false;
        }
      } else if (stage.type === "approve_one") {
        hasApproveOneType = true;
        if (stage && stage.status) {
          if (stage.status === "Approved") {
            anyApprovedOne = true;
          }
          if (stage.status === "Rejected") {
            anyRejected = true;
          }
        }
      } else if (stage.type === "accept") {
        hasAcceptType = true;
        if (stage && stage.status) {
          if (stage.status !== "Accepted") {
            fullyAccepted = false;
          } else {
            anyAccepted = true;
          }
          if (stage.status === "Rejected") {
            anyRejected = true;
          }
        } else {
          fullyAccepted = false;
        }
      } else if (stage.type === "received") {
        hasReceivedType = true;
        if (stage && stage.status) {
          if (stage.status !== "Received") {
            fullyReceived = false;
          } else {
            anyReceived = true;
          }
          if (stage.status === "Rejected") {
            anyRejected = true;
          }
        } else {
          fullyReceived = false;
        }
      }
    }
    if (anyRejected) {
      return "Rejected";
    }
    if (fullyApproved && hasApproveType && fullyAccepted) {
      return "Approved";
    }
    if (fullyAccepted && hasAcceptType) {
      return "Accepted";
    }
    if (fullyReceived && hasReceivedType) {
      return "Received";
    }
    if (anyApproved) {
      return "Partially Approved";
    }
    if (anyAccepted) {
      return "Partially Accepted";
    }
    if (anyReceived) {
      return "Partially Received";
    }
    if (anyApprovedOne && hasApproveOneType) {
      return "Approved";
    }
    return value[0] ? value[0].status : "No Workflow Definition";
  }
}
