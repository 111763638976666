/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const ministryGoalsItemDefinition = {
  baseUrl: "/reports/ministrygoals",
  viewTitle: "Ministry Goals Entries",
  editTitle: "Edit Ministry Goals Entry",
  createTitle: "New Ministry Goals Entry",
  columnNames: ["church", "specificGoals"],
  fieldNames: ["church", "specificGoals"],
  readPermissions: ["[author]", "sysadmin"],
  writePermissions: ["[author]", "sysadmin"],
  fields: {
    church: {
      label: "Church",
      readonly: true,
      type: "text"
    },
    specificGoals: {
      label: "Specific Goals",
      readonly: false,
      required: true,
      type: "textarea"
    }
  }
};

export let ministryGoalsItemService = new EntryService(
  ministryGoalsItemDefinition
);
