/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { userService } from "./userService";
import dateUtils from "../utils/dateUtils";
import { ministryReportItemService } from "./ministryReportItemService";

//pass in entire data set and it only returns data.activity (values to be shown) as an array
let customActivityView = (data) => {
  if (data === undefined || data.date === undefined) {
    return data;
  }
  let activity = data.activity;
  if (activity === undefined || activity === "[]") {
    activity = [];
  }
  if (typeof activity === "string") {
    activity = JSON.parse(activity);
  }
  let lastDay = new Date(
    parseInt(data.date.substring(0, 4)),
    parseInt(data.date.substring(5, 7)),
    0
  );

  let ret = [];

  for (let i = 1; i <= lastDay.getDate(); i++) {
    let d = i < 10 ? "0" + String(i) : String(i);
    let rowdate = data.date.substring(0, 8) + d;
    let activityElement = activity.find((a) => a._id === rowdate);
    if (activityElement) {
      activityElement.date = rowdate;
      ret.push(activityElement);
    } else {
      ret.push({ date: rowdate, _id: rowdate });
    }
  }
  return ret;
};

export const ministryReportDefinition = {
  baseUrl: "/reports/ministryreport",
  viewTitle: "Ministry Report",
  editTitle: "Edit Ministry Report",
  hideNav: true,
  noPlusCreate: true,
  columnNames: [
    "userid",
    "date",
    "objectivesThisMonth",
    "objectivesNextMonth",
    "helpNeeded",
    "viewWorkflow",
  ],
  fieldNames: [
    "column1",
    "userid",
    "column2",
    "date",
    "columnEnd",
    "activity",
    "column1",
    "labelOdometerReading1",
    "beginningMiles1",
    "endingMiles1",
    "differenceMiles1",
    "personalMiles1",
    "specialMiles1",
    "districtWorkMiles1",
    "column2",
    "labelOdometerReading2",
    "beginningMiles2",
    "endingMiles2",
    "differenceMiles2",
    "personalMiles2",
    "specialMiles2",
    "districtWorkMiles2",
    "columnEnd",
    "labelPastoralGoals",
    "objectivesThisMonth",
    "objectivesNextMonth",
    "helpNeeded",
    "attachments",
  ],
  createPermissions: ["pastor", "sysadmin"],
  deletePermissions: ["[author]", "ministryreport", "sysadmin"],
  readPermissions: ["[author]", "ministryreport", "sysadmin"],
  writePermissions: ["[author]", "ministryreport", "sysadmin"],
  workflow: "ministryreport",
  fields: {
    viewWorkflow: {
      label: "Workflow",
      type: "workflow",
    },
    userid: {
      label: "Name",
      required: true,
      type: "select",
      length: 64,
      selections: userService,
      default: "@UserID",
      readonly: true,
    },
    date: {
      label: "Date",
      required: true,
      type: "month",
      default: dateUtils.getMonthOptions(0, 0)[0]._id,
      readonly: true,
    },
    activity: {
      label: "Activity",
      type: "rows",
      service: ministryReportItemService,
      customview: customActivityView,
    },
    column1: {
      type: "column",
    },
    labelOdometerReading1: {
      label: "Odometer Reading (Car 1)",
      type: "label",
      isSection: true,
    },
    beginningMiles1: {
      label: "Beginning Miles",
      required: true,
      type: "miles",
      toField: "endingMiles1",
    },
    endingMiles1: {
      label: "Ending Miles",
      required: true,
      type: "miles",
      fromField: "beginningMiles1",
    },
    differenceMiles1: {
      label: "Difference",
      type: "calculated",
      fields: ["endingMiles1", "beginningMiles1"],
      value: (data) => {
        return dateUtils.roundFraction(
          parseFloat(data.endingMiles1) - parseFloat(data.beginningMiles1),
          1,
          ""
        );
      },
    },
    personalMiles1: {
      label: "Personal Miles",
      required: true,
      type: "miles",
    },
    specialMiles1: {
      label: "Special Miles",
      required: true,
      type: "miles",
    },
    districtWorkMiles1: {
      label: "Milage used in District work",
      type: "calculated",
      fields: [
        "endingMiles1",
        "beginningMiles1",
        "specialMiles1",
        "personalMiles1",
      ],
      value: (data) => {
        return dateUtils.roundFraction(
          parseFloat(data.endingMiles1) -
            parseFloat(data.beginningMiles1) -
            parseFloat(data.specialMiles1) -
            parseFloat(data.personalMiles1),
          1,
          ""
        );
      },
    },
    column2: {
      type: "column",
    },
    labelOdometerReading2: {
      label: "Odometer Reading (Car 2)",
      type: "label",
      isSection: true,
    },
    beginningMiles2: {
      label: "Beginning Miles",
      required: false,
      type: "miles",
      default: "0.0",
      toField: "endingMiles2",
    },
    endingMiles2: {
      label: "Ending Miles",
      required: false,
      type: "miles",
      default: "0.0",
      fromField: "beginningMiles2",
    },
    differenceMiles2: {
      label: "Difference",
      type: "calculated",
      fields: ["endingMiles2", "beginningMiles2"],
      value: (data) => {
        return dateUtils.roundFraction(
          parseFloat(data.endingMiles2) - parseFloat(data.beginningMiles2),
          1,
          ""
        );
      },
    },
    personalMiles2: {
      label: "Personal Miles",
      required: false,
      type: "miles",
      default: "0.0",
    },
    specialMiles2: {
      label: "Special Miles",
      required: false,
      type: "miles",
      default: "0.0",
    },
    districtWorkMiles2: {
      label: "Milage used in District work",
      type: "calculated",
      fields: [
        "endingMiles2",
        "beginningMiles2",
        "specialMiles2",
        "personalMiles2",
      ],
      value: (data) => {
        return dateUtils.roundFraction(
          parseFloat(data.endingMiles2) -
            parseFloat(data.beginningMiles2) -
            parseFloat(data.specialMiles2) -
            parseFloat(data.personalMiles2),
          1,
          ""
        );
      },
    },
    columnEnd: {
      type: "column",
      columnEnd: true,
    },
    labelPastoralGoals: {
      label: "Pastoral Goals",
      type: "label",
      isSection: true,
    },
    objectivesThisMonth: {
      label: "Objectives reached this month",
      required: true,
      type: "textarea",
      length: 4096,
    },
    objectivesNextMonth: {
      label: "Objectives for next Month",
      required: true,
      type: "textarea",
      length: 4096,
    },
    helpNeeded: {
      label: "How can SNEC administration help you?",
      required: false,
      type: "textarea",
      length: 4096,
    },
    attachments: {
      label: "Attachments",
      required: false,
      type: "attachments",
    },
  },
};

export let ministryReportService = new EntryService(ministryReportDefinition);
