/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import definitions from "./allDefinitions";

export default class GeneratePhpFiles extends Component {
  render() {
    let retError = [];
    return (
      <React.Fragment>
        <h3>sql_schema.inc.php</h3>
        <textArea cols="100" rows="20">
          {"<?php\n" +
            "/* Copyright (C) Andreas Goelzer - All Rights Reserved\n" +
            " * Unauthorized copying of this file, via any medium is strictly prohibited\n" +
            "* Proprietary and confidential\n" +
            "* Written by Andreas Goelzer <agolzer@agolzer.com>, 2019\n" +
            "*/\n" +
            "\n" +
            "function getForms() {\n" +
            "$ret = array();\n"}
          {definitions.map((definition) => {
            let urlParts = definition.baseUrl.split("/");
            let sqlName = urlParts[urlParts.length - 1];
            return '$ret[] = "' + sqlName + '";\n';
          })}
          {"return $ret;\n" +
            "}\n\n" +
            "function getSchema($form) {\n" +
            "global $createPermissions;\n" +
            "global $deletePermissions;\n" +
            "global $readPermissions;\n" +
            "global $writePermissions;\n" +
            "global $columns;\n" +
            "global $baseurl;\n" +
            "$columns = array();\n" +
            'if($form === "") {\n' +
            '    die("form parameter missing");\n'}
          {definitions.map((definition) => {
            let baseUrl = definition.baseUrl.replace(/^\//g, "");
            let urlParts = definition.baseUrl.split("/");
            let sqlName = urlParts[urlParts.length - 1];
            let ret = [];
            ret.push('} else if($form === "' + sqlName + '"){\n');

            let createPermissions = definition.createPermissions || ["[all]"];
            for (let cp = 0; cp < createPermissions.length; cp++) {
              ret.push(
                '    $createPermissions[] = "' + createPermissions[cp] + '";\n'
              );
            }

            let deletePermissions = definition.deletePermissions || [
              "[author]",
            ];
            for (let dp = 0; dp < deletePermissions.length; dp++) {
              ret.push(
                '      $deletePermissions[] = "' +
                  deletePermissions[dp] +
                  '";\n'
              );
            }

            let readPermissions = definition.readPermissions || ["[all]"];
            for (let rp = 0; rp < readPermissions.length; rp++) {
              ret.push(
                '    $readPermissions[] = "' + readPermissions[rp] + '";\n'
              );
            }

            let writePermissions = definition.writePermissions || ["sysadmin"];
            for (let wp = 0; wp < writePermissions.length; wp++) {
              ret.push(
                '    $writePermissions[] = "' + writePermissions[wp] + '";\n'
              );
            }

            let keys = Object.keys(definition.fields);
            for (let k = 0; k < keys.length; k++) {
              let field = definition.fields[keys[k]];
              if (
                field.type !== "label" &&
                field.type !== "calculated" &&
                field.type !== "workflow" &&
                ((field.type !== "custom" && field.type !== "column") ||
                  keys[k] === "metadata")
              ) {
                let def = "";
                if (
                  field.selections &&
                  field.selections.isService &&
                  field.selections.isService()
                ) {
                  let parts = field.selections.getBaseUrl().split("/");
                  def = "lookup:" + parts[parts.length - 1];
                } else if (
                  field.type === "date" ||
                  field.type === "month" ||
                  field.type === "year"
                ) {
                  def = "date";
                } else if (field.type === "number") {
                  def = "number";
                }
                ret.push('    $columns["' + keys[k] + '"] = "' + def + '";\n');
              }
            }
            if (definition.workflow !== undefined) {
              ret.push('    $columns["_workflow"] = "workflow";\n');
            }
            ret.push('    $baseurl = "' + baseUrl + '";\n');
            return ret;
          })}
          {"} else {\n"}
          {'    die("form parameter invalid");\n'}
          {"}\n"}
          {"}\n"}
          {"?>\n"}
        </textArea>

        <h3>initdb.php</h3>
        <textArea cols="100" rows="20">
          {"<?php"}
          {"\n"}
          {"/* Copyright (C) Andreas Goelzer - All Rights Reserved"}
          {"\n"}
          {
            " * Unauthorized copying of this file, via any medium is strictly prohibited"
          }
          {"\n"}
          {" * Proprietary and confidential"}
          {"\n"}
          {" * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019"}
          {"\n"}
          {" */"}
          {"\n"}
          {"\n"}
          {'require_once "cors.inc.php";'}
          {"\n"}
          {'require_once $_SERVER["DOCUMENT_ROOT"] . "/../config.inc.php";'}
          {"\n"}
          {'require_once "./sql_schema.inc.php";'}
          {"\n"}
          {""}
          {"\n"}
          {'if($mysql_db !== "snecapp-test") {'}
          {"\n"}
          {'    die("invalid db " . $mysql_db);'}
          {"\n"}
          {"}"}
          {"\n"}
          {"      "}
          {"\n"}
          {
            "      $conn = new mysqli($mysql_host, $mysql_username, $mysql_password, $mysql_db);"
          }
          {"\n"}
          {'$result = $conn->query("show tables");'}
          {"\n"}
          {"if($result !== FALSE) {"}
          {"\n"}
          {'echo "Show table succeeded<br/>";'}
          {"\n"}
          {"while(($row = $result->fetch_assoc()) !== NULL) {"}
          {"\n"}
          {"foreach($row as & $table) {"}
          {"\n"}
          {'$resultDelete = $conn -> query("drop table `$table`");'}
          {"\n"}
          {"if($resultDelete !== FALSE) {"}
          {"\n"}
          {'echo "Deleted table $table<br/>\n";'}
          {"\n"}
          {"}"}
          {"\n"}
          {"}"}
          {"\n"}
          {"}"}
          {"\n"}
          {"}"}
          {"\n"}
          {"$sql = array();"}
          {"\n"}
          {definitions.map((definition) => {
            let urlParts = definition.baseUrl.split("/");
            let sqlName = urlParts[urlParts.length - 1];
            let ret = [];
            ret.push("\n");
            ret.push(
              '$sql["' + sqlName + '"] = "CREATE TABLE `' + sqlName + "` ("
            );
            ret.push("\n");
            ret.push("`_id` char(64) NOT NULL,");
            ret.push("\n");
            ret.push("`_created` timestamp NOT NULL,");
            ret.push("\n");
            ret.push("`_modified` timestamp NOT NULL,");
            ret.push("\n");
            ret.push("`_deleted` tinyint(1) NOT NULL,");
            ret.push("\n");
            ret.push("`_author` varchar(64) NOT NULL,");
            ret.push("\n");
            if (definition.workflow) {
              ret.push("`_workflow` json NOT NULL,");
              ret.push("\n");
            }
            let keys = Object.keys(definition.fields);
            for (let k = 0; k < keys.length; k++) {
              let field = definition.fields[keys[k]];
              if (
                field.type === "label" ||
                field.type === "calculated" ||
                field.type === "workflow" ||
                field.type === "custom" ||
                field.type === "column" ||
                keys[k] === "metadata"
              ) {
                continue;
              }

              let sqlType = undefined;

              if (
                field.type === "select" ||
                field.type === "select_other" ||
                field.type === "text" ||
                field.type === "password" ||
                field.type === "checkbox" ||
                field.type === "textarea"
              ) {
                if (field.length) {
                  sqlType = "varchar(" + field.length + ")";
                } else {
                  retError.push(
                    "field " +
                      keys[k] +
                      " in " +
                      sqlName +
                      ' doesn\'t have a "length" attribute.'
                  );
                }
              } else if (field.type === "number") {
                sqlType = "int(11)";
              } else if (field.type === "date") {
                sqlType = "date";
              } else if (field.type === "miles") {
                sqlType = "decimal(11,1)";
              } else if (field.type === "time") {
                sqlType = "time";
              } else if (field.type === "currency") {
                sqlType = "decimal(11,2)";
              } else if (field.type === "rows") {
                sqlType = "json";
              } else if (field.type === "month" || field.type === "year") {
                sqlType = "date";
              } else {
                retError.push(
                  'Unknown type: "' +
                    field.type +
                    '" for field "' +
                    keys[k] +
                    '" in table "' +
                    sqlName +
                    '"'
                );
              }
              if (sqlType && (sqlName === "users" || sqlName === "groups")) {
                ret.push("`" + keys[k] + "` " + sqlType + ",");
                ret.push("\n");
              }
            }
            ret.push("`content` json,");
            ret.push("\n");
            ret.push("PRIMARY KEY (`_id`)");
            ret.push("\n");
            ret.push(
              ') ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci";'
            );
            ret.push("\n");
            return ret;
          })}
          {'$sql["firstuser"] = "INSERT INTO `users` (`_id`, `_created`, `_modified`, `_deleted`, `_author`, `name`, `email`, `password`, `churches`, `groups`, `manager`) VALUES ' +
            "('65fcd6231aa52ef0651df92b3c67b191d628352d033bb07844c6ad97d75e4578', '2019-08-29 10:41:58', '2019-10-22 11:32:35', 0, '65fcd6231aa52ef0651df92b3c67b191d628352d033bb07844c6ad97d75e4578', 'Sysadmin (snecapp)', 'sysadmin@snecapp.org', '\\$2y\\$10\\$PPzm9u9XoSac5hU7x3nyGOm.uGRuFFiSnAk/nLvl50To.GVqfg4mq', '', '54bf64daa5b80b0c5e53932bbc0fc0427855f7e993591853ef0b5d07a222c194,2cb694b353347b5980b5fec54f46913ae2ea171239601ad7ac86dcac5132a528,463bdceaba8746a60e3c01f4e26c5cb0ce3785f5653abc895f1a611662c2eea8,29edfaefc15d27e6216cf09d2557beef979c5bb9ff33c3cce5bfd247387a85cd,e0024a6e9f24a987c51d3620d0af5cd3bf3374856f4d1323f302d3661a4ad7c4', '65fcd6231aa52ef0651df92b3c67b191d628352d033bb07844c6ad97d75e4578')\";"}
          {"\n"}
          {'$sql["firstgroup"] = "INSERT INTO `groups` (`_id`, `_created`, `_modified`, `_deleted`, `_author`, `name`) VALUES ' +
            "('54bf64daa5b80b0c5e53932bbc0fc0427855f7e993591853ef0b5d07a222c194', '2019-08-29 10:42:18', '2019-08-29 10:42:18', 0, '2ff4d048187216d8a801160fa1bc0dd3d603b2dccf5c00baba96818702f0ff25', 'Sysadmin')\";"}
          {"\n"}
          {'$sql["session"] = "CREATE TABLE `session` (\n'}
          {"`sessionid` varchar(64) NOT NULL, \n"}
          {"`timeout` int(11) NOT NULL,\n"}
          {"`baseurl` varchar(256) NOT NULL,\n"}
          {"`email` varchar(80) NOT NULL,\n"}
          {" PRIMARY KEY (`sessionid`)\n"}
          {
            ') ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci";\n'
          }

          {"foreach($sql as $key => $s) {"}
          {"\n"}
          {"$result = $conn -> query($s);"}
          {"\n"}
          {"if($result !== FALSE) {"}
          {"\n"}
          {'echo "create success for $key<br />";\n'}
          {"\n"}
          {"}"}
          {"\n"}
          {"else {"}
          {"\n"}
          {'echo "create failed $key<br />";\n'}
          {"echo mysqli_error($conn);\n"}
          <br />
          {"}"}
          <br />
          {"}"}
          <br />
          {"    "}
          <br />
          {"?>"}
        </textArea>
        <br />
        {retError.map((error) => (
          <b>
            {error}
            <br />
          </b>
        ))}
      </React.Fragment>
    );
  }
}
