import { eventCoverageItemService } from "./eventCoverageItemService";
import dateUtils from "../utils/dateUtils";
import lookups from "../utils/lookups";

let customCoverageView = (data) => {
  if (data === undefined) {
    return data;
  }
  let coverage = data.coverage;
  if (coverage === undefined || coverage === "{}") {
    coverage = [];
  }
  if (typeof coverage === "string") {
    coverage = JSON.parse(coverage);
    churches = coverage.map((row) =>
      row._id.substring(row._id.indexOf(" ") + 1)
    );
  }

  let ret = [];

  if (data.startDate && data.endDate) {
    let sqlStartDate = data.startDate;
    let sqlEndDate = data.endDate;
    let dates = dateUtils.sqlDateList(sqlStartDate, sqlEndDate, 365);

    if (churches) {
      churches.forEach((church) => {
        for (let d = 0; d < dates.length; d++) {
          let date = new Date(
            parseInt(dates[d].substring(0, 4)),
            parseInt(dates[d].substring(5, 7)) - 1,
            parseInt(dates[d].substring(8, 10))
          );
          if (date.getDay() === 6) {
            let id = dates[d] + " " + church;
            try {
              let entry = coverage && coverage.find((e) => e._id === id);
              let speaker = entry && entry.speaker;
              ret.push({
                date: dates[d],
                church: church,
                _id: dates[d] + " " + church,
                speaker: speaker,
              });
            } catch (e) {
              console.log(e);
            }
          }
        }
      });
    }
  }

  return ret;
};

let churches = undefined; //filled in with initAsync()

let coverage = {
  label:
    "During the time of my absence, the following will speak in my churches",
  type: "rows",
  required: false,
  service: eventCoverageItemService,
  customview: customCoverageView,
  initAsync: async () => {
    console.log("initAsync");
    let c = await lookups.getChurchNames();
    if (!churches) {
      console.log("set initAsync");
      churches = c;
    }
  },
};

export default { coverage: coverage };
