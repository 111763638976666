/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import EntryService from "./entryService";
import { userService } from "./userService";
import lookups from "../utils/lookups";
import { ministryGoalsItemService } from "./ministryGoalsItemService";
import dateUtils from "../utils/dateUtils";
import CacheService from "./cacheService";
import Workflow from "../components/common/workflow";
import authService from "./authService";

let cache = new CacheService();

let userChurches = []; //filled in with initAsync()

let customGoalsView = (data, name) => {
  if (data === undefined) {
    return data;
  }
  let rows = data[name];
  let rowByChurch = {};
  if (!rows || rows === "{}") {
    rows = [];
  } else {
    if (typeof rows === "string") {
      rows = JSON.parse(rows);
    }
    if (rows.length > 0) {
      rows.forEach((obj) => {
        rowByChurch[obj._id] = {
          church: obj._id,
          _id: obj._id,
          specificGoals: obj.specificGoals,
        };
      });
    }
  }

  if (
    data.userid === authService.getProfile().token &&
    Workflow.getOverallStatus(data._workflow) === "Draft"
  ) {
    userChurches.forEach((userChurch) => {
      if (rowByChurch[userChurch] === undefined) {
        rowByChurch[userChurch] = {
          church: userChurch,
          _id: userChurch,
          specificGoals: "",
        };
      }
    });
  }

  let ret = [];
  let sortedChurches = Object.keys(rowByChurch).sort((a, b) =>
    a < b ? -1 : a > b ? 1 : 0
  );
  sortedChurches.forEach((church) => {
    ret.push(rowByChurch[church]);
  });

  return ret;
};

export const ministryGoalsDefinition = {
  baseUrl: "/reports/ministrygoals",
  viewTitle: "Ministry Goals",
  editTitle: "Edit Ministry Goals",
  columnNames: ["userid", "year", "viewWorkflow"],
  hideNav: true,
  fieldNames: [
    "userid",
    "year",
    "churchGrowth",
    "churchProjects",
    "nurturing",
    "training",
    "education",
    "preaching",
    "personalGoals",
  ],
  createPermissions: ["pastor", "sysadmin"],
  deletePermissions: ["[author]", "ministrygoals", "sysadmin"],
  readPermissions: ["[author]", "ministrygoals", "sysadmin"],
  writePermissions: ["[author]", "ministrygoals", "sysadmin"],
  workflow: "ministrygoals",
  fields: {
    viewName: {
      label: "Name",
      type: "calculated",
      fields: ["middleName", "firstName", "lastName"],
      value: (data) => {
        if (data.middleName) {
          return (
            data.firstName +
            " " +
            data.middleName.substring(0, 1) +
            " " +
            data.lastName
          );
        } else {
          return data.firstName + " " + data.lastName;
        }
      },
    },
    viewAddress: {
      label: "Address",
      type: "calculated",
      fields: ["address", "city", "state", "zip"],
      value: (data) => {
        return (
          <React.Fragment>
            {data.address}
            <br />
            {data.city}, {data.state} {data.zip}
          </React.Fragment>
        );
      },
      display: "lg xl",
    },
    viewPhone: {
      label: "Phone",
      type: "calculated",
      fields: ["homePhone", "cellPhone"],
      value: (data) => {
        return (
          <React.Fragment>
            {data.homePhone && "H: " + data.homePhone}
            {data.homePhone && data.cellPhone && <br />}
            {data.cellPhone && "C: " + data.cellPhone}
          </React.Fragment>
        );
      },
    },
    viewWorkflow: {
      label: "Workflow",
      type: "workflow",
    },
    userid: {
      label: "Pastor",
      required: true,
      type: "select",
      length: 64,
      selections: userService,
      default: "@UserID",
      readonly: true,
    },
    year: {
      label: "Ministry Goals for",
      required: true,
      type: "year",
      selections: dateUtils.getYearOptions(-20, 2),
      default: dateUtils.getYearOptions(1, 1)[0]._id,
      readonly: true,
    },
    churchGrowth: {
      label: "Evangelism/Church Growth (including Baptismal Growth)",
      type: "rows",
      required: false,
      service: ministryGoalsItemService,
      customview: customGoalsView,
      initAsync: async () => {
        userChurches = await lookups.getChurchNames(cache);
      },
    },
    churchProjects: {
      label: "Church Projects",
      type: "rows",
      required: false,
      service: ministryGoalsItemService,
      customview: customGoalsView,
    },
    nurturing: {
      label: "Nurturing/Visiting",
      type: "rows",
      required: false,
      service: ministryGoalsItemService,
      customview: customGoalsView,
    },
    training: {
      label: "Training/Empowering",
      type: "rows",
      required: false,
      service: ministryGoalsItemService,
      customview: customGoalsView,
    },
    education: {
      label: "Christian Education",
      type: "rows",
      required: false,
      service: ministryGoalsItemService,
      customview: customGoalsView,
    },
    preaching: {
      label: "Preaching",
      type: "textarea",
      length: 4096,
      required: true,
    },
    personalGoals: {
      label: "Personal Goals",
      type: "textarea",
      length: 4096,
      required: true,
    },
  },
};

export let ministryGoalsService = new EntryService(ministryGoalsDefinition);
