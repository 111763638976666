/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import CheckBoxes from "./common/checkBoxes";
import Select from "./common/select";

export default class SearchBox extends Component {
  static WORKFLOW_TYPES =
    "Draft,Submitted,Accepted,Partially Accepted,Received,Partially Received,Approved,Partially Approved,Rejected";

  state = {
    filtersShown: false,
  };

  handleCheckboxesChange = ({ currentTarget: input }) => {
    //let key = input.value.split("_", 2)[0];
    let value = input.value.split("_", 2)[1];
    let checked = input.checked;
    let workflowValues = this.props.search.workflowValues;
    if (checked) {
      workflowValues += workflowValues.length === 0 ? value : "," + value;
    } else if (workflowValues === value) {
      workflowValues = "";
    } else {
      let wv = "," + workflowValues + ",";
      let pos = wv.indexOf("," + value + ",");
      if (pos !== -1) {
        workflowValues =
          wv.substring(0, pos) + wv.substring(pos + value.length + 1);
        workflowValues = workflowValues.substring(1, workflowValues.length - 1);
      }
    }

    let search = { ...this.props.search, workflowValues };
    this.props.onChange(search);
    search = { ...search };
    delete search["query"];
    localStorage.setItem("search", JSON.stringify(search));
  };

  renderFilters() {
    let workflowOptions = SearchBox.WORKFLOW_TYPES.split(",").map((e) => {
      return { _id: e, name: e };
    });
    return (
      <div style={{ backgroundColor: "#EEEEEE" }} className="mb-3">
        <CheckBoxes
          name="Workflow"
          key="Workflow"
          value={this.props.search.workflowValues}
          label="Workflow"
          options={workflowOptions}
          onChange={this.handleCheckboxesChange}
          error=""
          readOnly={false}
        />
        {this.props.group && (
          <Select
            label={this.props.group.label}
            name={this.props.group.name}
            value={this.props.group.value}
            options={this.props.group.options}
            onChange={this.props.group.onChange}
          />
        )}
      </div>
    );
  }

  render() {
    const { search } = this.props;
    return (
      <React.Fragment>
        <div className="form-group my-0">
          {this.props.hasWorkflow && (
            <button
              onClick={() => {
                this.setState({ filtersShown: !this.state.filtersShown });
              }}
              className="my-3"
            >
              <div style={{ width: "60px" }}>
                Filters&nbsp;
                <span
                  className={
                    this.state.filtersShown
                      ? "fa fa-sort-asc"
                      : "fa fa-sort-desc"
                  }
                />
              </div>
            </button>
          )}
          <input
            type="text"
            name="query"
            className="form-control my-3"
            placeholder="Search..."
            value={search.query}
            onChange={(e) =>
              this.props.onChange({
                ...this.props.search,
                query: e.currentTarget.value,
              })
            }
          />
        </div>
        {this.state.filtersShown && this.renderFilters()}
        {!this.state.filtersShown &&
          this.props.hasWorkflow &&
          this.props.search.workflowValues.length !==
            SearchBox.WORKFLOW_TYPES.length && (
            <div className="mb-3" style={{ backgroundColor: "#EEEEEE" }}>
              Filters: Workflow: {this.props.search.workflowValues}
            </div>
          )}
        {!this.state.filtersShown &&
          this.props.group &&
          this.props.group.value && (
            <div className="mb-3" style={{ backgroundColor: "#EEEEEE" }}>
              Filters: {this.props.group.label}: {this.props.group.value}
            </div>
          )}
      </React.Fragment>
    );
  }
}
