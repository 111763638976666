/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";

const CheckBoxes = ({
  name,
  label,
  value,
  options,
  error,
  readOnly,
  onChange
}) => {
  let optionsSorted = [...options];
  optionsSorted.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div style={{ float: "none" }}>
        {optionsSorted.map(option => (
          <React.Fragment key={option._id}>
            {readOnly ? (
              ("," + value + ",").indexOf("," + option._id + ",") === -1 ? (
                ""
              ) : (
                <React.Fragment>
                  {option.name}
                  <br />
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                <input
                  type="checkbox"
                  name={name + "_" + option._id}
                  value={name + "_" + option._id}
                  checked={
                    ("," + value + ",").indexOf("," + option._id + ",") === -1
                      ? false
                      : true
                  }
                  onChange={onChange}
                />
                &nbsp;{option.name}
                <br />
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default CheckBoxes;
