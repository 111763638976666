/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";

const Label = ({ label, isSection }) => {
  return (
    <div
      key={label}
      className={isSection ? "form-group form-section" : "form-group"}
    >
      {label}
    </div>
  );
};

export default Label;
