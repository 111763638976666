/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { ministryReportService } from "../services/ministryReportService";
import dateUtils from "../utils/dateUtils";
import { holidayService } from "../services/holidayService";
import { activityService } from "../services/activityService";
import { vacationService } from "../services/vacationService";

export default class MinistryReportForm extends Form {
  state = {
    selectedMonth: dateUtils.getMonthOptions(0, 0)[0]._id,
  };

  addActivity = (activityTable, newData) => {
    for (let i = 0; i < activityTable.length; i++) {
      let activity = activityTable[i];
      if (activity._id !== newData._id) {
        continue;
      }

      if (activity.type !== newData.type) {
        activity.type = "";
      }
      activity.description += " // " + newData.description;

      if (newData.beginningMiles && newData.endingMiles) {
        if (activity.districtMiles === undefined) {
          activity.districtMiles = "0.0";
        }
        activity.districtMiles = dateUtils.roundFraction(
          parseFloat(activity.districtMiles) +
            parseFloat(newData.endingMiles) -
            parseFloat(newData.beginningMiles),
          1
        );
      }
      if (newData.specialMiles) {
        if (activity.specialMiles === undefined) {
          activity.specialMiles = "0.0";
        }
        activity.specialMiles = dateUtils.roundFraction(
          parseFloat(activity.specialMiles) + parseFloat(newData.specialMiles),
          1
        );
      }
      if (newData.perDiem) {
        if (activity.perDiem === undefined) {
          activity.perDiem = "0.0";
        }
        activity.perDiem = dateUtils.roundFraction(
          parseFloat(activity.perDiem) + parseFloat(newData.perDiem),
          2
        );
      }
      if (newData.tolls) {
        if (activity.tolls === undefined) {
          activity.tolls = "0.0";
        }
        activity.tolls = dateUtils.roundFraction(
          parseFloat(activity.tolls) + parseFloat(newData.tolls),
          2
        );
      }
      if (newData.misc) {
        if (activity.misc === undefined) {
          activity.misc = "0.0";
        }
        activity.misc = dateUtils.roundFraction(
          parseFloat(activity.misc) + parseFloat(newData.misc),
          2
        );
      }
    }
    activityTable.push(newData);
  };

  /* data = array of objects having a "date" field (format: YYYY-MM-DD)
     month = string of month (format: YYYY-MM-DD or YYYY-MM)
     returns only the elements with a matching date for the month
  */
  filterMonth(data, month) {
    let ret = [];
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].date &&
        data[i].date.substring(0, 7) === month.substring(0, 7)
      ) {
        ret.push(data[i]);
      }
    }
    return ret;
  }

  initData = async (data) => {
    let month = data.date;

    if (data.activity === undefined) {
      data.activity = [];
    }

    let beginningMiles = 0;
    let endingMiles = 0;
    let specialMiles = 0;
    let districtMiles = 0;

    let holidays = await holidayService.getAllAsyncLookup();
    holidays = this.filterMonth(holidays, month);
    for (let i = 0; i < holidays.length; i++) {
      this.addActivity(data.activity, {
        _id: holidays[i].date,
        type: "Holiday",
        description: holidays[i].name,
      });
    }

    let activities = await activityService.getAllAsyncLookup();
    activities = this.filterMonth(activities, month);
    for (let i = 0; i < activities.length; i++) {
      let activity = activities[i];
      if (activity._author !== data.userid) {
        continue;
      }

      if (activity.beginningMiles && activity.endingMiles) {
        let bm = parseFloat(activity.beginningMiles);
        let em = parseFloat(activity.endingMiles);
        if (bm > 0 && em > 0 && bm < em) {
          if (beginningMiles > bm || beginningMiles === 0) {
            beginningMiles = bm;
          }
          if (endingMiles < em) {
            endingMiles = em;
          }
          if (activity.type !== "Personal Miles") {
            districtMiles += endingMiles - beginningMiles;
          }
        }
      }

      if (activity.specialMiles) {
        let sm = parseFloat(activity.specialMiles);
        if (sm > 0) {
          specialMiles += sm;
        }
      }

      let type = activity.type;
      if (type === "Bible Study") {
        this.addActivity(data.activity, {
          _id: activity.date,
          type: "Work",
          description: "Bible Study",
          districtMiles: dateUtils.roundFraction(
            parseFloat(activity.endingMiles) -
              parseFloat(activity.beginningMiles),
            1
          ),
        });
      } else if (type === "Visitation") {
        this.addActivity(data.activity, {
          _id: activity.date,
          type: "Work",
          description: "Visitation",
          districtMiles: dateUtils.roundFraction(
            parseFloat(activity.endingMiles) -
              parseFloat(activity.beginningMiles),
            1
          ),
        });
      } else if (type === "Event") {
        this.addActivity(data.activity, {
          _id: activity.date,
          type: "Work",
          description: "Event (" + activity.comment + ")",
          districtMiles: dateUtils.roundFraction(
            parseFloat(activity.endingMiles) -
              parseFloat(activity.beginningMiles),
            1
          ),
        });
      } else if (type === "Sermon Prep") {
        this.addActivity(data.activity, {
          _id: activity.date,
          type: "Work",
          description: "Sermon Prep",
        });
      } else if (type === "Baptism") {
        this.addActivity(data.activity, {
          _id: activity.date,
          type: "Work",
          description: "Baptism/PoF",
        });
      } else if (type === "Expense") {
        this.addActivity(data.activity, {
          _id: activity.date,
          type: "Work",
          description: "Expense (" + activity.comment + ")",
          specialMiles: activity.specialMiles,
          perDiem: activity.perDiem,
          tolls: activity.tolls,
          misc: activity.misc,
        });
      } else if (type === "Church Meeting") {
        this.addActivity(data.activity, {
          _id: activity.date,
          type: "Work",
          description: activity.comment || "Church Meeting",
          districtMiles: activity.endingMiles - activity.beginningMiles,
        });
      }
    }

    let vacations = await vacationService.getAllAsyncLookup();
    for (let i = 0; i < vacations.length; i++) {
      let dateList = dateUtils.sqlDateList(
        vacations[i].startDate,
        vacations[i].endDate
      );
      dateList.forEach((date) => {
        if (
          date.substring(0, 7) === month.substring(0, 7) &&
          vacations[i].userid[0]._id === data.userid
        ) {
          this.addActivity(data.activity, {
            _id: date,
            type: "Vacation",
            description: "Vacation",
          });
        }
      });
    }

    data.beginningMiles1 = dateUtils.roundFraction(beginningMiles, 1);
    data.endingMiles1 = dateUtils.roundFraction(endingMiles, 1);
    data.personalMiles1 = dateUtils.roundFraction(
      endingMiles - beginningMiles - specialMiles - districtMiles,
      1
    );
    data.specialMiles1 = dateUtils.roundFraction(specialMiles, 1);
    return data;
  };

  entryform = undefined;

  render() {
    return (
      <EntryForm
        setRef={(ref) => {
          this.entryform = ref;
        }}
        service={ministryReportService}
        match={this.props.match}
        history={this.props.history}
        initData={this.initData}
      />
    );
  }
}
