/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Complete from "./common/complete";
import { ministryReportService } from "../services/ministryReportService";
import { ministryGoalsService } from "../services/ministryGoalsService";
import { baptismReportService } from "../services/baptismReportService";
import { bibleWorkerReportService } from "../services/bibleWorkerReportService";
import MinistryReport from "./ministryReport";
import MinistryGoals from "./ministryGoals";
import BibleWorkerReport from "./bibleWorkerReport";
import BaptismReport from "./baptismReport";
import authService from "../services/authService";

import ImageButton from "./common/imagebutton";
import BaptismImage from "../resources/baptism.png";
import GoalsImage from "../resources/goals.png";
import BibleWorkerImage from "../resources/bibleworker.png";
import MinistryReportsImage from "../resources/ministryreports.png";

export default class ReportsPage extends Component {
  render() {
    let publicURL = `${process.env.PUBLIC_URL}`;
    return (
      <div>
        <h3>SNEC Office Reports</h3>
        {ministryReportService.hasReadPermissions() && (
          <ImageButton
            link={publicURL + "/reports/ministryreport"}
            addLink={async (e) => {
              e.preventDefault();
              MinistryReport.showCreateDialog(this.props.history);
            }}
            label="Ministry Report"
            image={MinistryReportsImage}
          />
        )}
        {baptismReportService.hasReadPermissions() && (
          <ImageButton
            link={publicURL + "/reports/baptismreport"}
            addLink={async (e) => {
              e.preventDefault();
              BaptismReport.showCreateDialog(this.props.history);
            }}
            label="Baptism Reports"
            image={BaptismImage}
          />
        )}
        {ministryGoalsService.hasReadPermissions() && (
          <ImageButton
            link={publicURL + "/reports/ministrygoals"}
            addLink={
              ministryGoalsService.hasCreatePermissions() &&
              (async (e) => {
                e.preventDefault();
                MinistryGoals.showCreateDialog(this.props.history);
              })
            }
            label="Ministry Goals"
            image={GoalsImage}
          />
        )}
        {bibleWorkerReportService.hasReadPermissions() && (
          <React.Fragment>
            <ImageButton
              link={publicURL + "/reports/bibleworkerreport"}
              label="Bible Worker Reports"
              image={BibleWorkerImage}
              addLink={
                bibleWorkerReportService.hasCreatePermissions() &&
                (async (e) => {
                  e.preventDefault();
                  BibleWorkerReport.showCreateDialog(this.props.history);
                })
              }
            />
          </React.Fragment>
        )}
        {authService.hasGroup("ministrygoals") &&
          authService.hasGroup("ministryreport") && (
            <React.Fragment>
              <hr />
              <p>
                <Link
                  to={publicURL + "/reports/pastorsummary"}
                  className="btn btn-secondary"
                >
                  Report Summary (all Pastors)
                </Link>{" "}
              </p>
            </React.Fragment>
          )}
        <Complete />
      </div>
    );
  }
}
