/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import { Link, NavLink } from "react-router-dom";
import authService from "../services/authService";
import { activityDefinition } from "../services/activityService";
import { contactDefinition } from "../services/contactService";
import { vacationDefinition } from "../services/vacationService";
import { eventRequestDefinition } from "../services/eventRequestService";
import { serviceRequestDefinition } from "../services/serviceRequestService";
import { travelRequestDefinition } from "../services/travelRequestService";
import { evangelismFundsDefinition } from "../services/evangelismFundsService";
import { ministryReportDefinition } from "../services/ministryReportService";
import { ministryGoalsDefinition } from "../services/ministryGoalsService";
import { usersDefinition } from "../services/userService";
import { groupDefinition } from "../services/groupService";
import utils from "../utils/utils";
import axios from "axios";

const definitions = [
  {
    baseUrl: "/home",
    viewTitle: "Home" + utils.environmentTitle(),
  },
  activityDefinition,
  contactDefinition,
  vacationDefinition,
  eventRequestDefinition,
  serviceRequestDefinition,
  travelRequestDefinition,
  evangelismFundsDefinition,
  {
    baseUrl: "/requests",
    viewTitle: "Requests",
  },
  ministryReportDefinition,
  ministryGoalsDefinition,
  {
    baseUrl: "/reports",
    viewTitle: "Reports",
  },
  { baseUrl: "/links", viewTitle: "Links" },
  usersDefinition,
  groupDefinition,
  {
    baseUrl: "/settings",
    viewTitle: "Settings",
  },
  {
    baseUrl: "/logout",
    viewTitle: "Logout (" + authService.getUsername() + ")",
  },
];

export default class NavBar extends React.Component {
  state = {
    collapseMenu: true,
    updateAvailable: "",
    updateInstalled: localStorage.getItem("update_installed") || "",
  };

  handleCloseMenu = () => {
    this.setState({ collapseMenu: true });
  };

  checkUpdate = async () => {
    try {
      let version = await axios.get("/snecapp.client.build");
      if (version.data) {
        version = version.data;
      }
      const posNewline = version.indexOf("\n");
      if (posNewline !== -1) {
        version = version.substring(0, posNewline);
      }
      this.setState({
        updateAvailable: version !== utils.getBuild() ? version : "",
      });
    } catch (e) {
      console.log("Unable to check update", e);
      this.setState({ updateAvailable: "" });
    }
  };

  componentDidMount = async () => {
    await this.checkUpdate();
    setInterval(async () => {
      await this.checkUpdate();
    }, 60 * 1000);
    if (this.state.updateInstalled) {
      setTimeout(() => {
        this.setState({ updateInstalled: "" });
        localStorage.removeItem("update_installed");
      }, 5 * 1000);
    }
  };

  render() {
    let publicUrl = `${process.env.PUBLIC_URL}`;
    let definition = definitions.find((d) =>
      window.location.pathname.startsWith(publicUrl + d.baseUrl)
    );
    let formID;
    if (
      definition &&
      window.location.pathname.startsWith(publicUrl + definition.baseUrl + "/")
    ) {
      formID = window.location.pathname.substring(
        (publicUrl + definition.baseUrl + "/").length
      );
    } else {
      formID = "";
    }
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          {authService.getUsername() !== undefined ? (
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                this.setState({ collapseMenu: !this.state.collapseMenu });
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>
          ) : (
            <span>&nbsp;</span>
          )}
          <Link
            className="navbar-brand d-none d-lg-inline"
            to={`${process.env.PUBLIC_URL}/`}
          >
            {"SNEC App" + utils.environmentTitle()}
          </Link>
          <div className="navbar-brand d-none d-inline d-lg-none">
            {!definition
              ? "SNEC App" + utils.environmentTitle()
              : formID === "new"
              ? definition.createTitle
              : formID
              ? definition.editTitle
              : definition.viewTitle}
          </div>
          {definition &&
          definition.createTitle &&
          !formID &&
          !definition.noPlusCreate ? (
            <Link
              type="button"
              className="btn btn-default btn-lg d-lg-none"
              to={publicUrl + definition.baseUrl + "/new"}
            >
              +
            </Link>
          ) : (
            <React.Fragment>&nbsp;</React.Fragment>
          )}
          <div
            className={
              this.state.collapseMenu
                ? "collapse navbar-collapse"
                : "navbar-collapse"
            }
            id="navbarNavAltMarkup"
          >
            <div className="navbar-nav">
              {authService.getUsername() !== undefined && (
                <React.Fragment>
                  {definitions.map((element) => {
                    if (element.hideNav) {
                      return null;
                    }
                    return (
                      <NavLink
                        key={element.baseUrl}
                        className="nav-item nav-link"
                        to={publicUrl + element.baseUrl}
                        onClick={this.handleCloseMenu}
                      >
                        {element.viewTitle}
                      </NavLink>
                    );
                  })}
                </React.Fragment>
              )}
            </div>
          </div>
        </nav>
        {this.state.updateInstalled ? (
          <div style={{ color: "#FF0000" }}>{this.state.updateInstalled}</div>
        ) : (
          ""
        )}
        {this.state.updateAvailable && (
          <div
            style={{
              color: "#FF0000",
              width: "100%",
            }}
          >
            Update {this.state.updateAvailable} available.{" "}
            <button
              style={{ color: "#FF0000" }}
              onClick={() => {
                localStorage.setItem("update_installed", "Update installed.");
                window.location.reload();
              }}
            >
              Install Update
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}
