/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

function getKey(form, id) {
  if (id === "new" || !id) {
    return "draft_" + form;
  } else {
    return "draft_" + form + "_" + id;
  }
}

//update draft
function updateDraft(form, id, data) {
  while (true) {
    try {
      localStorage.setItem(
        getKey(form, id),
        JSON.stringify({
          data: data,
          modified: new Date().getTime()
        })
      );
      break;
    } catch (error) {
      let oldestKey = undefined;
      let oldestModified = new Date().getTime();
      let keys = Object.keys(localStorage);
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        if (key.startsWith("draft_")) {
          let value = localStorage.getItem(key);
          if (value.modified && value.modified < oldestModified) {
            oldestKey = key;
          }
        }
      }
      if (oldestKey) {
        localStorage.removeItem(oldestKey);
      } else {
        break;
      }
    }
  }
}

function deleteDraft(form, id) {
  localStorage.removeItem(getKey(form, id));
}

function getDraft(form, id) {
  let data = localStorage.getItem(getKey(form, id));
  if (data) {
    data = JSON.parse(data);
  }
  if (data && data.data) {
    return data.data;
  } else {
    return null;
  }
}

function getModified(form, id) {
  let data = localStorage.getItem(getKey(form, id));
  if (data) {
    data = JSON.parse(data);
  }
  if (data && data.modified) {
    return data.modified;
  } else {
    return null;
  }
}

function getModifiedAgo(form, id) {
  let modified = getModified(form, id);
  if (modified) {
    let now = new Date().getTime();
    let diff = (now - modified) / 1000;
    if (diff < 60) {
      return Math.floor(diff) + " seconds ago";
    }
    diff = diff / 60;
    if (diff < 60) {
      return Math.floor(diff) + " minutes ago";
    }
    diff = diff / 60;
    if (diff < 24) {
      return Math.floor(diff) + " hours ago";
    }
    diff = diff / 24;
    return Math.floor(diff) + " days ago";
  } else {
    return null;
  }
}

export default {
  updateDraft,
  deleteDraft,
  getDraft,
  getModified,
  getModifiedAgo
};
