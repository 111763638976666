/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { userService } from "./userService";
import { bibleWorkerReportItemService } from "./bibleWorkerReportItemService";

export const bibleWorkerReportDefinition = {
  baseUrl: "/reports/bibleworkerreport",
  viewTitle: "Bible Worker Report",
  editTitle: "Edit Bible Worker Report",
  columnNames: ["userid", "date", "viewWorkflow"],
  fieldNames: ["userid", "date", "activity", "comment"],
  createPermissions: ["bible worker", "sysadmin"],
  deletePermissions: ["[author]", "bibleworkerreport", "sysadmin"],
  readPermissions: ["[author]", "bibleworkerreport", "sysadmin"],
  writePermissions: ["[author]", "bibleworkerreport", "sysadmin"],
  workflow: "bibleworkerreport",
  fields: {
    viewWorkflow: {
      label: "Workflow",
      type: "workflow"
    },
    userid: {
      label: "Name",
      required: true,
      type: "select",
      length: 64,
      selections: userService,
      default: "@UserID",
      readonly: true
    },
    date: {
      label: "Date",
      required: true,
      type: "date",
      default: "@Today",
      readonly: true
    },
    activity: {
      label: "Activity",
      type: "rows",
      isSection: true,
      service: bibleWorkerReportItemService
    },
    comment: {
      label: "Comment",
      required: false,
      type: "textarea",
      length: 255
    }
  }
};

export let bibleWorkerReportService = new EntryService(
  bibleWorkerReportDefinition
);
