/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import EntriesTable from "./entriesTable";
import Pagination from "./common/pagination";
import _ from "lodash";
import SearchBox from "./searchBox";
import utils from "../utils/utils";
import Complete from "./common/complete";
import dateUtils from "../utils/dateUtils";
import Workflow from "./common/workflow";
import ImageButton from "./common/imagebutton";

export default class Entries extends Component {
  state = {
    entries: [],
    currentPage: 1,
    pageSize: 100,
    search: this.initSearch(),
    groupValue: "",
    selectedGenre: null,
    sortColumn: { path: "name", order: "asc" },
  };

  initSearch() {
    let query = utils.getUrlVar("q") || "";
    let workflowValues = SearchBox.WORKFLOW_TYPES;
    let search = localStorage.getItem("search");
    if (search) {
      let wv = JSON.parse(search).workflowValues;
      if (wv) {
        workflowValues = "";
        SearchBox.WORKFLOW_TYPES.split(",").forEach((wt) => {
          if (("," + wv + ",").indexOf("," + wt + ",") !== -1) {
            if (workflowValues.length > 0) {
              workflowValues += ",";
            }
            workflowValues += wt;
          }
        });
        workflowValues = wv;
      }
    }
    return {
      query,
      workflowValues,
    };
  }

  async componentDidMount() {
    let entries = await this.props.service.getAllAsyncLookup();
    this.setState({ entries: entries, complete: true });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (search) => {
    this.setState({ search: search, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  static splitWithQuotes(value) {
    let inQuote = false;
    let ret = [];
    let current = "";
    for (let i = 0; i < value.length; i++) {
      if (value[i] === '"') {
        if (inQuote && current !== "") {
          ret.push(current);
          current = "";
        }
        inQuote = !inQuote;
      } else if (value[i] === " " && !inQuote) {
        if (current !== "") {
          ret.push(current);
          current = "";
        }
      } else {
        current += value[i];
      }
    }
    if (current !== "") {
      ret.push(current);
    }
    return ret;
  }

  static paginate(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    return _(items).slice(startIndex).take(pageSize).value();
  }

  static searchFilter(data, search, service) {
    let columnNames = service.getColumnNames();
    let query = Entries.splitWithQuotes(search.query.toLowerCase());

    let ret = [];
    data.forEach((row) => {
      let found = true;
      if (query) {
        for (let s = 0; s < query.length; s++) {
          let found2 = false;
          for (let cn = 0; cn < columnNames.length; cn++) {
            let columnName = columnNames[cn];
            if (row[columnName]) {
              if (typeof row[columnName] === "string") {
                let haystack = row[columnName].toLowerCase();
                if (haystack.indexOf(query[s]) !== -1) {
                  found2 = true;
                  break;
                }
              } else if (
                typeof row[columnName] === "object" &&
                row[columnName].props &&
                row[columnName].props.children
              ) {
                let haystack = row[columnName].props.children;
                if (haystack.indexOf(query[s]) !== -1) {
                  found2 = true;
                  break;
                }
              }

              if (row._workflow) {
                let workflowStatus = Workflow.getOverallStatus(row._workflow);
                if (found2 === false) {
                  if (workflowStatus.toLowerCase().indexOf(query[s]) !== -1) {
                    found2 = true;
                  }
                }
              }
            }
          }
          if (found2 === false) {
            found = false;
            break;
          }
        }
      }
      if (found) {
        if (row._workflow) {
          let workflowStatus = Workflow.getOverallStatus(row._workflow);
          if (
            ("," + search.workflowValues + ",").indexOf(
              "," + workflowStatus + ","
            ) !== -1
          ) {
            ret.push(row);
          } else if (
            ("," + SearchBox.WORKFLOW_TYPES + ",").indexOf(
              "," + workflowStatus + ","
            ) === -1
          ) {
            //show unknown workflow states always
            ret.push(row);
          }
        } else {
          ret.push(row);
        }
      }
    });
    return ret;
  }

  static sortColumn(entries, column, order, service) {
    let definition = service.getFieldDefinition(column);
    if (
      definition &&
      (definition.type === "number" ||
        definition.type === "miles" ||
        definition.type === "currency")
    ) {
      return entries.sort((a, b) => {
        let a1 = a[column] ? parseInt(a[column]) : 0;
        let b1 = b[column] ? parseInt(b[column]) : 0;
        if (a1 < b1) {
          return "asc" === order ? -1 : 1;
        } else if (b1 < a1) {
          return "asc" === order ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
    return entries.sort((a, b) => {
      let a1 = a[column] || "";
      let b1 = b[column] || "";
      if (typeof a1 === "object" || typeof b1 === "object") {
        return 0;
      }
      a1 = a1.toLowerCase();
      b1 = b1.toLowerCase();
      if (a1 < b1) {
        return "asc" === order ? -1 : 1;
      } else if (b1 < a1) {
        return "asc" === order ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  getFilteredData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      entries: allEntries,
    } = this.state;

    let entries = allEntries;
    if (this.props.customFilter !== undefined) {
      entries = this.props.customFilter(allEntries);
    }

    entries = Entries.flatten(entries, this.props.service);

    entries = Entries.sortColumn(
      entries,
      sortColumn.path,
      sortColumn.order,
      this.props.service
    );

    entries = Entries.forDisplay(entries, this.props.service);

    entries = Entries.searchFilter(
      entries,
      this.state.search,
      this.props.service
    );

    return Entries.groupFilter(
      entries,
      this.props.group,
      this.state.groupValue
    );
  };

  getPagedData = () => {
    const { pageSize, currentPage } = this.state;
    const entries = this.getFilteredData();

    let pageEntries = Entries.paginate(entries, currentPage, pageSize);

    return { totalCount: entries.length, data: pageEntries };
  };

  static groupFilter(entries, group, groupValue) {
    if (!group || !groupValue) {
      return entries;
    }
    return entries.filter(
      (entry) =>
        entry[group].toLowerCase().indexOf(groupValue.toLowerCase()) !== -1
    );
  }

  static flatten(data, service) {
    let data2 = [];
    for (let i = 0; i < data.length; i++) {
      data2[i] = { ...data[i] };
      let keys = service.getColumnNames();
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        let definition = service.getFieldDefinition(key);
        if (definition && definition.selections !== undefined) {
          if (
            definition.selections.isService &&
            definition.selections.isService()
          ) {
            let datas = data2[i][key];
            if (datas === undefined) {
              datas = [];
            }
            let out = "";
            for (let s = 0; s < datas.length; s++) {
              if (s > 0) {
                out += ",";
              }
              if (datas && datas[s] && datas[s].name) {
                out += datas[s].name;
              } else if (datas && datas[s]) {
                out += datas[s];
              } else {
                out += "undefined";
              }
            }
            data2[i][key] = out;
          }
        } else if (definition && definition.type === "workflow") {
          data2[i][key] = Workflow.getOverallStatus(data2[i]._workflow);
        } else if (definition && definition.type === "calculated") {
          data2[i][key] = definition.value(data2[i]);
        } else if (
          definition &&
          definition.type === "year" &&
          data2[i][key] &&
          data2[i][key].endsWith("-01-01")
        ) {
          data2[i][key] = data2[i][key].substring(0, 4);
        }
      }
    }

    return data2;
  }

  static forDisplay(data, service) {
    let data2 = [];
    for (let i = 0; i < data.length; i++) {
      data2[i] = { ...data[i] };
      let keys = service.getColumnNames();
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        let definition = service.getFieldDefinition(key);
        if (definition && definition.type === "date") {
          data2[i][key] = dateUtils.SQL_to_MDY(data2[i][key]);
        }
      }
    }

    return data2;
  }

  downloadRowsAsCSV = (data, filename) => {
    const content = data
      .map(
        (row) =>
          row
            .map(String) // convert every value to String
            .map((v) => v.replaceAll('"', '""')) // escape double colons
            .map((v) => `"${v}"`) // quote it
            .join(",") // comma-separated
      )
      .join("\r\n"); // rows starting on new lines

    // Create a blob
    var blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
  };

  static getNow = () => {
    const d = new Date();
    const year = d.getFullYear().toString();
    const month = (d.getMonth() < 9 ? "0" : "") + String(d.getMonth() + 1);
    const day = (d.getDate() < 10 ? "0" : "") + String(d.getDate());
    const hour = (d.getHours() < 10 ? "0" : "") + String(d.getHours());
    const minute = (d.getMinutes() < 10 ? "0" : "") + String(d.getMinutes());
    const seconds = (d.getSeconds() < 10 ? "0" : "") + String(d.getSeconds());

    return year + month + day + "_" + hour + minute + seconds;
  };

  exportView = () => {
    const columnNames = this.props.service.getColumnNames();
    let data = [];
    data.push(
      columnNames.map((cn) => this.props.service.getFieldDefinition(cn).label)
    );
    const rows = this.getFilteredData();
    rows.forEach((row) => {
      data.push(columnNames.map((cn) => row[cn]));
    });
    this.downloadRowsAsCSV(
      data,
      this.props.service.definition.viewTitle + "_" + Entries.getNow() + ".csv"
    );
  };

  exportForms = async () => {
    const formEntries = Entries.flatten(
      await this.props.service.getAllAsyncLookupAllFields(),
      this.props.service
    );
    const formEntriesById = new Map();
    formEntries.forEach((entry) => {
      formEntriesById.set(entry._id, entry);
    });

    let fieldNames = [];
    const fields = this.props.service.definition.fields;
    this.props.service.definition.fieldNames.forEach((field) => {
      if (
        fields[field] &&
        fields[field].label &&
        fields[field].type !== "calculated" &&
        fields[field].type !== "label"
      ) {
        fieldNames.push(field);
      }
    });
    if (formEntries.length > 0 && formEntries[0]._workflow !== undefined) {
      fieldNames.push("_workflow");
    }
    let data = [];
    data.push(fieldNames);
    this.getFilteredData().forEach((row) => {
      let entry = formEntriesById.get(row._id);
      let row2 = [];
      fieldNames.forEach((fieldName) => {
        let value = entry[fieldName];
        if (fieldName === "_workflow") {
          value = Workflow.getOverallStatus(value);
        }
        if (
          fields[fieldName] &&
          fields[fieldName].type === "date" &&
          value.length === 10 &&
          value.substring(4, 5) === "-" &&
          value.substring(7, 8) === "-"
        ) {
          value = dateUtils.SQL_to_MDY(value);
        }
        row2.push(value);
      });
      data.push(row2);
    });
    this.downloadRowsAsCSV(
      data,
      this.props.service.definition.viewTitle + "_" + Entries.getNow() + ".csv"
    );
  };

  render() {
    const { pageSize, currentPage, sortColumn, search } = this.state;

    const { totalCount, data: entries } = this.getPagedData();
    const groupDefinition =
      this.props.group &&
      this.props.service.getFieldDefinition(this.props.group);

    let groupOptions = {};
    if (groupDefinition) {
      this.state.entries.forEach((entry) => {
        let name = entry[this.props.group];
        if (
          groupDefinition.type === "year" &&
          name.length === 10 &&
          name.endsWith("-01-01")
        ) {
          name = name.substring(0, 4);
        }
        if (name) {
          groupOptions[name] = name;
        }
      });
    }
    groupOptions = Object.keys(groupOptions).map((go) => {
      return { _id: go, name: go };
    });

    let group = groupDefinition && {
      label: groupDefinition.label,
      name: this.props.group,
      value: this.state.groupValue,
      options: groupOptions,
      onChange: ({ currentTarget: input }) => {
        this.setState({ groupValue: input.value });
      },
    };
    return (
      <div className="col">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {this.props.service.hasCreatePermissions() &&
            this.props.service.getCreateTitle() && (
              <ImageButton
                addLink={this.props.service.getBaseUrl() + "/new"}
                label={this.props.service.getCreateTitle()}
              />
            )}
        </div>
        <SearchBox
          search={search}
          onChange={this.handleSearch}
          hasWorkflow={this.props.service.hasWorkflow()}
          group={group}
        />
        <EntriesTable
          data={entries}
          service={this.props.service}
          sortColumn={sortColumn}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
          totalsColumns={this.props.totalsColumns}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ImageButton
              onClick={() => {
                this.exportView();
              }}
              label="Export View"
              style={{
                height: "40px",
                width: "auto",
              }}
            />
            <ImageButton
              onClick={() => {
                this.exportForms();
              }}
              label="Export Forms"
              style={{
                height: "40px",
                width: "auto",
              }}
            />
          </div>
        </div>
        {this.state.complete && <Complete />}
      </div>
    );
  }
}
