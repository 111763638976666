/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Dialog from "./common/dialog";
import Entries from "./entries";
import dateUtils from "../utils/dateUtils";
import { baptismReportService } from "../services/baptismReportService";
import { activityService } from "../services/activityService";
import draftStorage from "../utils/draftStorage";

export default class BaptismReport extends Component {
  static async showCreateDialog(history) {
    let dataActivities = await activityService.getAllAsyncLookup();
    let baptisms = dataActivities.filter(
      activity => activity.type === "Baptism"
    );

    let data = draftStorage.getDraft(baptismReportService.getForm(), "new");
    if (data) {
      //draft already exists - skip combo box dialog
      history.push("/reports/baptismreport/new");
    } else {
      let options = [];
      baptisms.forEach(baptism =>
        options.push({
          _id: baptism._id,
          name:
            baptism.contact[0].firstName +
            " " +
            baptism.contact[0].lastName +
            " (" +
            dateUtils.SQL_to_MDY(baptism.date) +
            ")"
        })
      );

      if (options.length === 0) {
        history.push("/reports/baptismreport/new");
      } else {
        Dialog.showComboOkCancel(
          "Create + populate new Baptism Report",
          "Select recent baptism to auto-populate report (select empty entry to start without populating)",
          options,
          options && options[options.length - 1]._id,
          async baptismid => {
            let options = "";
            if (baptismid !== undefined) {
              let baptism = baptisms.find(baptism => baptismid === baptism._id);
              if (baptism) {
                options =
                  "?date=" +
                  baptism.date +
                  "&baptismType=" +
                  baptism.baptismType +
                  "&contactid=" +
                  baptism.contact[0]._id;
              }
            }
            history.push("/reports/baptismreport/new" + options);
          }
        );
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-primary btn-space"
          onClick={async e => {
            e.preventDefault();
            BaptismReport.showCreateDialog(this.props.history);
          }}
        >
          Create + populate new Baptism Report
        </button>

        <Entries service={baptismReportService} />
      </React.Fragment>
    );
  }
}
