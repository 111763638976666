/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";

export default class TextArea extends Component {
  render() {
    let {
      name,
      label,
      error,
      value,
      type,
      setRef,
      readOnly,
      onChange,
      onBlur,
      autoComplete
    } = this.props;
    return (
      <React.Fragment>
        <div className="form-group">
          {label && <label htmlFor={name}>{label}</label>}
          {readOnly ? (
            value.split("\n").map((v, index) => (
              <React.Fragment key={index}>
                {index > 0 && <br />}
                {v}
              </React.Fragment>
            ))
          ) : (
            <React.Fragment>
              <span className="d-none d-print-block">{value}</span>
              <textarea
                type={type}
                name={name}
                id={name}
                className="form-control d-print-none"
                ref={input => setRef && setRef(name, input)}
                readOnly={readOnly}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onBlur({ currentTarget: { name: name, value: value } });
                  }
                }}
                autoComplete={autoComplete}
                rows="10"
                value={value}
              ></textarea>
            </React.Fragment>
          )}
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </React.Fragment>
    );
  }
}
