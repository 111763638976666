/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import { churchService } from "../services/churchService";
import authService from "../services/authService";
import { userService } from "../services/userService";
import CacheService from "../services/cacheService";

/* get current users' church names (text only) */
export async function getChurchNames(cache) {
  if (!cache) {
    cache = new CacheService();
  }
  let allChurchesArray = await cache.getAllAsync(churchService);
  let allChurches = {};
  for (let i = 0; i < allChurchesArray.length; i++) {
    allChurches[allChurchesArray[i]._id] = allChurchesArray[i];
  }

  if (authService.getProfile().token) {
    let churches = [];
    let user = await cache.getAsync(
      userService,
      authService.getProfile().token
    );
    if (user.churches) {
      let userChurchesArray = user.churches.split(",");
      for (let i = 0; i < userChurchesArray.length; i++) {
        churches.push(allChurches[userChurchesArray[i]].name);
      }
      return churches;
    }
  }
  return null;
}

export default {
  getChurchNames
};
