/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import axios from "axios";
import decode from "jwt-decode";
import utils from "../utils/utils";

async function login(email, password) {
  try {
    let res = await axios.post(utils.getDbUrl() + "login.php", {
      email: email,
      password: password,
    });
    if (res.data && res.data.token) {
      setToken(res.data.token);
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function isValidEmail(email) {
  try {
    let res = await axios.post(utils.getDbUrl() + "login.php", {
      checkemail: email,
    });
    if (res.data && res.data.error) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
  return false;
}

async function loginSession(sessionid) {
  try {
    let res = await axios.post(utils.getDbUrl() + "session.php", {
      sessionid: sessionid,
    });
    if (res.data && res.data.token) {
      setToken(res.data.token);
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function changePassword(password, sessionid) {
  try {
    let res = await axios.post(utils.getDbUrl() + "session.php", {
      sessionid: sessionid,
      password: password,
    });
    if (res.data && res.data.token) {
      setToken(res.data.token);
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function resetPassword(email) {
  try {
    let res = await axios.post(utils.getDbUrl() + "login.php", {
      resetemail: email,
    });
    if (res.data && res.data.error) {
      return res.data.error;
    } else if (res.data && res.data.msg) {
      return res.data.msg;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

function setToken(idToken) {
  // Saves user token to localStorage
  localStorage.setItem("id_token", idToken);
  groupsCached = undefined;
}

function getToken() {
  // Retrieves the user token from localStorage
  return localStorage.getItem("id_token");
}

async function refreshGroups() {
  const token = getToken();
  if (!token) {
    return;
  }

  try {
    let res = await axios.post(utils.getDbUrl() + "login.php", {
      token: token,
    });
    if (res.data && res.data.token) {
      setToken(res.data.token);
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

function logout() {
  // Clear user token and profile data from localStorage
  localStorage.removeItem("id_token");
  groupsCached = undefined;
}

function getProfile() {
  let token = getToken();
  if (token === null) {
    return undefined;
  }
  let ret = decode(getToken());
  return JSON.parse(ret);
}

function getUsername() {
  let profile = getProfile();
  if (profile === undefined) {
    return undefined;
  } else {
    return profile.name; //"Andreas Goelzer";
  }
}

function getEmail() {
  let profile = getProfile();
  if (profile === undefined) {
    return undefined;
  } else {
    return profile.email; //"agolzer@agolzer.com";
  }
}

/* returns groups as String array of user which includes:
   - user's UUID (token)
   - [all] group
   - all groups names (lowercase)
*/
let groupsCached = undefined;
function getGroups() {
  if (groupsCached) {
    return groupsCached;
  }
  let profile = getProfile();
  if (profile === undefined) {
    return [];
  }
  let ret = [];
  ret.push(profile.token);
  ret.push("[all]");
  let keys = Object.keys(profile.groups);
  for (let k = 0; k < keys.length; k++) {
    ret.push(profile.groups[keys[k]].toLowerCase());
  }
  groupsCached = ret;
  return ret;
}

function hasGroup(group) {
  let profile = getProfile();
  if (profile === undefined) {
    return false;
  }
  group = group.toLowerCase();
  let keys = Object.keys(profile.groups);
  for (let k = 0; k < keys.length; k++) {
    if (profile.groups[keys[k]].toLowerCase() === group) {
      return true;
    }
  }
  return false;
}

setInterval(() => {
  refreshGroups();
}, 5 * 60 * 1000);

export default {
  getUsername,
  getEmail,
  isValidEmail,
  logout,
  login,
  loginSession,
  changePassword,
  resetPassword,
  getProfile,
  getToken,
  getGroups,
  hasGroup,
};
