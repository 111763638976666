/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import WorkflowList from "./workflowList";
import Complete from "./common/complete";
import utils from "../utils/utils";

import ImageButton from "./common/imagebutton";
import ChartImage from "../resources/chart.png";
import RequestsImage from "../resources/requests.jpg";
import InterviewImage from "../resources/interview.png";
import AddressbookImage from "../resources/addressbook.png";

export default class HomePage extends Component {
  render() {
    let publicURL = `${process.env.PUBLIC_URL}`;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md">
            <h3>Personal Items</h3>
            <ImageButton
              link={publicURL + "/activities"}
              addLink={publicURL + "/activities/new"}
              label="Activity"
              image={InterviewImage}
            />
            <ImageButton
              link={publicURL + "/contacts"}
              addLink={publicURL + "/contacts/new"}
              label="Contacts"
              image={AddressbookImage}
            />
            <h3>SNEC Office Items</h3>
            <ImageButton
              link={publicURL + "/reports"}
              label="Reports"
              image={ChartImage}
            />
            <ImageButton
              link={publicURL + "/requests"}
              label="Requests"
              image={RequestsImage}
            />
          </div>{" "}
          <div className="col-md"></div>
          <br />
          <br />
          <br />
          <Complete />
        </div>
        <h3>Workflow items</h3>
        <WorkflowList type="button" />
        <span style={{ fontSize: "8pt" }}>Build: {utils.getBuild()}</span>
      </div>
    );
  }
}
