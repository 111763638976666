/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import dateUtils from "../utils/dateUtils";

export const bibleWorkerReportItemDefinition = {
  baseUrl: "/reports/bibleworkerreport",
  viewTitle: "Bible Worker Report Entries",
  editTitle: "Edit Bible Worker Report Entry",
  createTitle: "New Bible Worker Report Entry",
  columnNames: [
    "viewWeekday",
    "date",
    "timeIn",
    "timeOut",
    "timeTotal",
    "activity",
  ],
  fieldNames: ["date", "timeIn", "timeOut", "timeTotal", "activity"],
  totalsColumns: ["timeTotal"],
  createPermissions: ["bible worker", "sysadmin"],
  deletePermissions: ["[author]", "bibleworkerreport", "sysadmin"],
  readPermissions: ["[author]", "bibleworkerreport", "sysadmin"],
  writePermissions: ["[author]", "bibleworkerreport", "sysadmin"],
  fields: {
    viewWeekday: {
      label: "Weekday",
      required: false,
      type: "calculated",
      readonly: true,
      fields: ["date"],
      value: (data) =>
        data.date &&
        ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
          dateUtils.SQL_to_Date(data.date).getUTCDay()
        ],
    },
    date: {
      label: "Date",
      required: true,
      type: "date",
      readonly: true,
    },
    timeIn: {
      label: "Time In",
      required: false,
      type: "time",
    },
    timeOut: {
      label: "Time Out",
      required: false,
      type: "time",
    },
    timeTotal: {
      label: "Total time",
      required: false,
      type: "calculated",
      showOnly: "type:Bible Worker",
      fields: ["timeIn", "timeOut"],
      value: (data) => {
        if (!data.timeIn || !data.timeOut) {
          return "";
        }
        let hourMinuteSecond1 = data.timeIn.split(":");
        let hourMinuteSecond2 = data.timeOut.split(":");
        let hours =
          parseInt(hourMinuteSecond2[0]) - parseInt(hourMinuteSecond1[0]);
        let minutes =
          parseInt(hourMinuteSecond2[1]) - parseInt(hourMinuteSecond1[1]);
        if (minutes < 0) {
          minutes += 60;
          hours--;
        }
        if (minutes === 0) {
          return hours + " hours";
        } else {
          return hours + " hours " + minutes + " min";
        }
      },
    },
    activity: {
      label: "Activity",
      required: false,
      type: "text",
    },
  },
};

export let bibleWorkerReportItemService = new EntryService(
  bibleWorkerReportItemDefinition
);
