/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { useMemo, useState } from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { docReadService } from "../services/docReadService";

export default class DocForm extends Form {
  render() {
    return (
      <EntryForm
        service={docReadService}
        match={this.props.match}
        history={this.props.history}
      />
    );
  }
}
