/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import preval from "preval.macro";

function getUrlVars() {
  var vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = decodeURI(value);
    }
  );
  return vars;
}

function getUrlVar(key) {
  let vars = getUrlVars();
  if (vars === undefined) {
    return undefined;
  }
  return vars[key];
}

function isLocal() {
  const hostname = window.location.hostname;
  if (
    hostname === "www.snecapp.org" ||
    hostname === "snecapp.org" ||
    hostname === "www.snecapp.com" ||
    hostname === "snecapp.com" ||
    hostname === "staging.snecapp.org" ||
    hostname === "staging.snecapp.com"
  ) {
    return false;
  } else {
    return true;
  }
}

function environmentTitle() {
  let { hostname } = window.location;
  if (!isLocal()) {
    if (
      hostname === "www.snecapp.org" ||
      hostname === "snecapp.org" ||
      hostname === "www.snecapp.com" ||
      hostname === "snecapp.com"
    ) {
      return "";
    } else if (
      hostname === "staging.snecapp.org" ||
      hostname === "staging.snecapp.com"
    ) {
      return " - Staging";
    } else {
      return " - Unknown environment";
    }
  } else {
    return " - Local";
  }
}

function getDbUrl() {
  //window.location.host returns "staging.snecapp.org" for staging or "localhost:3000" for dev
  let publicUrl = `${process.env.PUBLIC_URL}`;
  if (isLocal()) {
    return "https://staging.snecapp.org/db/";
  } else {
    return publicUrl + "/db/";
  }
}

function getBuild() {
  return preval`const now = new Date(); 
  function twoDigits(x){
    if(x<10) {
      return "0"+String(x);
    }
    return String(x);
  }
  module.exports = 
    twoDigits(now.getUTCFullYear()) + 
    twoDigits(now.getUTCMonth()+1) + 
    twoDigits(now.getUTCDate()) + 
    "_" + 
    twoDigits(now.getUTCHours()) + 
    twoDigits(now.getUTCMinutes()) + 
    twoDigits(now.getUTCSeconds());`;
}

export default {
  getUrlVars,
  getUrlVar,
  environmentTitle,
  getDbUrl,
  getBuild,
};
