/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { userService } from "./userService";
import { contactService } from "./contactService";
import dateUtils from "../utils/dateUtils";

export const activityDefinition = {
  baseUrl: "/activities",
  viewTitle: "Activities",
  editTitle: "Edit Activity",
  createTitle: "New Activity",
  columnNames: [
    "userid",
    "date",
    "type",
    "viewContact",
    "comment",
    "viewMiles",
    "lessonNumber",
  ],
  fieldNames: [
    "userid",
    "date",
    "type",
    "beginningMiles",
    "endingMiles",
    "contact",
    "baptismType",
    "lessonNumber",
    "timeIn",
    "timeOut",
    "timeTotal",
    "activity",
    "specialMiles",
    "perDiem",
    "tolls",
    "misc",
    "comment",
  ],
  createPermissions: ["Pastor", "sysadmin"],
  deletePermissions: ["[author]", "activity", "sysadmin"],
  readPermissions: ["[author]", "activity", "sysadmin"],
  writePermissions: ["[author]", "activity", "sysadmin"],
  fields: {
    viewContact: {
      label: "Contact",
      type: "calculated",
      fields: ["contact[0].firstName", "contact[0].lastName"],
      value: (data) => {
        let contact = data.contact && data.contact[0];
        return contact
          ? data.contact[0].firstName + " " + data.contact[0].lastName
          : null;
      },
    },
    viewMiles: {
      label: "Miles",
      type: "calculated",
      fields: ["beginningMiles", "endingMiles"],
      value: (data) => {
        if (
          data.beginningMiles &&
          data.endingMiles &&
          !isNaN(data.beginningMiles) &&
          !isNaN(data.endingMiles)
        ) {
          let diff =
            parseFloat(data.endingMiles) - parseFloat(data.beginningMiles);
          let ret = dateUtils.roundFraction(diff, 1);
          return ret !== "0.0" && ret;
        } else {
          return null;
        }
      },
      display: "lg xl",
    },
    userid: {
      label: "Name",
      required: true,
      type: "select",
      length: 64,
      selections: userService,
      default: "@UserID",
      readonly: true,
      display: "lg xl",
    },
    date: {
      label: "Date",
      required: true,
      type: "date",
      default: "@Today",
    },
    type: {
      label: "Type",
      required: true,
      type: "select",
      length: 255,
      selections: {
        "bible worker": "Bible Worker",
        pastor:
          "Bible Study,Visitation,Sermon Prep,Baptism,Expense,Personal Miles,Church Meeting",
        default:
          "Bible Study,Visitation,Sermon Prep,Baptism,Bible Worker,Expense,Personal Miles,Church Meeting",
      },
    },
    beginningMiles: {
      label: "Beginning Miles",
      required: true,
      type: "miles",
      showOnly:
        "type:Personal,Bible Study,Visitation,Event,Personal Miles,Church Meeting",
      toField: "endingMiles",
    },
    endingMiles: {
      label: "Ending Miles",
      required: true,
      type: "miles",
      showOnly:
        "type:Personal,Bible Study,Visitation,Event,Personal Miles,Church Meeting",
      fromField: "beginningMiles",
    },
    contact: {
      label: "Contact",
      required: true,
      type: "select",
      length: 64,
      selections: contactService,
      newOption: true,
      showOnly: "type:Bible Study,Visitation,Baptism",
    },
    baptismType: {
      label: "Baptism/PoF",
      required: true,
      type: "select",
      length: 80,
      selections: "Baptism,Profession of Faith",
      showOnly: "type:Baptism",
      display: "lg xl",
    },
    lessonNumber: {
      label: "Lesson Number",
      type: "text",
      length: 80,
      required: true,
      showOnly: "type:Bible Study",
      display: "lg xl",
    },
    timeIn: {
      label: "Time In",
      required: true,
      type: "time",
      showOnly: "type:Bible Worker",
      toField: "timeOut",
    },
    timeOut: {
      label: "Time Out",
      required: true,
      type: "time",
      showOnly: "type:Bible Worker",
      fromField: "timeIn",
    },
    timeTotal: {
      label: "Total time",
      required: false,
      type: "calculated",
      showOnly: "type:Bible Worker",
      fields: ["timeIn", "timeOut"],
      value: (data) => {
        if (!data.timeIn || !data.timeOut) {
          return "";
        }
        let hourMinuteSecond1 = data.timeIn.split(":");
        let hourMinuteSecond2 = data.timeOut.split(":");
        if (
          isNaN(hourMinuteSecond1[0]) ||
          isNaN(hourMinuteSecond1[1]) ||
          isNaN(hourMinuteSecond2[0]) ||
          isNaN(hourMinuteSecond2[1])
        ) {
          return "";
        }
        let hours =
          parseInt(hourMinuteSecond2[0]) - parseInt(hourMinuteSecond1[0]);
        let minutes =
          parseInt(hourMinuteSecond2[1]) - parseInt(hourMinuteSecond1[1]);
        if (minutes < 0) {
          minutes += 60;
          hours--;
        }
        if (minutes === 0) {
          return hours + " hours";
        } else {
          return hours + " hours " + minutes + " min";
        }
      },
    },
    activity: {
      label: "Activity",
      required: true,
      type: "text",
      length: 255,
      showOnly: "type:Bible Worker",
    },
    specialMiles: {
      label: "Special Miles",
      required: false,
      type: "miles",
      showOnly: "type:Expense",
    },
    perDiem: {
      label: "Per Diem",
      type: "currency",
      showOnly: "type:Expense",
    },
    tolls: {
      label: "Tolls",
      type: "currency",
      showOnly: "type:Expense",
    },
    misc: {
      label: "Misc",
      type: "currency",
      showOnly: "type:Expense",
    },
    comment: {
      label: "Comment",
      type: "text",
      length: 255,
      showOnly:
        "type:Personal,Bible Study,Visitation,Event,Sermon Prep,Baptism,Expense,Personal Miles,Church Meeting",
      display: "md lg xl",
    },
  },
};

export let activityService = new EntryService(activityDefinition);
