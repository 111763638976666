/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { baptismReportService } from "../services/baptismReportService";
import dateUtils from "../utils/dateUtils";
import { contactService } from "../services/contactService";

export default class BaptismReportForm extends Form {
  state = {
    selectedMonth: dateUtils.getMonthOptions(0, 0)[0]._id
  };

  addActivity = (activityTable, newData) => {
    for (let i = 0; i < activityTable.length; i++) {
      let activity = activityTable[i];
      if (activity._id !== newData._id) {
        continue;
      }

      if (activity.type !== newData.type) {
        activity.type = "";
      }
      activity.description += " // " + newData.description;

      if (newData.beginningMiles && newData.endingMiles) {
        if (activity.districtMiles === undefined) {
          activity.districtMiles = "0.0";
        }
        activity.districtMiles = dateUtils.roundFraction(
          parseFloat(activity.districtMiles) +
            parseFloat(newData.endingMiles) -
            parseFloat(newData.beginningMiles),
          1
        );
      }
      if (newData.specialMiles) {
        if (activity.specialMiles === undefined) {
          activity.specialMiles = "0.0";
        }
        activity.specialMiles = dateUtils.roundFraction(
          parseFloat(activity.specialMiles) + parseFloat(newData.specialMiles),
          1
        );
      }
      if (newData.perDiem) {
        if (activity.perDiem === undefined) {
          activity.perDiem = "0.0";
        }
        activity.perDiem = dateUtils.roundFraction(
          parseFloat(activity.perDiem) + parseFloat(newData.perDiem),
          2
        );
      }
      if (newData.tolls) {
        if (activity.tolls === undefined) {
          activity.tolls = "0.0";
        }
        activity.tolls = dateUtils.roundFraction(
          parseFloat(activity.tolls) + parseFloat(newData.tolls),
          2
        );
      }
      if (newData.misc) {
        if (activity.misc === undefined) {
          activity.misc = "0.0";
        }
        activity.misc = dateUtils.roundFraction(
          parseFloat(activity.misc) + parseFloat(newData.misc),
          2
        );
      }
    }
    activityTable.push(newData);
  };

  /* data = array of objects having a "date" field (format: YYYY-MM-DD)
     month = string of month (format: YYYY-MM-DD or YYYY-MM)
     returns only the elements with a matching date for the month
  */
  filterMonth(data, month) {
    let ret = [];
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].date &&
        data[i].date.substring(0, 7) === month.substring(0, 7)
      ) {
        ret.push(data[i]);
      }
    }
    return ret;
  }

  initData = async data => {
    if (data.contactid) {
      let contact = await contactService.getAsync(data.contactid);
      data.firstName = contact.firstName;
      data.middleName = contact.middleName;
      data.lastName = contact.lastName;
      data.address = contact.address;
      data.city = contact.city;
      data.state = contact.state;
      data.zip = contact.zip;
      data.email = contact.email;
      data.homePhone = contact.homePhone;
      data.cellPhone = contact.cellPhone;
      data.dob = contact.dob;
      data.maritalStatus = contact.maritalStatus;
      data.churchMembership = contact.churchMembership;
      data.occupation = contact.occupation;
    }
    return data;
  };

  entryform = undefined;

  render() {
    return (
      <EntryForm
        setRef={ref => {
          this.entryform = ref;
        }}
        service={baptismReportService}
        match={this.props.match}
        history={this.props.history}
        initData={this.initData}
      />
    );
  }
}
