/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Validator from "../utils/Validator";
import Form from "./common/form";
import authService from "../services/authService";
import Input from "./common/input";
import utils from "../utils/utils";
import Complete from "./common/complete";
import sdaLogo from "../resources/adventist-symbol-denim.png";

export default class LoginForm extends Form {
  state = {
    data: { email: "", password: "", password2: "" },
    error: null,
    form: "login", //"resetPasswordRequest", "resetPasswordLinkSubmitted", "register", "changePassword", "passwordChanged"
    completed: false
  };

  schema = {
    email: Validator.string()
      .required()
      .label("E-Mail"),
    password: Validator.string()
      .required()
      .label("Password")
  };

  getUrlParam(param) {
    var ret = undefined;
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
      m,
      key,
      value
    ) {
      if (key === param) {
        ret = decodeURIComponent(value);
      }
    });
    return ret;
  }

  async componentDidMount() {
    let sessionid = this.getUrlParam("sessionid");
    let redirect = this.getUrlParam("redirect");
    let reset = this.getUrlParam("reset");
    if (reset !== undefined) {
      this.setState({ form: "changePassword" });
    }
    let email = this.getUrlParam("email");
    if (email !== undefined) {
      let data = { ...this.state.data };
      data.email = email;
      this.setState({ data });
      if (authService.getEmail()) {
        if (authService.getEmail() === email) {
          window.location.href = redirect ? redirect : "/";
        } else {
          authService.logout();
          window.location.href =
            "/login?email=" +
            encodeURIComponent(email) +
            "&redirect=" +
            encodeURIComponent(redirect ? redirect : "/");
        }
      }
    }
    if (this.getUrlParam("sessionid")) {
      let error = await authService.loginSession(sessionid);
      if (error) {
        this.setState({ error });
      } else if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.href =
          "/settings/users/" + authService.getProfile().token;
      }
    }
    this.setState({ completed: true });
  }

  INVALID_EMAIL_PASSWORD =
    "invalid email-address or password. Please try again or ";

  async doSubmit() {
    let error = await authService.login(
      this.state.data.email,
      this.state.data.password
    );
    if (error) {
      this.setState({ error });
    } else if (authService.getUsername() === undefined) {
      this.setState({ error: this.INVALID_EMAIL_PASSWORD });
    } else {
      let publicUrl = `${process.env.PUBLIC_URL}`;
      let redirect = this.getUrlParam("redirect");
      if (redirect === undefined) {
        window.location = publicUrl + "/";
      } else {
        window.location = redirect;
      }
    }
  }

  async doResetPassword() {
    let error = await authService.resetPassword(this.state.data.email);
    this.setState({ error, form: "resetPasswordLinkSubmitted" });
    return null;
  }

  async doChangePassword(password, sessionid) {
    let error = await authService.changePassword(password, sessionid);
    if (error) {
      this.setState({ error });
    } else {
      this.setState({ error, form: "resetPasswordLinkSubmitted" });
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    const error = "";

    this.setState({ data, error });
  };

  renderResetPasswordLinkSubmitted() {
    return (
      <React.Fragment>
        <h2>Password Reset e-mail sent</h2>
        An e-mail has been sent to your address {this.state.data.email}. Please
        check your e-mail and click the link in the e-mail to reset your
        password.
        <br />
        <button
          onClick={async e => {
            e.preventDefault();
            window.location.href = "/";
          }}
          className="btn btn-primary btn-space"
        >
          Continue
        </button>
      </React.Fragment>
    );
  }

  renderPasswordChanged() {
    return (
      <React.Fragment>
        <h2>Password changed successfully</h2>
        Password has been changed successfully.
        <br />
        <button
          onClick={async e => {
            e.preventDefault();
            window.location.href = "/";
          }}
          className="btn btn-primary btn-space"
        >
          Login
        </button>
      </React.Fragment>
    );
  }

  renderResetPasswordRequest() {
    return (
      <div>
        <h3>Forgot Password?</h3>
        Please provide e-mail address of your account. An e-mail will be sent to
        this address with instructions to reset your password.
        <form onSubmit={this.handleSubmit}>
          <Input
            type="email"
            key="email"
            name="email"
            value={this.state.data.email}
            label={"E-Mail"}
            onChange={this.handleChange}
          />
          {this.state.error && (
            <div className="alert alert-danger">{this.state.error} </div>
          )}
          <button
            onClick={async e => {
              e.preventDefault();
              let isValid = await authService.isValidEmail(
                this.state.data.email
              );
              if (isValid) {
                await this.doResetPassword();
                this.setState({ form: "resetPasswordLinkSubmitted" });
              } else {
                this.setState({
                  error:
                    "Invalid e-mail address provided. This e-mail is not registered."
                });
              }
            }}
            className="btn btn-primary btn-space"
          >
            Reset Password
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button
            onClick={async e => {
              e.preventDefault();
              this.setState({ form: "login" });
            }}
            className="btn btn-secondary btn-space"
          >
            Cancel
          </button>
        </form>
      </div>
    );
  }

  renderRegister() {
    return (
      <div>
        <h3>Register</h3>
        Self registration is not permitted on this site. Please contact an Admin
        to register an account for you. Alternatively you can reset a password
        for an existing account.
        <br />
        <br />
        <form onSubmit={this.handleSubmit}>
          <button
            onClick={e => {
              e.preventDefault();
              this.setState({ form: "resetPasswordRequest" });
            }}
            className="btn btn-secondary btn-space"
          >
            Forgot Password
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button
            onClick={async e => {
              e.preventDefault();
              this.setState({ form: "login" });
            }}
            className="btn btn-secondary btn-space"
          >
            Cancel
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </form>
      </div>
    );
  }

  renderChangePassword() {
    return (
      <div>
        <h1>Change Password</h1>
        Provide new Password for {this.getUrlParam("u")} in the fields below:
        <form onSubmit={this.handleSubmit}>
          <Input
            type="password"
            key="password"
            name="password"
            value={this.state.data.password}
            label="Password"
            onChange={this.handleChange}
          />
          <Input
            type="password"
            key="password2"
            name="password2"
            value={this.state.data.password2}
            label="Re-enter Password"
            onChange={this.handleChange}
          />
          {this.state.error && (
            <div className="alert alert-danger">{this.state.error} </div>
          )}
          <button
            onClick={async e => {
              e.preventDefault();
              if (this.state.data.password !== this.state.data.password2) {
                this.setState({ error: "Passwords don't match. Try again" });
              } else {
                await this.doChangePassword(
                  this.state.data.password,
                  this.getUrlParam("reset")
                );
                this.setState({ form: "passwordChanged" });
              }
            }}
            className="btn btn-primary btn-space"
          >
            Change Password
          </button>
        </form>
      </div>
    );
  }

  renderLogin() {
    return (
      <React.Fragment>
        <img
          src={sdaLogo}
          alt="sdaLogo"
          width="200px"
          align="left"
          className="d-none d-md-inline"
        />
        <div>
          <h3>Sign in</h3>
          <br />
          <form onSubmit={this.handleSubmit}>
            <Input
              type="email"
              key="email"
              name="email"
              value={this.state.data.email}
              label={"E-Mail"}
              onChange={this.handleChange}
            />
            <Input
              type="password"
              key="password"
              name="password"
              value={this.state.data.password}
              label="Password"
              onChange={this.handleChange}
            />
            {this.state.error && (
              <div className="alert alert-danger">
                {this.state.error}{" "}
                {this.state.error === this.INVALID_EMAIL_PASSWORD && (
                  <button
                    onClick={async e => {
                      e.preventDefault();
                      this.setState({ form: "resetPasswordRequest" });
                    }}
                    className="btn btn-primary btn-space"
                  >
                    Reset Password
                  </button>
                )}
              </div>
            )}
            {this.renderSubmitButton("Login")}&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              onClick={e => {
                e.preventDefault();
                this.setState({ form: "resetPasswordRequest" });
              }}
              className="btn btn-secondary btn-space"
            >
              Forgot Password
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              onClick={e => {
                e.preventDefault();
                this.setState({ form: "register" });
              }}
              className="btn btn-secondary btn-space"
            >
              Register
            </button>
          </form>
          <br />
          <br />
          <br />
          <span style={{ fontSize: "8pt" }}>Build: {utils.getBuild()}</span>
        </div>
      </React.Fragment>
    );
  }

  render() {
    let ret = [];
    if (this.state.form === "resetPasswordRequest") {
      ret.push(this.renderResetPasswordRequest());
    } else if (this.state.form === "changePassword") {
      ret.push(this.renderChangePassword());
    } else if (this.state.form === "resetPasswordLinkSubmitted") {
      ret.push(this.renderResetPasswordLinkSubmitted());
    } else if (this.state.form === "passwordChanged") {
      ret.push(this.renderPasswordChanged());
    } else if (this.state.form === "register") {
      ret.push(this.renderRegister());
    } else {
      ret.push(this.renderLogin());
    }
    if (this.state.completed) {
      ret.push(<Complete />);
    }
    return ret;
  }
}
