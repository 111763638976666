/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import axios from "axios";
import authService from "./authService";
import utils from "../utils/utils";

class SqlParser {
  remaining = undefined;
  table = undefined;
  fieldLabels = [];
  fieldValues = [];

  constructor(line) {
    this.remaining = line;
    this.sql = line;
  }

  error(msg) {
    console.error("SQL Parsing error: " + msg);
    console.error("Remaining SQL string: " + this.remaining);
    console.error("Original SQL string: " + this.sql);
    throw new Error("ERROR: " + msg);
  }

  skipWhitespace() {
    while (true) {
      let ch = this.remaining.substring(0, 1);
      if (ch !== " " && ch !== "\t" && ch !== "\n" && ch !== "\r") {
        break;
      } else {
        this.remaining = this.remaining.substring(1);
      }
    }
  }

  getField() {
    let posEnd;
    let startPos = 0;
    if (this.remaining.startsWith("`")) {
      posEnd = this.remaining.indexOf("`", 1);
      if (posEnd === -1) {
        this.error("no end quote for field");
      }
      startPos = 1;
    } else if (this.remaining.startsWith('"')) {
      posEnd = this.remaining.indexOf('"', 1);
      if (posEnd === -1) {
        this.error("no end quote for field");
      }
      startPos = 1;
    } else if ((posEnd = this.remaining.indexOf(" ")) === -1) {
      this.error("no space after field name");
    }
    let ret = this.remaining.substring(startPos, posEnd);
    if (ret === "") {
      this.error("Field cannot be empty");
    }
    this.remaining = this.remaining.substring(posEnd + 1);
    return ret;
  }

  skipChar(ch) {
    if (this.remaining.startsWith(ch)) {
      this.remaining = this.remaining.substring(ch.length);
    } else {
      this.error('character "' + ch + '" required');
    }
  }

  getFieldLabels() {
    let labels = [];
    this.skipChar("(");
    while (true) {
      this.skipWhitespace();
      labels.push(this.getField());
      this.skipWhitespace();
      if (this.remaining.startsWith(")")) {
        this.skipChar(")");
        break;
      }
      this.skipChar(",");
      this.skipWhitespace();
    }
    return labels;
  }

  getValue() {
    let hasQuote = false;
    let hasBackslash = false;
    let value = "";
    while (this.remaining.length > 0) {
      let ch = this.remaining.substring(0, 1);
      if (!hasBackslash && ch === "\\") {
        hasBackslash = true;
      } else if (hasBackslash) {
        if (ch === '"') {
          value += '"';
        } else if (ch === "t") {
          value += "\t";
        } else if (ch === "\\") {
          value += "\\";
        } else if (ch === "u" && this.remaining.length > 4) {
          value += String.fromCharCode(
            parseInt(this.remaining.substring(1, 5), 16)
          );
          this.remaining = this.remaining.substring(4);
        } else {
          this.error("TODO backslash case for " + ch);
        }
        hasBackslash = false;
      } else if (ch === '"' && !hasQuote) {
        hasQuote = true;
      } else if (ch === '"' && hasQuote) {
        hasQuote = false;
      } else if ((ch === "," || ch === ")" || ch === " ") && !hasQuote) {
        return value;
      } else {
        value += ch;
      }
      this.remaining = this.remaining.substring(1);
    }
    if (value !== "") {
      this.error("value not closed " + value);
    }
    return value;
  }

  getFieldValues() {
    let values = [];
    this.skipChar("(");
    this.skipWhitespace();
    while (true) {
      values.push(this.getValue());
      this.skipWhitespace();
      if (this.remaining.startsWith(")")) {
        this.skipChar(")");
        break;
      }
      this.skipChar(",");
      this.skipWhitespace();
    }
    return values;
  }

  parseAll() {
    this.skipWhitespace();
    this.type = this.getField();
    if (this.type === "insert") {
      this.skipWhitespace();
      let into = this.getField();
      if (into !== "into") {
        this.error(
          'insert statement needs to be followed with "into" but was followed with "' +
            into +
            '"'
        );
      }
      this.skipWhitespace();
      this.table = this.getField();
      this.skipWhitespace();
      this.fieldLabels = this.getFieldLabels();
      this.skipWhitespace();
      this.skipChar("values");
      this.skipWhitespace();
      this.fieldValues = this.getFieldValues();
    } else if (this.type === "update") {
      this.skipWhitespace();
      this.table = this.getField();
      this.skipWhitespace();
      this.skipChar("set");
      this.skipWhitespace();
      while (true) {
        let field = this.getField();
        this.skipWhitespace();
        this.skipChar("=");
        let value = this.getValue();
        this.skipWhitespace();
        this.fieldLabels.push(field);
        this.fieldValues.push(value);
        if (!this.remaining.startsWith(",")) {
          break;
        }
        this.skipChar(",");
        this.skipWhitespace();
      }
    } else {
      this.error("invalid command");
    }

    let fields = [];
    if (this.fieldLabels.length !== this.fieldValues.length) {
      this.error(
        "count don't match. fieldLabels.length=" +
          this.fieldLabels.length +
          " fieldValues.length=" +
          this.fieldValues.length
      );
    } else {
      for (let i = 0; i < this.fieldLabels.length; i++) {
        fields.push({ name: this.fieldLabels[i], value: this.fieldValues[i] });
      }
    }

    return {
      type: this.type,
      table: this.table,
      fields
    };
  }

  static parse(line) {
    let sql = new SqlParser(line);
    return sql.parseAll();
  }
}

function hasDateTimeUnid(line) {
  let map =
    "0000-00-00 00:00:00 hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh ";
  if (line.length < map.length) {
    return false;
  }
  for (let i = 0; i < map.length; i++) {
    let chl = line[i];
    let mapl = map[i];
    if (mapl === "0") {
      if (chl < "0" || chl > "9") {
        return false;
      }
    } else if (mapl === "h") {
      if ((chl < "0" || chl > "9") && (chl < "a" || chl > "f")) {
        return false;
      }
    } else if (mapl !== chl) {
      return false;
    }
  }
  return true;
}

export default class HistoryService {
  static async getHistory(id) {
    let url = utils.getDbUrl() + "history.php?id=" + id;
    let res = await axios.get(url, {
      headers: { "X-Auth-Token": authService.getToken() }
    });
    let ret = [];
    let lines = res.data.split("\n");
    let line = "";
    for (let i = 0; i < lines.length; i++) {
      line += lines[i];
      if (i < lines.length - 1 && !hasDateTimeUnid(lines[i + 1])) {
        line += "\n";
        continue;
      }
      let parts = line.split(" ");
      if (parts.length > 3) {
        let sql = line.substring(
          parts[0].length + parts[1].length + parts[2].length + 3
        );
        if (sql.trim().length > 0) {
          let result = SqlParser.parse(sql);
          ret.push({
            timestamp: parts[0] + " " + parts[1],
            user: parts[2],
            ...result
          });
        }
      }
      line = "";
    }
    return ret;
  }
}
