/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const fileDefinition = {
  baseUrl: "/settings/files",
  viewTitle: "Files",
  editTitle: "Edit File",
  createTitle: "Create File",
  hideNav: true,
  fieldNames: ["label", "attachment"],
  columnNames: ["viewAttachment", "copyLink"],
  createPermissions: ["admin", "sysadmin"],
  deletePermissions: ["admin", "sysadmin"],
  readPermissions: ["[all]"],
  writePermissions: ["admin", "sysadmin"],
  fields: {
    viewAttachment: {
      label: "Attachment",
      required: false,
      type: "calculated",
      readonly: true,
      fields: ["attachment"],
      value: (data) => {
        let value = data.attachment;
        if (!value) {
          return "";
        }
        if (typeof value === "string") {
          try {
            value = JSON.parse(value);
          } catch (e) {
            //NOOP
          }
        }
        if (value && value[0] && value[0].name) {
          return value[0].name;
        }
        return "";
      },
    },
    copyLink: {
      label: "Copy Link",
      required: false,
      type: "calculated",
      readonly: true,
      fields: ["attachment"],
      value: (data) => {
        let value = data.attachment;
        if (!value) {
          return "";
        }
        if (typeof value === "string") {
          try {
            value = JSON.parse(value);
          } catch (e) {
            //NOOP
          }
        }
        if (value && value[0] && value[0].name) {
          return (
            <button
              onClick={() => {
                navigator.clipboard.writeText(value[0].name);
              }}
            >
              Copy Link
            </button>
          );
        }
      },
    },

    label: {
      label: "Upload a single file",
      type: "label",
    },
    attachment: {
      label: "File",
      required: true,
      type: "attachments",
      multiple: false,
    },
  },
};

export let fileService = new EntryService(fileDefinition);
