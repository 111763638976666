/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import { activityDefinition } from "./activityService";
import { churchDefinition } from "./churchService";
import { contactDefinition } from "./contactService";
import { docDefinition } from "./docService";
import { linkbuttonDefinition } from "./linkbuttonService";
import { fileDefinition } from "./fileService";
import { evangelismFundsDefinition } from "./evangelismFundsService";
import { groupDefinition } from "./groupService";
import { holidayDefinition } from "./holidayService";
import { serviceRequestDefinition } from "./serviceRequestService";
import { travelRequestDefinition } from "./travelRequestService";
import { usersDefinition } from "./userService";
import { vacationDefinition } from "./vacationService";
import { eventRequestDefinition } from "./eventRequestService";
import { workflowRuleDefinition } from "./workflowRuleService";
import { ministryReportDefinition } from "./ministryReportService";
import { ministryGoalsDefinition } from "./ministryGoalsService";
import { baptismReportDefinition } from "./baptismReportService";
import { bibleWorkerReportDefinition } from "./bibleWorkerReportService";

export default [
  activityDefinition,
  churchDefinition,
  contactDefinition,
  docDefinition,
  linkbuttonDefinition,
  fileDefinition,
  evangelismFundsDefinition,
  groupDefinition,
  holidayDefinition,
  serviceRequestDefinition,
  travelRequestDefinition,
  usersDefinition,
  vacationDefinition,
  eventRequestDefinition,
  workflowRuleDefinition,
  ministryReportDefinition,
  ministryGoalsDefinition,
  baptismReportDefinition,
  bibleWorkerReportDefinition,
];
