/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { ministryGoalsService } from "../services/ministryGoalsService";
import dateUtils from "../utils/dateUtils";
import lookups from "../utils/lookups";

export default class MinistryGoalsForm extends Form {
  state = {
    selectedMonth: dateUtils.getMonthOptions(0, 0)[0]._id,
    churches: undefined
  };

  async componentDidMount() {
    let churches = await lookups.getChurchNames();
    this.setState({ churches });
  }

  render() {
    if (
      this.state.churches !== undefined &&
      (this.state.churches === null || this.state.churches.length === 0) &&
      this.props.match.params.id === "new"
    ) {
      return (
        <h3>
          No churches assigned to this user. This is required to create ministry
          Goals.
        </h3>
      );
    }
    return (
      <EntryForm
        service={ministryGoalsService}
        match={this.props.match}
        history={this.props.history}
      />
    );
  }
}
