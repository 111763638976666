/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import WorkflowList from "./workflowList";
import Complete from "./common/complete";

export default class WorkflowPage extends Component {
  render() {
    return (
      <React.Fragment>
        <WorkflowList />
        <Complete />
      </React.Fragment>
    );
  }
}
