/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import EntryForm from "../entryForm";

class Dialog extends React.Component {
  static s_instance = undefined;
  static s_nextDialogId = 0;

  state = {
    dialogs: [],
  };

  componentDidMount() {
    if (Dialog.s_instance === undefined) {
      Dialog.s_instance = this;
    }
  }

  static getInstance() {
    if (Dialog.s_instance === undefined) {
      Dialog.s_instance = new Dialog();
    }
    return Dialog.s_instance;
  }

  render() {
    // The gray background
    const backdropStyle = {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      padding: 50,
      zIndex: 10,
    };

    // The modal "window"
    const modalStyle = {
      backgroundColor: "#fff",
      borderRadius: 5,
      maxWidth: 500,
      minHeight: 300,
      margin: "0 auto",
      padding: 30,
    };

    let ret = this.state.dialogs.map((dialog, dialogIndex) => (
      <div
        key={dialogIndex}
        style={{ ...backdropStyle, zIndex: 10 + dialogIndex }}
      >
        <div style={modalStyle}>
          {dialog.title && (
            <React.Fragment>
              {dialog.title}
              <hr />
            </React.Fragment>
          )}
          {dialog.body}
          {dialog.options && (
            <React.Fragment>
              <br />
              <select
                name="combo"
                onChange={(e) => {
                  dialog.defaultOption = e.target.value;
                }}
                defaultValue={dialog.defaultOption}
              >
                <option value=""></option>
                {dialog.options.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </React.Fragment>
          )}
          <div className="footer">
            {dialog.buttons &&
              dialog.buttons.map((button) => (
                <button
                  key={button}
                  onClick={(e) => {
                    e.preventDefault();
                    Dialog.removeDialog(dialog.id);
                    if (dialog.options) {
                      if (button === "Ok") {
                        dialog.callback(dialog.defaultOption);
                      } else {
                        dialog.callback(undefined);
                      }
                    } else {
                      dialog.callback(button);
                    }
                  }}
                >
                  {button}
                </button>
              ))}
          </div>
        </div>
      </div>
    ));
    return ret || null;
  }

  static showComboOkCancel = (
    title,
    body,
    options,
    defaultOption,
    callback
  ) => {
    let dialog = {
      id: Dialog.s_nextDialogId,
      title,
      body,
      buttons: ["Ok", "Cancel"],
      options,
      defaultOption,
      callback,
    };
    Dialog.s_nextDialogId++;
    let dialogs = [...Dialog.getInstance().state.dialogs, dialog];
    Dialog.getInstance().setState({ dialogs });
  };

  static showForm = (service, id, callback) => {
    let body = (
      <div style={{ overflow: "auto", height: "500px" }}>
        <EntryForm
          service={service}
          match={{ params: { id: "new" } }}
          callback={callback}
        />
      </div>
    );

    let dialog = {
      id: Dialog.s_nextDialogId,
      body,
      callback,
    };
    Dialog.s_nextDialogId++;
    let dialogs = [...Dialog.getInstance().state.dialogs, dialog];
    Dialog.getInstance().setState({ dialogs });
    return dialog.id;
  };

  static removeDialog(id) {
    let dialogs = Dialog.getInstance().state.dialogs;
    let retDialogs = [];
    for (let d = 0; d < dialogs.length; d++) {
      if (id !== dialogs[d].id) {
        retDialogs.push(dialogs[d]);
      }
    }
    Dialog.getInstance().setState({ dialogs: retDialogs });
  }

  static showYesNo = (title, body, callback) => {
    Dialog.showConfirmation(title, body, ["yes", "no"], callback);
  };

  static showMessage = (title, body) => {
    Dialog.showConfirmation(title, body, ["Ok"], (button) => {});
  };

  static showConfirmation = (title, body, buttons, callback) => {
    let dialog = { id: Dialog.s_nextDialogId, title, body, buttons, callback };
    Dialog.s_nextDialogId++;
    let dialogs = [...Dialog.getInstance().state.dialogs, dialog];
    Dialog.getInstance().setState({ dialogs });
  };

  static show = async (title, body) => {
    return new Promise((resolve, reject) => {
      let dialog = {
        id: Dialog.s_nextDialogId,
        title,
        body,
        buttons: ["Ok", "Cancel"],
        callback: (button) => resolve(button),
      };
      Dialog.s_nextDialogId++;
      let dialogs = [...Dialog.getInstance().state.dialogs, dialog];
      Dialog.getInstance().setState({ dialogs });
    });
  };
}

export default Dialog;
