/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2020
 */

import React, { Component } from "react";

export default class CalendarPage extends Component {
  render() {
    return (
      <React.Fragment>
        <iframe
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&src=b21vbmViMWZ0M2duaGcwNnBhNjVqcmJvNXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%239E69AF&showTitle=0&showNav=1&showDate=1&showTabs=1&showCalendars=1"
          style={{ border: "solid 1px #777", width: "100%", height: "600px" }}
          frameBorder="0"
          scrolling="no"
          title="SNEC Calendar"
        ></iframe>
      </React.Fragment>
    );
  }
}
