/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Entries from "./entries";
import { workflowRuleService } from "../services/workflowRuleService";
import { userService } from "../services/userService";

export default class WorkflowRules extends Component {
  state = {
    users: {}
  };

  async componentDidMount() {
    let usersArray = await userService.getAllAsync();
    let users = [];
    for (let u = 0; u < usersArray.length; u++) {
      users[usersArray[u]._id] = usersArray[u];
    }
    this.setState({ users });
  }
  customFilter = data => {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let rules = data[i].rules;
        if (rules) {
          if (typeof rules === "string") {
            rules = JSON.parse(rules);
          }
          for (let r = 0; r < rules.length; r++) {
            if (rules[r].user) {
              let user = this.state.users[rules[r].user];
              if (user) {
                rules[r].name = this.state.users[rules[r].user].name;
                rules[r].email = this.state.users[rules[r].user].email;
              }
            }
          }
          data[i].rules = JSON.stringify(rules);
        }
      }
    }
    return data;
  };

  render() {
    return (
      <Entries service={workflowRuleService} customFilter={this.customFilter} />
    );
  }
}
