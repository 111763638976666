/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";

// columns: array
// sortColumn: object
// onSort: function

class TableHeader extends Component {
  raiseSort = path => {
    if (this.props.onSort === undefined) {
      return;
    }

    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn !== undefined) {
      if (sortColumn.path === path)
        sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
      else {
        sortColumn.path = path;
        sortColumn.order = "asc";
      }
      this.props.onSort(sortColumn);
    }
  };

  renderSortIcon = column => {
    const { sortColumn } = this.props;
    if (sortColumn !== undefined) {
      if (column.path !== sortColumn.path) return null;
      if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
      return <i className="fa fa-sort-desc" />;
    }
    return null;
  };

  addDisplay(display) {
    if (display) {
      let d = display.split(" ");
      let ret = " d-none";
      for (let i = 0; i < d.length; i++) {
        ret += " d-" + d[i] + "-table-cell";
      }
      return ret;
    } else {
      return "";
    }
  }

  render() {
    let clickable = this.props.onSort ? "clickable" : "";
    return (
      <thead>
        <tr>
          {this.props.columns.map(column => (
            <th
              className={clickable + this.addDisplay(column.display)}
              key={column.path || column.key}
              onClick={() => this.raiseSort(column.path)}
            >
              {column.label} {this.renderSortIcon(column)}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
