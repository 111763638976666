/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import EntryService from "./entryService";
import { userService } from "./userService";
import sharedFields from "./sharedFields";

export const vacationDefinition = {
  baseUrl: "/requests/vacations",
  viewTitle: "Vacations",
  editTitle: "Edit Vacation Request",
  createTitle: "New Vacation Request",
  fieldNames: [
    "userid",
    "label1",
    "type",
    "year",
    "startDate",
    "endDate",
    "totalDays",
    "label2",
    "emergencyName",
    "emergencyAddress",
    "emergencyEmail",
    "emergencyPhone",
    "coverage",
  ],
  columnNames: [
    "userid",
    "startDate",
    "endDate",
    "totalDays",
    "type",
    "viewWorkflow",
  ],
  workflow: "vacationrequest",
  hideNav: true,
  createPermissions: ["pastor", "admin", "sysadmin"],
  readPermissions: ["[author]", "vacation", "sysadmin"],
  deletePermissions: ["[author]", "vacation", "admin", "sysadmin"],
  writePermissions: ["[author]", "vacation", "admin", "sysadmin"],
  fields: {
    viewWorkflow: {
      label: "Workflow",
      type: "workflow",
    },
    viewDates: {
      label: "Dates",
      type: "calculated",
      fields: ["startDate", "endDate"],
      value: (data) => {
        let strDate = "";
        if (data.startDate && data.endDate) {
          let startYear = parseInt(data.startDate.substr(0, 4));
          let startMonth = parseInt(data.startDate.substr(5, 7));
          let startDay = parseInt(data.startDate.substring(8, 10));
          let endYear = parseInt(data.endDate.substr(0, 4));
          let endMonth = parseInt(data.endDate.substr(5, 7));
          let endDay = parseInt(data.endDate.substring(8, 10));
          strDate =
            startMonth +
            "/" +
            startDay +
            "/" +
            startYear +
            " - " +
            endMonth +
            "/" +
            endDay +
            "/" +
            endYear;
        }
        return <React.Fragment>{data && strDate}</React.Fragment>;
      },
    },
    userid: {
      label: "Name",
      required: true,
      type: "select",
      length: 64,
      selections: userService,
      default: "@UserID",
      readonly: true,
    },
    label1: {
      label: "According to policy I would like to take my",
      isSection: true,
      type: "label",
    },
    type: {
      label: "Type",
      type: "select",
      length: 80,
      selections: "vacation,sabbatical",
      default: "vacation",
      required: true,
    },
    year: {
      label: "for",
      required: true,
      type: "year",
      default: String(new Date().getFullYear()) + "-01-01",
    },
    startDate: {
      label: "Start Date",
      required: true,
      type: "date",
    },
    endDate: {
      label: "End Date",
      required: true,
      type: "date",
    },
    totalDays: {
      label: "Total Days",
      required: true,
      type: "number",
    },
    label2: {
      label: "For emergency I can be contacted through",
      isSection: true,
      type: "label",
    },
    emergencyName: {
      label: "Name",
      type: "text",
      length: 255,
    },
    emergencyAddress: {
      label: "Address",
      type: "text",
      length: 255,
    },
    emergencyEmail: {
      label: "E-Mail",
      type: "text",
      length: 255,
    },
    emergencyPhone: {
      label: "Phone",
      type: "text",
      length: 255,
    },
    coverage: sharedFields.coverage,
  },
};

export let vacationService = new EntryService(vacationDefinition);
