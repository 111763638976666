/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import EntryService from "./entryService";
import Attachments from "../components/common/attachments/Attachments";

export const linkbuttonDefinition = {
  baseUrl: "/settings/linkbuttons",
  viewTitle: "Link Buttons",
  editTitle: "Edit Link Button",
  createTitle: "Create Link Button",
  hideNav: true,
  fieldNames: ["title", "linkTo", "newTab", "image", "position"],
  columnNames: ["position", "title", "viewImage", "viewLinkTo", "newTab"],
  createPermissions: ["admin", "sysadmin"],
  deletePermissions: ["admin", "sysadmin"],
  readPermissions: ["[all]"],
  writePermissions: ["admin", "sysadmin"],
  fields: {
    title: {
      label: "Title",
      type: "text",
      length: 255,
      required: true,
    },
    linkTo: {
      label: "Link to",
      type: "text",
      length: 255,
      required: true,
    },
    newTab: {
      label: "Show in new Browser tab",
      type: "select",
      selections: "yes,no",
      default: "yes",
      length: 255,
      required: true,
    },
    image: {
      label: "Button Image",
      required: false,
      type: "attachments",
      multiple: false,
    },
    viewImage: {
      label: "Image",
      required: false,
      type: "calculated",
      readonly: true,
      fields: ["image"],
      value: (data) => {
        return <img src={Attachments.getFirstHREF(data.image)} height="50" />;
      },
    },
    viewLinkTo: {
      label: "Link",
      required: false,
      type: "calculated",
      readonly: true,
      fields: ["linkTo"],
      value: (data) => {
        if (data.linkTo) {
          return <a href={data.linkTo}>Link</a>;
        } else {
          return "";
        }
      },
    },
    position: {
      label: "View Position",
      type: "number",
      length: 255,
      required: true,
      default: 100,
    },
  },
};

export let linkbuttonService = new EntryService(linkbuttonDefinition);
