/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import EntryService from "./entryService";
import { workflowRuleItemService } from "./workflowRuleItemService";

let customRuleView = (data) => {
  let rules;
  if (data === undefined || data.rules === undefined || data.rules === "[]") {
    rules = [];
  } else {
    rules = data.rules;
  }
  if (typeof rules === "string") {
    rules = JSON.parse(rules);
  }
  let ret = [];
  let row = 0;
  for (let i = 0; i < rules.length; i++) {
    let rule = rules[i];
    if (rule.step || rule.type || rule.user || rule.name || rule.email) {
      ret.push({
        _id: row,
        step: rule.step,
        type: rule.type,
        user: rule.user,
        name: rule.name,
        email: rule.email,
      });
      row++;
    }
  }
  ret.push({ _id: row, step: "", type: "" });

  return ret;
};

export const workflowRuleDefinition = {
  baseUrl: "/settings/workflowrules",
  viewTitle: "Workflow Rules",
  editTitle: "Edit Workflow Rule",
  createTitle: "Create Workflow Rule",
  columnNames: ["workflowkey", "viewRules"],
  fieldNames: ["workflowkey", "rules"],
  createPermissions: ["sysadmin"],
  deletePermissions: ["sysadmin"],
  readPermissions: ["[all]"],
  writePermissions: ["admin", "sysadmin"],
  fields: {
    workflowkey: {
      label: "Key",
      required: true,
      type: "select",
      selections:
        "baptismreport,bibleworkerreport,evangelismfunds,ministrygoals,ministryreport,servicerequest,vacationrequest,travelrequest,eventrequest",
      length: 256,
    },
    rules: {
      label: "Rules",
      type: "rows",
      service: workflowRuleItemService,
      customview: customRuleView,
    },
    viewRules: {
      label: "Rules",
      type: "calculated",
      fields: ["rules"],
      value: (data) => {
        let ret = [];
        let rules = data.rules;
        if (typeof rules === "string") {
          rules = JSON.parse(rules);
        }
        for (let i = 0; i < rules.length; i++) {
          let rule = rules[i];
          ret.push(
            rule.step + ": " + rule.type + " - " + (rule.name || "Manual")
          );
          ret.push(<br />);
        }
        return <React.Fragment>{ret}</React.Fragment>;
      },
    },
  },
};

export let workflowRuleService = new EntryService(workflowRuleDefinition);
