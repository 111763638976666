/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import imageExtensions from "image-extensions";
import isUrl from "is-url";
import { fileService } from "../../../services/fileService";
import { docService } from "../../../services/docService";
import utils from "../../../utils/utils";

export default class Dialog extends Component {
  state = {
    values: [],
    renderBody: undefined,
    buttons: undefined,
  };
  static instance = undefined;

  static getInstance = () => {
    return Dialog.instance;
  };

  closeDialog = () => {
    this.setState({
      title: undefined,
      buttons: undefined,
      renderBody: undefined,
      page: undefined,
      values: {},
    });
  };

  componentDidMount() {
    if (!Dialog.instance) {
      Dialog.instance = this;
    }
  }

  handleChange = ({ currentTarget: input }) => {
    let values = { ...this.state.values };
    values[input.name] = input.value;
    this.setState({ values });
  };

  render() {
    if (!this.state.renderBody) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      padding: 50,
      zIndex: 10,
    };

    // The modal "window"
    const modalStyle = {
      backgroundColor: "#fff",
      borderRadius: 5,
      maxWidth: 500,
      minHeight: 300,
      margin: "0 auto",
      padding: 30,
    };

    return (
      <div style={{ ...backdropStyle, zIndex: 10 }}>
        <div style={modalStyle}>
          {this.state.title && (
            <React.Fragment>
              {this.state.title}
              <hr />
            </React.Fragment>
          )}
          {this.state.renderBody(this.state.values, this.handleChange)}
          <div className="footer">
            {this.state.buttons &&
              this.state.buttons.map((button) => (
                <button
                  className={
                    button.title === "Cancel"
                      ? "btn btn-secondary mr-2 mt-2"
                      : "btn btn-primary mr-2 mt-2"
                  }
                  key={button.id || button.title}
                  onClick={(e) => {
                    e.preventDefault();
                    button.callback(button.id || button.title);
                  }}
                >
                  {button.title}
                </button>
              ))}
          </div>
        </div>
      </div>
    );
  }

  static async insertImageDialog() {
    let me = Dialog.getInstance();
    return new Promise((resolve, reject) => {
      let title = "Insert Image";
      let renderBody = (values, onChange) => {
        if (!values._files) {
          fileService
            .getAllAsync()
            .then((entries) => {
              let files = entries.map((entry) => {
                let attachment = entry.attachment;
                if (typeof attachment === "string") {
                  attachment = JSON.parse(attachment);
                }
                attachment = attachment[0];
                attachment.url =
                  utils.getDbUrl() +
                  "attachments.php?filename=" +
                  encodeURIComponent(attachment.name) +
                  "&_id=" +
                  encodeURIComponent(attachment._id);
                if (attachment.url.startsWith("/")) {
                  attachment.url =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    attachment.url;
                }
                return attachment;
              });
              onChange({
                currentTarget: { name: "_files", value: files },
              });
            })
            .catch((e) => {
              console.log("Exception", e);
            });
        }
        return (
          <React.Fragment>
            <div>Either select an image from the list below:</div>
            <select name="url" value={values.url || ""} onChange={onChange}>
              <option id="" value="">
                --- Select an image ---
              </option>
              {values._files &&
                values._files.map((file) => {
                  return (
                    <option key={file.url} id={file.url} value={file.url}>
                      {file.name}
                    </option>
                  );
                })}
            </select>
            <div>or enter/paste the URL here:</div>
            <input
              name="url"
              type="text"
              value={values.url || ""}
              onChange={onChange}
              style={{ width: "100%" }}
            />
          </React.Fragment>
        );
      };
      let buttons = [
        {
          id: "ok",
          title: "Ok",
          callback: () => {
            let values = { button: "ok", ...me.state.values };
            me.closeDialog();
            console.log("RESOLVE", values.url);
            resolve(values.url);
          },
        },
        {
          id: "cancel",
          title: "Cancel",
          callback: () => {
            me.closeDialog();
            resolve(null);
          },
        },
      ];
      let values = {};

      me.setState({ title, renderBody, buttons, values });
    });
  }

  static async insertLinkDialog() {
    let me = Dialog.getInstance();
    return new Promise((resolve, reject) => {
      let title = "Insert Link";
      let renderBody = (values, onChange) => {
        if (!values._files) {
          fileService
            .getAllAsync()
            .then((entries) => {
              let files = entries
                .map((entry) => {
                  let attachment = entry.attachment;
                  if (typeof attachment === "string") {
                    attachment = JSON.parse(attachment);
                  }
                  attachment = attachment[0];
                  attachment.url =
                    utils.getDbUrl() +
                    "attachments.php?filename=" +
                    encodeURIComponent(attachment.name) +
                    "&_id=" +
                    encodeURIComponent(attachment._id);
                  if (attachment.url.startsWith("/")) {
                    attachment.url =
                      window.location.protocol +
                      "//" +
                      window.location.host +
                      attachment.url;
                  }
                  return attachment;
                })
                .filter((entry) => {
                  let pos = entry.name.lastIndexOf(".");
                  let ext = "";
                  if (pos !== -1) {
                    ext = entry.name.substring(pos + 1);
                    ext = ext.toLowerCase();
                  }

                  return isUrl(entry.url) && imageExtensions.includes(ext);
                });
              onChange({
                currentTarget: { name: "_files", value: files },
              });
            })
            .catch((e) => {
              console.log("Exception", e);
            });

          docService
            .getAllAsync()
            .then((entries) => {
              let docs = entries.map((entry) => {
                return { title: entry.title, url: "/docs/" + entry._id };
              });
              onChange({
                currentTarget: { name: "_docs", value: docs },
              });
            })
            .catch((e) => {
              console.log("Exception", e);
            });
        }
        return (
          <React.Fragment>
            <div>Either select a file from the list below:</div>
            <select name="url" value={values.url || ""} onChange={onChange}>
              <option id="" value="">
                --- Select a file ---
              </option>
              {values._files &&
                values._files.map((file) => {
                  return (
                    <option key={file._id} id={file._id} value={file.url}>
                      {file.name}
                    </option>
                  );
                })}
            </select>
            <div>or select a document from the list below:</div>
            <select name="url" value={values.url || ""} onChange={onChange}>
              <option id="" value="">
                --- Select a document ---
              </option>
              {values._docs &&
                values._docs.map((doc) => {
                  return (
                    <option key={doc.url} id={doc.url} value={doc.url}>
                      {doc.title}
                    </option>
                  );
                })}
            </select>
            <div>or enter/paste the image URL here:</div>
            <input
              name="url"
              type="text"
              value={values.url || ""}
              onChange={onChange}
              style={{ width: "100%" }}
            />
          </React.Fragment>
        );
      };
      let buttons = [
        {
          id: "ok",
          title: "Ok",
          callback: () => {
            let values = { button: "ok", ...me.state.values };
            me.closeDialog();
            console.log("RESOLVE", values.url);
            resolve(values.url);
          },
        },
        {
          id: "cancel",
          title: "Cancel",
          callback: () => {
            me.closeDialog();
            resolve(null);
          },
        },
      ];
      let values = {};

      me.setState({ title, renderBody, buttons, values });
    });
  }
}
