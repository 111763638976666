/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Vacations from "./components/vacations";
import VacationForm from "./components/vacationForm";
import Events from "./components/events";
import EventForm from "./components/eventForm";
import ServiceRequests from "./components/serviceRequests";
import ServiceRequestForm from "./components/serviceRequestForm";
import TravelRequests from "./components/travelRequests";
import TravelRequestForm from "./components/travelRequestForm";
import Users from "./components/users";
import UserForm from "./components/userForm";
import Churches from "./components/churches";
import ChurchForm from "./components/churchForm";
import Activities from "./components/activities";
import ActivityForm from "./components/activityForm";
import Holidays from "./components/holidays";
import HolidayForm from "./components/holidayForm";
import Contacts from "./components/contacts";
import ContactForm from "./components/contactForm";
import MinistryReport from "./components/ministryReport";
import MinistryReportForm from "./components/ministryReportForm";
import MinistryGoals from "./components/ministryGoals";
import MinistryGoalsForm from "./components/ministryGoalsForm";
import EvangelismFundsRequests from "./components/evangelismFundsRequests";
import EvangelismFundsRequestForm from "./components/evangelismFundsRequestForm";
import Groups from "./components/groups";
import GroupForm from "./components/groupForm";
import Docs from "./components/docs";
import DocForm from "./components/docForm";
import DocReadForm from "./components/docReadForm";
import Linkbuttons from "./components/linkbuttons";
import LinkbuttonForm from "./components/linkbuttonForm";
import Files from "./components/files";
import FileForm from "./components/fileForm";
import WorkflowRules from "./components/workflowRules";
import WorkflowRuleForm from "./components/workflowRuleForm";
import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
import LoginForm from "./components/loginForm";
import LogoutForm from "./components/logoutForm";
import HomePage from "./components/homePage";
import WorkflowPage from "./components/workflowPage";
import SettingsPage from "./components/settingsPage";
import ImageUpload from "./components/imageUpload";
import CalendarPage from "./components/calendarPage";
import LinksPage from "./components/linksPage";
import ReportsPage from "./components/reportsPage";
import PastorSummaryPage from "./components/pastorSummaryPage";
import RequestsPage from "./components/requestsPage";
import BaptismReport from "./components/baptismReport";
import BaptismReportForm from "./components/baptismReportForm";
import BibleWorkerReport from "./components/bibleWorkerReport";
import BibleWorkerReportForm from "./components/bibleWorkerReportForm";
import authService from "./services/authService";
import GeneratePhpFiles from "./services/generatePhpFiles";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "./components/common/dialog";
import axios from "axios";
import utils from "./utils/utils";
import draftStorage from "./utils/draftStorage";
import { SourceMapConsumer } from "source-map";

class App extends Component {
  state = { error: undefined, info: undefined, errorLogged: false };

  async componentDidCatch(error, info) {
    // Display fallback UI
    //    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    this.setState({ error, info });

    let href = window.location.href;
    let hrefParts = href && href.split("/");
    let data = undefined;
    if (hrefParts && hrefParts.length > 2) {
      data = draftStorage.getDraft(
        hrefParts[hrefParts.length - 2],
        hrefParts[hrefParts.length - 1]
      );
    }

    let annotated = "";
    try {
      if (error && error.stack) {
        SourceMapConsumer.initialize({
          "lib/mappings.wasm":
            "https://unpkg.com/source-map@0.7.3/lib/mappings.wasm",
        });

        let pos = 0;
        const rawSourceMap = {};
        while (pos >= 0) {
          let pos1 = error.stack.indexOf("(https://", pos);
          if (pos1 === -1) {
            pos1 = error.stack.indexOf("(http://", pos);
          }
          if (pos1 === -1) {
            break;
          }
          pos1++;
          pos = error.stack.indexOf(")", pos1);
          let source = error.stack.substring(pos1, pos);
          let parts = source.split(":");
          let url = "";
          let line = -1;
          let col = -1;
          if (parts.length === 4) {
            url = parts[0] + ":" + parts[1];
            line = parseInt(parts[2]);
            col = parseInt(parts[3]);
          } else if (parts.length === 5) {
            url = parts[0] + ":" + parts[1] + ":" + parts[2];
            line = parseInt(parts[3]);
            col = parseInt(parts[4]);
          }
          if (url !== "") {
            if (rawSourceMap[url] === undefined) {
              rawSourceMap[url] = (await axios.get(url + ".map")).data;
            }

            let ret2 = await SourceMapConsumer.with(
              rawSourceMap[url],
              null,
              (consumer) => {
                return consumer.originalPositionFor({
                  line: line,
                  column: col,
                });
              }
            );
            if (ret2.source) {
              annotated += ret2.source + ":" + ret2.line + ":" + ret2.column;
            }
          } else {
            annotated += source;
          }
          annotated += "\n";
        }
      }
    } catch (e) {
      annotated += "ERROR occurred parsing stack";
    }

    let url = utils.getDbUrl() + "logerror.php";
    axios
      .post(url, {
        error: error && error.toString(),
        stack: error && error.stack,
        stackAnnotated: annotated,
        info: info && info.componentStack && info.componentStack.toString(),
        data: data,
        author: {
          name: authService.getUsername(),
          id: authService.getProfile() && authService.getProfile().token,
        },
        build: utils.getBuild(),
        href: window.location.href,
      })
      .then(
        (msg) => {
          console.log("sent error", msg);
          this.setState({ errorLogged: true });
        },
        (err) => {
          console.log("sent error failed", err);
          this.setState({ errorLogged: false });
        }
      );
  }

  render() {
    if (this.state.error || this.state.info) {
      if (this.state.error) {
        console.error(this.state.error);
      }
      if (this.state.info) {
        console.log(this.state.info);
      }
      return (
        <React.Fragment>
          <h1>Error occurred</h1>
          <hr />
          {this.state.errorLogged
            ? "Error has been logged and will be investigated"
            : ""}
        </React.Fragment>
      );
    }
    let publicUrl = `${process.env.PUBLIC_URL}`;
    const isProduction =
      window.location.href.indexOf("/snecapp.org/") !== -1 ||
      window.location.href.indexOf("/snecapp.com/") !== -1 ||
      window.location.href.indexOf("/www.snecapp.org/") !== -1 ||
      window.location.href.indexOf("/www.snecapp.com/") !== -1;

    return (
      <React.Fragment>
        <ToastContainer />
        <Dialog />
        <NavBar />
        <main
          className={isProduction ? "container" : "container constructionImage"}
        >
          {authService.getUsername() ? (
            <React.Fragment>
              <Switch>
                <Route
                  path={`${process.env.PUBLIC_URL}/home`}
                  component={HomePage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/workflow`}
                  component={WorkflowPage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/logout`}
                  component={LogoutForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/vacations/:id`}
                  component={VacationForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/vacations`}
                  component={Vacations}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/eventrequests/:id`}
                  component={EventForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/eventrequests`}
                  component={Events}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/servicerequests/:id`}
                  component={ServiceRequestForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/servicerequests`}
                  component={ServiceRequests}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/travelrequests/:id`}
                  component={TravelRequestForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/travelrequests`}
                  component={TravelRequests}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/evangelismfunds/:id`}
                  component={EvangelismFundsRequestForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests/evangelismfunds`}
                  component={EvangelismFundsRequests}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/requests`}
                  component={RequestsPage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/baptismreport/:id`}
                  component={BaptismReportForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/baptismreport`}
                  component={BaptismReport}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/bibleworkerreport/:id`}
                  component={BibleWorkerReportForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/bibleworkerreport`}
                  component={BibleWorkerReport}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/ministryreport/:id`}
                  component={MinistryReportForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/ministryreport`}
                  component={MinistryReport}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/ministrygoals/:id`}
                  component={MinistryGoalsForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/ministrygoals`}
                  component={MinistryGoals}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/pastorsummary`}
                  component={PastorSummaryPage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/reports/`}
                  component={ReportsPage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/groups/:id`}
                  component={GroupForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/linkbuttons/:id`}
                  component={LinkbuttonForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/linkbuttons`}
                  component={Linkbuttons}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/docs/:id`}
                  component={DocReadForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/docs/:id`}
                  component={DocForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/docs`}
                  component={Docs}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/groups`}
                  component={Groups}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/workflowrules/:id`}
                  component={WorkflowRuleForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/workflowrules`}
                  component={WorkflowRules}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/files/:id`}
                  component={FileForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/files`}
                  component={Files}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/holidays/:id`}
                  component={HolidayForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/holidays`}
                  component={Holidays}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/churches/:id`}
                  component={ChurchForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/churches`}
                  component={Churches}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/users/:id`}
                  component={UserForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/users`}
                  component={Users}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings`}
                  component={SettingsPage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/activities/:id`}
                  component={ActivityForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/activities`}
                  component={Activities}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/contacts/:id`}
                  component={ContactForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/contacts`}
                  component={Contacts}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/links/calendar`}
                  component={CalendarPage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/links`}
                  component={LinksPage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/not-found`}
                  component={NotFound}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/imageupload`}
                  component={ImageUpload}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/generatephpfiles`}
                  component={GeneratePhpFiles}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/login`}
                  component={LoginForm}
                />
                <Redirect from={publicUrl + "/"} to={publicUrl + "/home"} />
              </Switch>
            </React.Fragment>
          ) : (
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={LoginForm}
              />
              <Redirect
                from={publicUrl + "/"}
                to={
                  publicUrl +
                  "/login?redirect=" +
                  (window.location.pathname.startsWith("/logout")
                    ? "/"
                    : window.location.pathname)
                }
              />
            </Switch>
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default App;
