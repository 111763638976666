/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { serviceRequestService } from "../services/serviceRequestService";
import { eventRequestService } from "../services/eventRequestService";
import { travelRequestService } from "../services/travelRequestService";
import { evangelismFundsService } from "../services/evangelismFundsService";
import Complete from "./common/complete";

import ImageButton from "./common/imagebutton";
import VacationImage from "../resources/vacation.jpg";
import TravelImage from "../resources/travel.png";
import FundsImage from "../resources/funds.png";
import SpeakerImage from "../resources/speakers.jpg";
import CoupleImage from "../resources/couple.png";

export default class RequestsPage extends Component {
  render() {
    let publicURL = `${process.env.PUBLIC_URL}`;
    return (
      <div>
        <h3>SNEC Office Requests</h3>
        <ImageButton
          link={publicURL + "/requests/vacations"}
          addLink="/requests/vacations/new"
          label="Vacation"
          image={VacationImage}
        />
        {travelRequestService.hasReadPermissions() && (
          <ImageButton
            link={publicURL + "/requests/travelrequests"}
            addLink={
              travelRequestService.hasCreatePermissions() &&
              "/requests/travelrequests/new"
            }
            label="Travel Requests"
            image={TravelImage}
          />
        )}
        {evangelismFundsService.hasReadPermissions() && (
          <ImageButton
            link={publicURL + "/requests/evangelismfunds"}
            addLink={
              evangelismFundsService.hasCreatePermissions() &&
              "/requests/evangelismfunds/new"
            }
            label="Evangelism Funds"
            image={FundsImage}
          />
        )}
        {serviceRequestService.hasReadPermissions() && (
          <ImageButton
            link={publicURL + "/requests/servicerequests"}
            addLink={
              serviceRequestService.hasCreatePermissions() &&
              "/requests/servicerequests/new"
            }
            label="Service Requests"
            image={SpeakerImage}
          />
        )}
        {eventRequestService.hasReadPermissions() && (
          <ImageButton
            link={publicURL + "/requests/eventrequests"}
            addLink={
              eventRequestService.hasCreatePermissions() &&
              "/requests/eventrequests/new"
            }
            label="Event (Funeral, Wedding, Conference, Church trip, ..."
            image={CoupleImage}
          />
        )}
        <Complete />
      </div>
    );
  }
}
