/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./common/table";
import dateUtils from "../utils/dateUtils";
import authService from "../services/authService";

export default class EntriesTable extends Component {
  state = {
    selections: {}
  };

  async componentDidMount() {
    const { data } = this.props;
    let selections = {};
    for (let i = 0; i < data.length; i++) {
      let keys = Object.keys(data[i]);
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        let definition = this.props.service.getFieldDefinition(key);
        if (definition && definition.selections !== undefined) {
          if (typeof definition.selections === "string") {
            let values = definition.selections.split(",");
            let dataSelections = [];
            for (let d = 0; d < values.length; d++) {
              dataSelections.push({ id: values[d], name: values[d] });
            }
            selections[key] = dataSelections;
          } else if (
            definition.selections.isService &&
            definition.selections.isService()
          ) {
            selections[key] = await definition.selections.getAllAsyncLookup();
          } else {
            selections[key] = [];
            let groups = authService.getGroups();
            for (let g = 0; g < groups.length; g++) {
              if (definition.selections[groups[g]] !== undefined) {
                let values = definition.selections[groups[g]].split(",");
                for (let v = 0; v < values.length; v++) {
                  let value = values[v].split("|", 2);
                  selections[key].push({
                    _id: value[0],
                    name: value[value.length - 1]
                  });
                }
              }
            }
          }
          if (
            selections[key].length === 0 &&
            definition.selections.default !== undefined
          ) {
            let values = definition.selections.default.split(",");
            for (let v = 0; v < values.length; v++) {
              let value = values[v].split("|", 2);
              selections[key].push({
                _id: value[0],
                name: value[value.length - 1]
              });
            }
          }
        }
      }
    }
    this.setState({ selections });
  }

  render() {
    let service = this.props.service;
    let columns = [];
    let columnNames = service.getColumnNames();
    let firstColumn = true;
    for (let i = 0; i < columnNames.length; i++) {
      let column = {
        path: columnNames[i],
        label: service.getFieldLabel(columnNames[i]),
        display: service.getFieldDisplay(columnNames[i])
      };
      let fieldDefinition = service.getFieldDefinition(columnNames[i]);
      if (
        fieldDefinition.type === undefined ||
        fieldDefinition.type !== "label"
      ) {
        if (firstColumn === true) {
          firstColumn = false;
          column.content = entry => {
            return (
              <Link
                to={
                  service.getBaseUrl() +
                  "/" +
                  entry._id +
                  (entry._id === "new" && entry.date !== undefined
                    ? "?date=" + dateUtils.MDY_to_SQL(entry.date)
                    : "")
                }
              >
                {entry[columnNames[i]] || "[Edit]"}
              </Link>
            );
          };
        }
        columns.push(column);
      }
    }

    const { data, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={columns}
        data={data}
        sortColumn={sortColumn}
        onSort={onSort}
        totalsColumns={this.props.totalsColumns}
        service={this.props.service}
      />
    );
  }
}
