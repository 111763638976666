/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Complete from "./common/complete";
import Table from "./common/table";
import { Link } from "react-router-dom";
import { userService } from "../services/userService";
import { ministryGoalsService } from "../services/ministryGoalsService";
import { ministryReportService } from "../services/ministryReportService";
import Workflow from "./common/workflow";

let service = {
  getFieldDefinition: name => {
    return { type: "text", label: name, length: 80, readonly: true };
  },
  getFieldNames: () => {
    return ["pastor", "def"];
  },
  hasWritePermissions: () => {
    return false;
  }
};

export default class PastorSummaryPage extends Component {
  state = {
    data: [],
    columns: []
  };

  async componentDidMount() {
    let publicURL = `${process.env.PUBLIC_URL}`;

    let users = await userService.getAllAsyncLookup();
    let ministryReports = await ministryReportService.getAllAsyncLookup();
    let ministryGoals = await ministryGoalsService.getAllAsyncLookup();
    let pastors = [];
    users.forEach(user => {
      let foundPastor = false;
      user.groups.forEach(group => {
        if (group && group.name === "Pastor") {
          foundPastor = true;
        }
      });
      if (foundPastor) {
        pastors.push(user);
      }
    });
    pastors.sort((p1, p2) => (p1.name > p2.name ? 1 : -1));

    let years = {};
    ministryGoals.forEach(goal => {
      years[goal.year.substring(0, 4)] = "";
    });
    years = Object.keys(years)
      .sort()
      .slice(-3);

    let months = {};
    ministryReports.forEach(report => {
      months[report.date.substring(0, 7)] = "";
    });
    months = Object.keys(months)
      .sort()
      .slice(-3);

    let columns = [];
    columns.push({ label: "Pastor", path: "pastor" });
    years.forEach(year => {
      columns.push({ label: "Goals " + year, path: "goals" + year });
    });
    months.forEach(month => {
      columns.push({ label: "Report " + month, path: "report" + month });
    });

    let data = [];
    pastors.forEach(pastor => {
      let d = { pastor: pastor.name };
      years.forEach(year => {
        ministryGoals.forEach(goal => {
          if (
            goal.year.substring(0, 4) === year &&
            goal.userid[0]._id === pastor._id
          ) {
            d["goals" + year] = (
              <Link to={publicURL + "/reports/ministrygoals/" + goal._id}>
                {Workflow.getOverallStatus(goal._workflow)}
              </Link>
            );
          }
        });
      });
      months.forEach(month => {
        ministryReports.forEach(report => {
          if (
            report.date.substring(0, 7) === month &&
            report.userid[0]._id === pastor._id
          ) {
            d["report" + month] = (
              <Link to={publicURL + "/reports/ministryreport/" + report._id}>
                {Workflow.getOverallStatus(report._workflow)}
              </Link>
            );
          }
        });
      });
      data.push(d);
    });
    console.log(years, pastors, ministryReports, ministryGoals);
    this.setState({ data, columns });
  }

  render() {
    return (
      <div>
        <h3>SNEC Office Reports</h3>
        <Table
          columns={this.state.columns}
          onSort={() => {}}
          data={this.state.data}
          onChange={() => {}}
          service={service}
          fullData={this.state.data}
        />
        <Complete />
      </div>
    );
  }
}
