/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { activityService } from "../services/activityService";
import Entries from "./entries";

export default class Activities extends Component {
  render() {
    return <Entries service={activityService} />;
  }
}
