/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import { serviceRequestService } from "../services/serviceRequestService";
import EntryForm from "./entryForm";

export default class ServiceRequestForm extends Form {
  render() {
    return (
      <React.Fragment>
        <EntryForm
          service={serviceRequestService}
          match={this.props.match}
          history={this.props.history}
        />
        <a
          href={
            "/db/docx.php?_id=" +
            this.props.match.params.id +
            "&_docx=ServiceRequest.docx"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Download as Word Document
        </a>
      </React.Fragment>
    );
  }
}
