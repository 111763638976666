/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import Form from "./common/form";
import authService from "../services/authService";

export default class LogoutForm extends Form {
  render() {
    authService.logout();
    window.location.reload(true);
    window.location = `${process.env.PUBLIC_URL}/`;
    return null;
  }
}
