/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { vacationService } from "../services/vacationService";
import ListGroup from "./common/listGroup";
import Entries from "./entries";

export default class Vacations extends Component {
  state = {
    groups: [],
    selectedGroup: null
  };

  componentDidMount() {
    const groups = [
      { _id: "", name: "All Requests" },
      {
        _id: "all_approved",
        name: "All Approved"
      },
      {
        _id: "pending",
        name: "Pending Approvals"
      },
      {
        _id: "active",
        name: "Active"
      }
    ];

    this.setState({ groups });
  }

  render() {
    return (
      <div className="row">
        {false && (
          <div className="col-3">
            <ListGroup
              items={this.state.groups}
              selectedItem={this.state.selectdGroups}
              onItemSelect={this.handleGroupSelect}
            />
          </div>
        )}
        <div className="col">
          <Entries service={vacationService} />
        </div>
      </div>
    );
  }
}
