/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import { evangelismFundsService } from "../services/evangelismFundsService";
import EntryForm from "./entryForm";

export default class EvangelismFundsForm extends Form {
  render() {
    return (
      <EntryForm
        service={evangelismFundsService}
        match={this.props.match}
        history={this.props.history}
      />
    );
  }
}
