/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Validator from "../../utils/Validator";
import Workflow from "./workflow";
import { toast } from "react-toastify";
import Dialog from "./dialog";
import draftStorage from "../../utils/draftStorage";

export default class Form extends Component {
  hasErrors(errors) {
    if (JSON.stringify(errors) === "{}") {
      return false;
    } else {
      return true;
    }
  }

  validate = () => {
    let errors = Validator.validate(this.state.data, this.props.service);
    return errors;
  };

  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate();

    if (this.props.service) {
      draftStorage.deleteDraft(
        this.props.service.getForm(),
        this.state.data._id
      );
    }

    this.doSubmit(errors);
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    if (data[input.name] !== input.value) {
      data[input.name] = input.value;

      this.setData(data);

      if (this.props.service) {
        draftStorage.updateDraft(this.props.service.getForm(), data._id, data);
      }
    }
  };

  renderSubmitButton(label) {
    return (
      <button
        onClick={e => {
          let errors = this.validate();
          if (this.hasErrors(errors)) {
            e.preventDefault();
            let msg = "";
            Object.keys(errors).forEach(error => {
              if (msg.length > 0) {
                msg += "\n";
              }
              msg += errors[error];
            });
            Dialog.showMessage(
              "Field validation failed",
              msg.split("\n").map((m, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {m}
                </React.Fragment>
              ))
            );
            this.setState({ globalValidate: true });
          }
        }}
        className="btn btn-primary btn-space d-print-none"
      >
        {label}
      </button>
    );
  }

  renderDeleteButton(service, id) {
    return (
      <button
        onClick={async e => {
          e.preventDefault();
          Dialog.showYesNo(
            "Delete",
            "Are you sure you want to delete?",
            async label => {
              if (label === "yes") {
                let success = await service.deleteAsync(id);
                if (success) {
                  toast.success("Delete successful");
                  this.props.history.goBack();
                } else {
                  toast.error("Delete failed");
                }
              }
            }
          );
        }}
        className="btn btn-danger btn-sm sm-right md-right lg-right xl-right d-print-none"
      >
        Delete
      </button>
    );
  }

  renderWorkflowButtons(setWorkflowFields) {
    return (
      <React.Fragment>
        <button
          onClick={() => {
            setWorkflowFields({ index: 0, action: "submit" });
          }}
          className="btn btn-primary btn-space d-print-none"
        >
          Submit
        </button>
        &nbsp;
        <button
          onClick={() => {
            setWorkflowFields({ index: 0, action: "draft" });
          }}
          className="btn btn-secondary btn-space d-print-none"
        >
          Save as Draft
        </button>
      </React.Fragment>
    );
  }

  renderCancelButton() {
    return (
      <button
        onClick={e => {
          e.preventDefault();
          if (this.props.service) {
            draftStorage.deleteDraft(
              this.props.service.getForm(),
              this.state.data._id
            );
          }
          if (this.props.history) {
            this.props.history.goBack(e);
          }
          if (this.props.callback) {
            this.props.callback(undefined);
          }
        }}
        className="btn btn-secondary btn-space d-print-none"
      >
        Cancel
      </button>
    );
  }

  isReadOnly = name => {
    let data = this.state.data;
    if (
      data._workflow &&
      data._worklow[0] &&
      data._workflow[0].status !== "Draft"
    ) {
      return true;
    }
    if (this.props.service === undefined) {
      return false;
    }
    let definition = this.props.service.getFieldDefinition(name);
    if (definition && definition.readonly === true) {
      return true;
    }

    return !this.props.service.hasWritePermissions(name, data && data._author);
  };

  renderWorkflow() {
    const { data } = this.state;
    return (
      <Workflow
        value={data._workflow}
        setWorkflowFields={this.setWorkflowFields}
      />
    );
  }
}
