/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const docDefinition = {
  baseUrl: "/settings/docs",
  viewTitle: "Docs",
  editTitle: "Edit Doc",
  createTitle: "Create Doc",
  hideNav: true,
  fieldNames: ["title", "position", "body"],
  columnNames: ["title", "position"],
  createPermissions: ["admin", "sysadmin"],
  deletePermissions: ["admin", "sysadmin"],
  readPermissions: ["[all]"],
  writePermissions: ["admin", "sysadmin"],
  fields: {
    title: {
      label: "Name",
      type: "text",
      length: 255,
      required: true,
    },
    position: {
      label: "View Position",
      type: "number",
      length: 255,
      required: true,
      default: 100,
    },
    body: {
      label: "Body",
      type: "richtext",
      length: 255,
      required: true,
    },
  },
};

export let docService = new EntryService(docDefinition);
