/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

export default class CacheService {
  cache = {};
  cacheid = {};

  async getAllAsync(service) {
    let sqlTable = service.getSqlTable();
    let cacheEntry = this.cache[sqlTable];
    if (cacheEntry === undefined) {
      cacheEntry = service.getAllAsync();
      this.cache[sqlTable] = cacheEntry;
    }
    return cacheEntry;
  }

  async getAllAsyncLookup(service) {
    let sqlTable = service.getSqlTable();
    let cacheEntry = this.cache[sqlTable];
    if (cacheEntry === undefined) {
      cacheEntry = service.getAllAsyncLookup();
      this.cache[sqlTable] = cacheEntry;
    }
    return cacheEntry;
  }

  async getAsync(service, id) {
    let sqlTable = service.getSqlTable();
    let cacheEntry = this.cacheid[sqlTable + " " + id];
    if (cacheEntry === undefined) {
      cacheEntry = service.getAsync(id);
      this.cacheid[sqlTable + " " + id] = cacheEntry;
    }
    return cacheEntry;
  }

  async saveAsync(service, entry) {
    let success = service.saveAsync(entry);
    if (success) {
      delete this.cache[service];
      Object.keys(this.cacheid).forEach(key => {
        if (key.startsWith(service.getSqlTable() + " ")) {
          delete this.cacheid[key];
        }
      });
    }
    return success;
  }

  async deleteAsync(service, id) {
    let success = service.deleteAsync(id);
    if (success) {
      delete this.cache[service];
      Object.keys(this.cacheid).forEach(key => {
        if (key.startsWith(service.getSqlTable() + " ")) {
          delete this.cacheid[key];
        }
      });
    }
    return success;
  }
}
