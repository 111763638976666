/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Dialog from "./common/dialog";
import Entries from "./entries";
import dateUtils from "../utils/dateUtils";
import { ministryReportService } from "../services/ministryReportService";
import authService from "../services/authService";
import draftStorage from "../utils/draftStorage";

export default class MinistryReport extends Component {
  static async showCreateDialog(history) {
    let data = draftStorage.getDraft(ministryReportService.getForm(), "new");
    if (data) {
      //draft already exists - skip combo box dialog
      history.push("/reports/ministryreport/new");
    } else {
      let monthOptionsAll = dateUtils.getMonthOptions(-120, 0);
      let data = await ministryReportService.getAllAsync();
      let monthOptions = [];
      monthOptionsAll.forEach(entry => {
        let entry2 = { ...entry };
        for (let d = 0; d < data.length; d++) {
          if (
            entry._id === data[d].date &&
            data[d].userid === authService.getProfile().token
          ) {
            entry2.name = entry2.name + " (Open existing)";
            entry2._id = data[d]._id;
          }
        }
        monthOptions.push(entry2);
      });

      Dialog.showComboOkCancel(
        "Create + populate new Report",
        "Select month of new report to generate:",
        monthOptions,
        monthOptions && monthOptions[monthOptions.length - 2]._id,
        async month => {
          if (month !== undefined) {
            if (month.length === 64) {
              history.push("/reports/ministryreport/" + month);
            } else {
              history.push("/reports/ministryreport/new?date=" + month);
            }
          }
        }
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-primary btn-space"
          onClick={async e => {
            e.preventDefault();
            MinistryReport.showCreateDialog(this.props.history);
          }}
        >
          Create + populate new Report
        </button>

        <Entries service={ministryReportService} />
      </React.Fragment>
    );
  }
}
