/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { userService } from "./userService";

export const serviceRequestDefinition = {
  baseUrl: "/requests/servicerequests",
  viewTitle: "Service Requests",
  editTitle: "Edit Service Request",
  createTitle: "Create Service Request",
  hideNav: true,
  columnNames: [
    "requestingPastor",
    "date",
    "requestedName",
    "requestedAddress",
    "requestedEmail",
    "requestingOrganization",
    "requestedPersonInstitution",
    "datesRequested",
    "purpose",
    "expensesCoveredBy",
    "viewWorkflow"
  ],
  fieldNames: [
    "date",
    "requestingPastor",
    "labelPersonRequested",
    "requestedName",
    "requestedAddress",
    "requestedEmail",
    "requestingOrganization",
    "requestedPersonInstitution",
    "datesRequested",
    "purpose",
    "expensesCoveredBy",
    "labelNote"
  ],
  workflow: "servicerequest",
  createPermissions: ["pastor", "sysadmin"],
  deletePermissions: ["[author]", "servicerequest", "sysadmin"],
  readPermissions: ["[author]", "servicerequest", "sysadmin"],
  writePermissions: ["[author]", "servicerequest", "sysadmin"],
  fields: {
    viewWorkflow: {
      label: "Workflow",
      type: "workflow"
    },
    date: {
      label: "Date",
      required: true,
      type: "date",
      default: "@Today",
      readonly: true
    },
    requestingPastor: {
      label: "Requesting Pastor",
      required: true,
      type: "select",
      length: 80,
      selections: userService,
      default: "@UserID",
      readonly: true
    },
    labelPersonRequested: {
      label: "Person being requested",
      type: "label"
    },
    requestedName: {
      label: "Name",
      type: "text",
      length: 80,
      required: true
    },
    requestedAddress: {
      label: "Address",
      type: "text",
      length: 120,
      required: false
    },
    requestedEmail: {
      label: "E-Mail",
      type: "text",
      length: 80,
      required: true
    },
    requestingOrganization: {
      label: "Requesting Organization",
      type: "text",
      length: 80,
      description:
        "Southern New England,Atlantic Union,North American Division",
      required: true
    },
    requestedPersonInstitution: {
      label: "Institution Person Affiliated",
      type: "text",
      length: 80,
      description: "Conference,Union,Division",
      required: true
    },
    datesRequested: {
      label: "Date(s) requested",
      type: "text",
      length: 80,
      required: true
    },
    purpose: {
      label: "Purpose",
      type: "text",
      length: 160,
      required: true
    },
    expensesCoveredBy: {
      label: "Expenses covered by",
      type: "text",
      length: 80,
      required: true
    },
    labelNote: {
      label:
        "For requests within NAD allow one month for processing and outside NAD three months.",
      type: "label"
    }
  }
};

export let serviceRequestService = new EntryService(serviceRequestDefinition);
