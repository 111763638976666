/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import authService from "../services/authService";
import { groupService } from "../services/groupService";
import { workflowRuleService } from "../services/workflowRuleService";
import { holidayService } from "../services/holidayService";
import { churchService } from "../services/churchService";
import { userService } from "../services/userService";
import { docService } from "../services/docService";
import { linkbuttonService } from "../services/linkbuttonService";
import { fileService } from "../services/fileService";
import ImageButton from "./common/imagebutton";
import ProfileImage from "../resources/profile.png";
import WorkflowRulesImage from "../resources/workflowRules.jpg";
import GroupsImage from "../resources/groups.png";
import HolidaysImage from "../resources/holidays.png";
import ChurchImage from "../resources/church.png";
import UsersImage from "../resources/users.png";
import DocImage from "../resources/doc.png";
import ButtonImage from "../resources/button.png";
import FilesImage from "../resources/files.png";
import Complete from "./common/complete";

export default class SettingsPage extends Component {
  render() {
    let publicURL = `${process.env.PUBLIC_URL}`;
    return (
      <div>
        <h3>Settings</h3>
        <ImageButton
          label="Profile"
          link={"/settings/users/" + authService.getProfile().token}
          image={ProfileImage}
        />
        {groupService.hasWritePermissions() && (
          <ImageButton
            label="Groups"
            link={publicURL + "/settings/groups"}
            image={GroupsImage}
          />
        )}
        {workflowRuleService.hasWritePermissions() && (
          <ImageButton
            label="Workflow Rules"
            link={publicURL + "/settings/workflowrules"}
            image={WorkflowRulesImage}
          />
        )}
        {holidayService.hasWritePermissions() && (
          <ImageButton
            label="Holidays"
            link={publicURL + "/settings/holidays"}
            image={HolidaysImage}
          />
        )}
        {churchService.hasWritePermissions() && (
          <ImageButton
            label="Churches"
            link={publicURL + "/settings/churches"}
            image={ChurchImage}
          />
        )}
        {userService.hasWritePermissions() && (
          <ImageButton
            label="Users"
            link={publicURL + "/settings/users"}
            image={UsersImage}
          />
        )}
        <h3>Content</h3>
        {docService.hasWritePermissions() && (
          <ImageButton
            label="Docs"
            link={publicURL + "/settings/docs"}
            image={DocImage}
          />
        )}
        {linkbuttonService.hasWritePermissions() && (
          <ImageButton
            label="Link Buttons"
            link={publicURL + "/settings/linkbuttons"}
            image={ButtonImage}
          />
        )}
        {fileService.hasWritePermissions() && (
          <ImageButton
            label="Files"
            link={publicURL + "/settings/files"}
            image={FilesImage}
          />
        )}
        <Complete />
      </div>
    );
  }
}
