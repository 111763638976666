/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import { Link } from "react-router-dom";
import EntryService from "./entryService";
import { churchService } from "./churchService";

export const contactDefinition = {
  baseUrl: "/contacts",
  viewTitle: "Contacts",
  editTitle: "Edit Contact",
  createTitle: "Create Contact",
  columnNames: ["name", "activityLink", "viewAddress", "email", "viewPhone"],
  fieldNames: [
    "firstName",
    "middleName",
    "lastName",
    "address",
    "city",
    "state",
    "zip",
    "email",
    "homePhone",
    "cellPhone",
    "dob",
    "maritalStatus",
    "churchMembership",
    "occupation",
  ],
  createPermissions: ["pastor", "bible worker", "sysadmin"],
  deletePermissions: ["[author]", "sysadmin"],
  readPermissions: ["[author]", "sysadmin"],
  writePermissions: ["[author]", "sysadmin"],
  fields: {
    name: {
      label: "Name",
      type: "calculated",
      fields: ["middleName", "firstName", "lastName"],
      value: (data) => {
        if (data.middleName) {
          return (
            data.firstName +
            " " +
            data.middleName.substring(0, 1) +
            " " +
            data.lastName
          );
        } else {
          return data.firstName + " " + data.lastName;
        }
      },
    },
    activityLink: {
      label: "",
      type: "calculated",
      fields: ["firstName", "lastName"],
      value: (data) => {
        return (
          <Link
            to={
              `${process.env.PUBLIC_URL}` +
              "/activities?q=" +
              encodeURI('"' + data.firstName + " " + data.lastName + '"')
            }
          >
            Activities
          </Link>
        );
      },
    },
    viewAddress: {
      label: "Address",
      type: "calculated",
      fields: ["address", "city", "state", "zip"],
      value: (data) => {
        return (
          <React.Fragment>
            {data.address}
            <br />
            {data.city}, {data.state} {data.zip}
          </React.Fragment>
        );
      },
      display: "lg xl",
    },
    viewPhone: {
      label: "Phone",
      type: "calculated",
      fields: ["homePhone", "cellPhone"],
      value: (data) => {
        return (
          <React.Fragment>
            {data.homePhone && "H: " + data.homePhone}
            {data.homePhone && data.cellPhone && <br />}
            {data.cellPhone && "C: " + data.cellPhone}
          </React.Fragment>
        );
      },
    },
    firstName: {
      label: "First Name",
      type: "text",
      length: 80,
      required: true,
    },
    middleName: {
      label: "Middle Name",
      type: "text",
      length: 80,
      required: false,
    },
    lastName: {
      label: "Last Name",
      type: "text",
      length: 80,
      required: true,
    },
    address: {
      label: "Address",
      type: "text",
      length: 80,
      required: true,
    },
    city: {
      label: "City",
      type: "text",
      length: 80,
      required: true,
    },
    state: {
      label: "State",
      type: "text",
      length: 2,
      required: true,
    },
    zip: {
      label: "Zip",
      type: "text",
      length: 20,
      required: true,
    },
    email: {
      label: "E-Mail",
      type: "text",
      length: 80,
      required: false,
      display: "md lg xl",
    },
    homePhone: {
      label: "Home Phone",
      type: "text",
      length: 80,
      required: false,
    },
    cellPhone: {
      label: "Cell Phone",
      type: "text",
      length: 80,
      required: false,
    },
    dob: {
      label: "Date of Birth",
      type: "date",
      required: false,
    },
    maritalStatus: {
      label: "Marrital Status",
      type: "select",
      length: 80,
      required: false,
      selections: "single,married,separated,divorced,widowed",
    },
    churchMembership: {
      label: "Church Membership",
      type: "select_other",
      length: 80,
      required: false,
      selections: churchService,
    },
    occupation: {
      label: "Occupation",
      type: "text",
      length: 80,
      required: false,
    },
  },
};

export let contactService = new EntryService(contactDefinition);
