/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { eventRequestService } from "../services/eventRequestService";
import Entries from "./entries";

export default class Events extends Component {
  render() {
    return (
      <div className="row">
        <div className="col">
          <Entries service={eventRequestService} />
        </div>
      </div>
    );
  }
}
