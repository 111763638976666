/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const groupDefinition = {
  baseUrl: "/settings/groups",
  viewTitle: "Groups",
  editTitle: "Edit Group",
  createTitle: "Create Group",
  hideNav: true,
  fieldNames: ["name"],
  createPermissions: ["admin", "sysadmin"],
  deletePermissions: ["admin", "sysadmin"],
  readPermissions: ["[all]"],
  writePermissions: ["admin", "sysadmin"],
  fields: {
    name: {
      label: "Name",
      type: "text",
      length: 255,
      required: true
    }
  }
};

export let groupService = new EntryService(groupDefinition);
