/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const ministryReportItemDefinition = {
  baseUrl: "/reports/ministryreport",
  viewTitle: "Ministry Report Entries",
  editTitle: "Edit Ministry Report Entry",
  createTitle: "New Ministry Report Entry",
  columnNames: [
    "date",
    "type",
    "description",
    "districtMiles",
    "specialMiles",
    "perDiem",
    "tolls",
    "misc"
  ],
  fieldNames: [
    "date",
    "type",
    "description",
    "districtMiles",
    "specialMiles",
    "perDiem",
    "tolls",
    "misc"
  ],
  totalsColumns: ["districtMiles", "specialMiles", "perDiem", "tolls", "misc"],
  readPermissions: ["[author]", "sysadmin"],
  writePermissions: ["[author]", "sysadmin"],
  fields: {
    date: {
      label: "Date",
      required: true,
      type: "date",
      default: "@Today",
      readonly: true
    },
    type: {
      label: "Select Type",
      required: true,
      type: "select",
      selections: "Work,Day Off,Sick,Vacation,Holiday"
    },
    label1: {
      label: "In District:",
      type: "label"
    },
    description: {
      label: "Description: Activity or Location",
      required: true,
      type: "text"
    },
    districtMiles: {
      label: "District Miles",
      required: false,
      type: "miles"
    },
    label2: {
      label: "Out of District (approved):",
      type: "label"
    },
    specialMiles: {
      label: "Special Miles",
      required: false,
      type: "miles"
    },
    perDiem: {
      label: "Per Diem",
      type: "currency"
    },
    tolls: {
      label: "Tolls",
      type: "currency"
    },
    misc: {
      label: "Misc",
      type: "currency"
    }
  }
};

export let ministryReportItemService = new EntryService(
  ministryReportItemDefinition
);
