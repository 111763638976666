/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { userService } from "./userService";

export const workflowRuleItemDefinition = {
  baseUrl: "/reports/workflowrules",
  viewTitle: "Workflow Rules",
  editTitle: "Edit Workflow Rules",
  createTitle: "New Workflow Rules",
  columnNames: ["step", "type", "user", "name", "email"],
  fieldNames: ["step", "type", "user", "name", "email"],
  readPermissions: ["[author]", "sysadmin"],
  writePermissions: ["[author]", "sysadmin"],
  fields: {
    step: {
      label: "Step",
      required: false,
      type: "select",
      selections: "1,2,3,4,5,6,7,8,9,10",
      readonly: false,
    },
    type: {
      label: "Select Type",
      required: false,
      type: "select",
      selections: "notify,approve,accept,approve_one,received",
    },
    user: {
      label: "User",
      required: false,
      type: "select",
      selections: userService,
      readonly: false,
    },
    name: {
      label: "Name",
      required: false,
      type: "text",
      readonly: false,
    },
    email: {
      label: "e-mail",
      required: false,
      type: "email",
      readonly: false,
    },
  },
};

export let workflowRuleItemService = new EntryService(
  workflowRuleItemDefinition
);
