/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import { userService } from "../services/userService";
import { groupService } from "../services/groupService";
import EntryForm from "./entryForm";
import allDefinitions from "../services/allDefinitions";

export default class UserForm extends Form {
  state = { groups: {} };

  async componentDidMount() {
    let groupList = await groupService.getAllAsyncLookup();
    let groups = {};
    groupList.forEach((group) => {
      groups[group.name.toLowerCase()] = group._id;
    });
    this.setState({ groups });
  }

  checkAccess(groupnames, data) {
    let groups = "";
    if (data && data.groups) {
      groups = "," + data.groups.toLowerCase() + ",";
    }

    let access = "no";
    groupnames.forEach((groupname) => {
      if (groupname === "[author]") {
        if (access === "no") {
          access = "authored docs only";
        }
      } else if (groupname === "[all]") {
        access = "yes";
      } else if (
        groups.indexOf(this.state.groups[groupname.toLowerCase()]) !== -1
      ) {
        access = "yes";
      }
    });
    return access;
  }

  renderAccess(data) {
    return (
      <React.Fragment>
        <h3>Permissions by Form</h3>
        Recent changes will be active within 5 minutes or if user logs out and
        back in.
        <table border="1">
          <thead>
            <tr>
              <th>Form</th>
              <th>Create</th>
              <th>Read</th>
              <th>Write</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {allDefinitions.map((definition) => {
              return (
                <tr key={definition.viewTitle}>
                  <td>{definition.viewTitle}</td>
                  <td>
                    {this.checkAccess(definition.createPermissions, data)}
                  </td>
                  <td>{this.checkAccess(definition.readPermissions, data)}</td>
                  <td>{this.checkAccess(definition.writePermissions, data)}</td>
                  <td>
                    {this.checkAccess(definition.deletePermissions, data)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <EntryForm
          service={userService}
          match={this.props.match}
          history={this.props.history}
          insertFooter={(data) => this.renderAccess(data)}
        />
      </React.Fragment>
    );
  }
}
