/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import EntryService from "./entryService";

export const docReadDefinition = {
  baseUrl: "/settings/docs",
  viewTitle: "Docs",
  editTitle: "",
  createTitle: "",
  hideNav: true,
  fieldNames: ["title", "body"],
  columnNames: ["title"],
  createPermissions: ["nobody"],
  deletePermissions: ["nobody"],
  readPermissions: ["[all]"],
  writePermissions: ["nobody"],
  showChangeLog: false,
  fields: {
    title: {
      label: "",
      type: "calculated",
      length: 255,
      required: false,
      fields: ["title"],
      value: (data) => (
        <React.Fragment>
          <h1>{data.title}</h1>
          <hr />
        </React.Fragment>
      ),
    },
    body: {
      label: "Body",
      type: "richtext",
      length: 255,
      required: true,
    },
  },
};

export let docReadService = new EntryService(docReadDefinition);
