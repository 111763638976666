/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import historyService from "../services/historyService";
import { userService } from "../services/userService";
import { groupService } from "../services/groupService";
import { churchService } from "../services/churchService";

export default class ChangeLog extends Component {
  state = {
    lookup: [],
    history: undefined,
    show: false,
  };

  async loadData() {
    if (this.state.history === undefined) {
      let [u, g, c, history] = await Promise.all([
        userService.getAllAsync(),
        groupService.getAllAsync(),
        churchService.getAllAsync(),
        historyService.getHistory(this.props.id),
      ]);
      let lookup = {};
      u.forEach((user) => {
        lookup[user._id] = user.name;
      });
      g.forEach((group) => {
        lookup[group._id] = group.name;
      });
      c.forEach((church) => {
        lookup[church._id] = church.name;
      });
      this.setState({ lookup, history });
    }
  }

  render() {
    if (
      this.props.id === undefined ||
      this.props.id === "" ||
      this.props.id === "new"
    ) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="d-print-none">
          <hr />
          <br />
          <br />
          <button
            onClick={async () => {
              await this.loadData();
              this.setState({ show: !this.state.show });
            }}
          >
            {this.state.show ? "Hide Change Log" : "Show Change Log"}
          </button>
          <br />
          <br />
          <br />
        </div>
        {this.state.show && (
          <table border="1">
            <tbody>
              <tr>
                <th>User</th>
                <th>Fields</th>
                <th>Value</th>
              </tr>
              {this.state.history &&
                this.state.history.map((row, rowIndex) => {
                  if (!row.fields) {
                    return null;
                  }

                  let ret = [];
                  let fields = [];
                  row.fields.forEach((field) => {
                    if (
                      field.name !== "_modified" &&
                      field.name !== "_id" &&
                      field.name !== "_created" &&
                      field.name !== "_author" &&
                      field.name !== "_deleted"
                    ) {
                      if (field.name !== "content") {
                        fields.push(field);
                      } else {
                        let value = field.value;
                        if (typeof value === "string") {
                          value = JSON.parse(value);
                        }
                        Object.keys(value).forEach((key) => {
                          fields.push({ name: key, value: value[key] });
                        });
                      }
                    }
                  });
                  fields.forEach((field) => {
                    let isEqual = false;
                    if (rowIndex > 0) {
                      let priorFields = this.state.history[rowIndex - 1].fields;
                      priorFields.forEach((priorField) => {
                        let pv = priorField.value;
                        let fv = field.value;
                        try {
                          pv = JSON.stringify(JSON.parse(pv));
                          fv = JSON.stringify(JSON.parse(fv));
                        } catch (e) {}
                        if (priorField.name === field.name && pv === fv) {
                          isEqual = true;
                        }
                      });
                    }
                    if (!isEqual) {
                      let value = field.value;
                      if (field.name === "password") {
                        value = "********";
                      }
                      if (value && value.startsWith("[")) {
                        try {
                          let j = JSON.parse(field.value);
                          let keys = {};
                          if (field.name !== "_workflow") {
                            j.forEach((jRow) => {
                              Object.keys(jRow).forEach((k) => {
                                keys[k] = k;
                              });
                            });
                            keys = Object.keys(keys);
                          } else {
                            keys = ["_id", "name", "status", "comment"];
                          }
                          value = (
                            <table border="1">
                              <thead>
                                <tr>
                                  {keys.map((k) => (
                                    <th key={k}>{k}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {j.map((jRow, index) => (
                                  <tr key={index}>
                                    {keys.map((key) => {
                                      let jValue = jRow[key];
                                      if (
                                        field.name === "_workflow" &&
                                        key === "name" &&
                                        !jValue &&
                                        jRow["user"]
                                      ) {
                                        jValue = jRow["user"];
                                      }
                                      return (
                                        <td key={key}>
                                          {this.state.lookup[jValue] || jValue}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          );
                        } catch (e) {}
                      } else {
                        value = value.split(",").map((v, index) => {
                          return (
                            (index > 0 ? "," : "") + (this.state.lookup[v] || v)
                          );
                        });
                      }
                      ret.push(
                        <React.Fragment>
                          <td>{field.name}</td>
                          <td>{value}</td>
                        </React.Fragment>
                      );
                    }
                  });

                  return (
                    <React.Fragment key={rowIndex}>
                      <tr>
                        <td rowSpan={ret.length || 1}>
                          <b>{this.state.lookup[row.user] || row.user}</b>
                          <br />
                          {row.timestamp}
                        </td>
                        {ret[0]}
                      </tr>
                      {ret.map((r, index) =>
                        index > 0 ? <tr key={index}>{r}</tr> : null
                      )}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}
