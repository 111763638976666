/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { bibleWorkerReportService } from "../services/bibleWorkerReportService";
import { evangelismFundsService } from "../services/evangelismFundsService";
import { eventRequestService } from "../services/eventRequestService";
import { ministryGoalsService } from "../services/ministryGoalsService";
import { ministryReportService } from "../services/ministryReportService";
import { travelRequestService } from "../services/travelRequestService";
import { serviceRequestService } from "../services/serviceRequestService";
import { vacationService } from "../services/vacationService";
import authService from "../services/authService";
import EntriesTable from "./entriesTable";
import Entries from "./entries";
import Workflow from "./common/workflow";

import ImageButton from "./common/imagebutton";
import GearsImage from "../resources/gears.jpg";

export default class WorkflowList extends Component {
  state = {
    services: undefined,
    allDocs: undefined,
  };

  async componentDidMount() {
    let services = [
      bibleWorkerReportService,
      evangelismFundsService,
      eventRequestService,
      ministryGoalsService,
      ministryReportService,
      travelRequestService,
      serviceRequestService,
      vacationService,
    ];

    let allDocs = await Promise.all(
      await [
        services[0].getAllAsyncLookup(),
        services[1].getAllAsyncLookup(),
        services[2].getAllAsyncLookup(),
        services[3].getAllAsyncLookup(),
        services[4].getAllAsyncLookup(),
        services[5].getAllAsyncLookup(),
        services[6].getAllAsyncLookup(),
        services[7].getAllAsyncLookup(),
      ]
    );

    this.setState({ services, allDocs });
  }

  render() {
    let publicURL = `${process.env.PUBLIC_URL}`;

    if (!this.state.services || !this.state.allDocs) {
      return null;
    }

    let retDrafts = [];
    let retPendingApprovals = [];
    let retApprovalRequired = [];
    let retApprovedRejected = [];
    let draftCount = 0;
    let pendingApprovalCount = 0;
    let approvalRequiredCount = 0;
    this.state.allDocs.forEach((serviceDocs, indexService) => {
      retDrafts.push({ service: this.state.services[indexService], data: [] });
      retPendingApprovals.push({
        service: this.state.services[indexService],
        data: [],
      });
      retApprovalRequired.push({
        service: this.state.services[indexService],
        data: [],
      });
      retApprovedRejected.push({
        service: this.state.services[indexService],
        data: [],
      });
      serviceDocs &&
        serviceDocs.forEach((doc) => {
          let overallStatus = Workflow.getOverallStatus(doc._workflow);
          if (doc._workflow && doc._workflow.length > 0) {
            if (doc._author === authService.getProfile().token) {
              if (overallStatus === "Draft") {
                retDrafts[indexService].data.push(doc);
                draftCount++;
              } else if (
                overallStatus !== "Approved" &&
                overallStatus !== "Accepted" &&
                overallStatus !== "Received" &&
                overallStatus !== "Rejected"
              ) {
                retPendingApprovals[indexService].data.push(doc);
                pendingApprovalCount++;
              } else if (
                overallStatus === "Approved" ||
                overallStatus === "Accepted" ||
                overallStatus === "Received" ||
                overallStatus === "Rejected"
              ) {
                retApprovedRejected[indexService].data.push(doc);
              }
            }
          }
          if (doc._workflow !== null && typeof doc._workflow === "object") {
            for (let s = 1; s < doc._workflow.length; s++) {
              let stage = doc._workflow[s];
              if (
                stage.user === authService.getProfile().token &&
                (!stage.status || stage.status === "Pending") &&
                overallStatus !== "Approved" &&
                overallStatus !== "Accepted" &&
                overallStatus !== "Received" &&
                overallStatus !== "Draft"
              ) {
                retApprovalRequired[indexService].data.push(doc);
                approvalRequiredCount++;
              }
            }
          }
        });
    });

    if (this.props.type === "button") {
      return (
        <React.Fragment>
          {approvalRequiredCount} need your approval
          <br />
          {draftCount} are in draft (not submitted)
          <br />
          {pendingApprovalCount} are not completely approved by others
          <br />
          <ImageButton
            link={publicURL + "/workflow"}
            label="Workflow items"
            image={GearsImage}
          />
          <br />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>Workflow Items</h3>
        {this.renderWorkflowType(
          "You need to approve/reject:",
          retApprovalRequired
        )}
        {this.renderWorkflowType("Your draft workflow items:", retDrafts)}
        {this.renderWorkflowType(
          "Others need to approve/reject",
          retPendingApprovals
        )}
        {this.renderWorkflowType(
          "Your Approved/Rejected Items",
          retApprovedRejected
        )}
      </React.Fragment>
    );
  }

  renderWorkflowType(title, list) {
    let boxStyle = {
      border: "2px solid currentColor",
      backgroundColor: "#cccccc",
      padding: "5px",
    };
    let bottomLine = { borderBottom: "2px solid currentColor" };

    let sections = [];
    list.forEach((entry, index) => {
      if (entry.data.length > 0) {
        sections.push(
          <EntriesTable
            key={index}
            data={Entries.flatten(entry.data, entry.service)}
            service={entry.service}
          />
        );
      }
    });

    return (
      <React.Fragment>
        <br />
        <div style={boxStyle}>
          <h3 style={bottomLine}>{title}</h3>
          {sections.length === 0
            ? "None"
            : sections.map((section, index) => {
                if (index > 0) {
                  return (
                    <React.Fragment>
                      <hr style={bottomLine} />
                      {section}
                    </React.Fragment>
                  );
                } else {
                  return section;
                }
              })}
        </div>
      </React.Fragment>
    );
  }
}
