/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2020
 */

import React, { Component } from "react";
import ImageButton from "./common/imagebutton";
import { linkbuttonService } from "../services/linkbuttonService";
import { docService } from "../services/docService";
import Attachments from "./common/attachments/Attachments";
import Complete from "./common/complete";

export default class LinksPage extends Component {
  state = { buttons: [], docs: [] };

  async componentDidMount() {
    let buttonsPromise = linkbuttonService.getAllAsync();
    let docsPromise = docService.getAllAsync();
    let [buttons, docs] = await Promise.all([buttonsPromise, docsPromise]);
    buttons.sort((item1, item2) => item1.position - item2.position);
    docs.sort((doc1, doc2) => doc1 - doc2);
    this.setState({ buttons, docs });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.buttons.map((item, index) => {
          return (
            <ImageButton
              key={index}
              link={item.linkTo}
              image={Attachments.getFirstHREF(item.image)}
              label={item.title}
              newtab={item.newTab}
            />
          );
        })}
        {this.state.docs.map((doc, index) => {
          return (
            <p>
              <a href={"/docs/" + doc._id}>{doc.title}</a>
            </p>
          );
        })}
        <Complete />
      </React.Fragment>
    );
  }
}
