/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import TableTotals from "./tableTotals";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  onChange,
  totalsColumns,
  service,
  fullData,
  globalValidate
}) => {
  return (
    <table className="table">
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody
        fullData={fullData}
        columns={columns}
        data={data}
        onChange={onChange}
        service={service}
        globalValidate={globalValidate}
      />
      {totalsColumns && data && data.length > 0 && (
        <React.Fragment>
          <TableHeader
            columns={columns}
            sortColumn={sortColumn}
            onSort={onSort}
          />
          <TableTotals
            columns={columns}
            data={data}
            totalsColumns={totalsColumns}
          />
        </React.Fragment>
      )}
    </table>
  );
};

export default Table;
