/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const holidayDefinition = {
  baseUrl: "/settings/holidays",
  viewTitle: "Holidays",
  editTitle: "Edit Holiday",
  createTitle: "Create Holiday",
  fieldNames: ["date", "name"],
  createPermissions: ["admin", "sysadmin"],
  deletePermissions: ["admin", "sysadmin"],
  readPermissions: ["admin", "sysadmin"],
  writePermissions: ["admin", "sysadmin"],
  fields: {
    date: {
      label: "Date",
      type: "date",
      required: true
    },
    name: {
      label: "Name",
      type: "text",
      length: 255,
      required: true
    }
  }
};

export let holidayService = new EntryService(holidayDefinition);
