/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { workflowRuleService } from "../services/workflowRuleService";

export default class WorkflowRuleForm extends Form {
  render() {
    return (
      <EntryForm
        service={workflowRuleService}
        match={this.props.match}
        history={this.props.history}
      />
    );
  }
}
