/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const eventCoverageItemDefinition = {
  baseUrl: "/requests/eventrequest",
  viewTitle: "Event Requests",
  editTitle: "Edit Event Request",
  createTitle: "New Event Request",
  columnNames: ["church", "date", "speaker"],
  fieldNames: ["church", "date", "speaker"],
  readPermissions: ["[author]", "sysadmin"],
  writePermissions: ["[author]", "sysadmin"],
  fields: {
    church: {
      label: "Church",
      readonly: true,
      type: "text"
    },
    date: {
      label: "Date",
      readonly: true,
      type: "date"
    },
    speaker: {
      label: "Speaker",
      required: true,
      readonly: false,
      type: "text"
    }
  }
};

export let eventCoverageItemService = new EntryService(
  eventCoverageItemDefinition
);
