/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { churchService } from "./churchService";
import { groupService } from "./groupService";

export const usersDefinition = {
  baseUrl: "/settings/users",
  viewTitle: "Users",
  editTitle: "Edit User",
  createTitle: "Create User",
  hideNav: true,
  columnNames: ["name", "email", "churches", "groups", "manager"],
  fieldNames: ["name", "email", "password", "groups", "churches", "manager"],
  readPermissions: ["[all]"],
  createPermissions: ["admin", "sysadmin"],
  deletePermissions: ["admin", "sysadmin"],
  writePermissions: ["[author]", "admin", "sysadmin"],
  fields: {
    name: {
      label: "Name",
      type: "text",
      length: 255,
      required: true
    },
    email: {
      label: "E-Mail",
      type: "text",
      length: 255,
      required: true
    },
    password: {
      label: "Password",
      required: true,
      type: "password",
      length: 255
    },
    churches: {
      label: "Churches",
      required: false,
      type: "checkbox",
      length: 3200,
      selections: churchService,
      display: "md lg xl",
      writePermissions: ["admin", "sysadmin"]
    },
    groups: {
      label: "Groups",
      required: false,
      type: "checkbox",
      length: 3200,
      selections: groupService,
      display: "lg xl",
      writePermissions: ["admin", "sysadmin"]
    },
    manager: {
      label: "Manager",
      required: false,
      type: "select",
      length: 64,
      selections: "",
      display: "lg xl",
      writePermissions: ["admin", "sysadmin"]
    }
  }
};

export let userService = new EntryService(usersDefinition);

usersDefinition.fields.manager.selections = userService;
