/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { userService } from "./userService";
import dateUtils from "../utils/dateUtils";
import sharedFields from "./sharedFields";

function total(numbers) {
  let total = 0;
  numbers.forEach((number) => {
    if (number) {
      total += parseFloat(number);
    }
  });
  return total;
}

export const travelRequestDefinition = {
  baseUrl: "/requests/travelrequests",
  viewTitle: "Travel Requests",
  editTitle: "Edit Travel Request",
  createTitle: "Create Travel Request",
  hideNav: true,
  columnNames: [
    "from",
    "date",
    "meetingEvent",
    "location",
    "sponsor",
    "viewTotal",
    "viewWorkflow",
  ],
  fieldNames: [
    "from",
    "date",
    "labelNote",
    "meetingEvent",
    "location",
    "startDate",
    "endDate",
    "sponsor",
    "reason",
    "labelCosts",
    "milageParking",
    "airfare",
    "carrental",
    "lodging",
    "feesregistration",
    "viewTotal",
    "coverage",
  ],
  workflow: "travelrequest",
  createPermissions: ["pastor", "sysadmin"],
  deletePermissions: ["[author]", "travelrequest", "sysadmin"],
  readPermissions: ["[author]", "travelrequest", "sysadmin"],
  writePermissions: ["[author]", "travelrequest", "sysadmin"],
  fields: {
    viewWorkflow: {
      label: "Workflow",
      type: "workflow",
    },
    from: {
      label: "From",
      required: true,
      type: "select",
      length: 80,
      selections: userService,
      default: "@UserID",
      readonly: true,
    },
    date: {
      label: "Date",
      required: true,
      type: "date",
      default: "@Today",
      readonly: true,
    },
    labelNote: {
      label:
        "NOTE: Use this request form for all travels outside of your district and conference and sponsored by SNEC, Union, Division or GC. " +
        "For service request, please fill out the service request form on the SNEC Website in addition to this form. " +
        "For reimbursement, a copy of the approved request must be attached to the monthly reports with appropriate receipts. " +
        "Please be sure to leave ample time before the travel date for ADCOM approval.",
      type: "label",
    },
    meetingEvent: {
      label: "Meeting/Event",
      type: "text",
      length: 80,
      required: true,
    },
    location: {
      label: "Location",
      type: "text",
      length: 80,
      required: true,
    },
    startDate: {
      label: "Start Date",
      required: true,
      type: "date",
    },
    endDate: {
      label: "End Date",
      required: true,
      type: "date",
    },
    sponsor: {
      label:
        "Sponsor (Atlantic Union Conference, NAD, General Conference, Seminar Company/Church etc)",
      type: "text",
      length: 80,
      required: true,
    },
    reason: {
      label: "Reason for request to attend/participate",
      type: "text",
      length: 80,
      required: true,
    },
    labelCosts: {
      label: "Approximate costs (for qualified reimbursement travels):",
      type: "label",
      isSection: true,
    },
    milageParking: {
      label: "Mileage reimbursement/Parking",
      type: "currency",
      required: false,
    },
    airfare: {
      label: "Airfare",
      type: "currency",
      required: false,
    },
    carrental: {
      label: "Car Rental",
      type: "currency",
      required: false,
    },
    lodging: {
      label: "Estimated Lodging",
      type: "currency",
      required: false,
    },
    feesregistration: {
      label: "Fees/Registration",
      type: "currency",
      required: false,
    },
    viewTotal: {
      label: "Total",
      type: "calculated",
      fields: [
        "milageParking",
        "airfare",
        "carrental",
        "lodging",
        "feesregistration",
      ],
      value: (data) =>
        dateUtils.roundFraction(
          total([
            data.milageParking,
            data.airfare,
            data.carrental,
            data.lodging,
            data.feesregistration,
          ]),
          2,
          ""
        ),
    },
    coverage: sharedFields.coverage,
  },
};

export let travelRequestService = new EntryService(travelRequestDefinition);
