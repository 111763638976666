/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { bibleWorkerReportService } from "../services/bibleWorkerReportService";
import dateUtils from "../utils/dateUtils";
import { activityService } from "../services/activityService";

export default class BibleWorkerReportForm extends Form {
  state = {
    selectedMonth: dateUtils.getMonthOptions(0, 0)[0]._id
  };

  addActivity = (activityTable, newData) => {
    for (let i = 0; i < activityTable.length; i++) {
      let activity = activityTable[i];
      if (activity._id !== newData._id) {
        continue;
      }

      if (activity.type !== newData.type) {
        activity.type = "";
      }
      activity.description += " // " + newData.description;

      if (newData.beginningMiles && newData.endingMiles) {
        if (activity.districtMiles === undefined) {
          activity.districtMiles = "0.0";
        }
        activity.districtMiles = dateUtils.roundFraction(
          parseFloat(activity.districtMiles) +
            parseFloat(newData.endingMiles) -
            parseFloat(newData.beginningMiles),
          1
        );
      }
      if (newData.specialMiles) {
        if (activity.specialMiles === undefined) {
          activity.specialMiles = "0.0";
        }
        activity.specialMiles = dateUtils.roundFraction(
          parseFloat(activity.specialMiles) + parseFloat(newData.specialMiles),
          1
        );
      }
      if (newData.perDiem) {
        if (activity.perDiem === undefined) {
          activity.perDiem = "0.0";
        }
        activity.perDiem = dateUtils.roundFraction(
          parseFloat(activity.perDiem) + parseFloat(newData.perDiem),
          2
        );
      }
      if (newData.tolls) {
        if (activity.tolls === undefined) {
          activity.tolls = "0.0";
        }
        activity.tolls = dateUtils.roundFraction(
          parseFloat(activity.tolls) + parseFloat(newData.tolls),
          2
        );
      }
      if (newData.misc) {
        if (activity.misc === undefined) {
          activity.misc = "0.0";
        }
        activity.misc = dateUtils.roundFraction(
          parseFloat(activity.misc) + parseFloat(newData.misc),
          2
        );
      }
    }
    activityTable.push(newData);
  };

  /* data = array of objects having a "date" field (format: YYYY-MM-DD)
     month = string of month (format: YYYY-MM-DD or YYYY-MM)
     returns only the elements with a matching date for the month
  */
  filterMonth(data, month) {
    let ret = [];
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].date &&
        data[i].date.substring(0, 7) === month.substring(0, 7)
      ) {
        ret.push(data[i]);
      }
    }
    return ret;
  }

  initData = async data => {
    if (data === undefined || data.date === undefined) {
      return data;
    }

    let ret = [];

    if (data.startDate) {
      let startDate = dateUtils.SQL_to_Date(data.startDate);
      let endDate = dateUtils.SQL_to_Date(data.startDate);
      endDate.setUTCDate(endDate.getUTCDate() + 14);

      let activity = await activityService.getAllAsync();
      let currentActivity = [];
      activity.forEach(a => {
        if (
          dateUtils.SQL_to_Date(a.date).getTime() >= startDate.getTime() &&
          dateUtils.SQL_to_Date(a.date).getTime() <= endDate.getTime() &&
          a.type === "Bible Worker"
        ) {
          currentActivity.push(a);
        }
      });
      currentActivity.sort((a, b) => {
        if (a.date + a.timeIn < b.date + b.timeIn) {
          return -1;
        } else if (a.date + a.timeIn > b.date + b.timeIn) {
          return 1;
        } else {
          return 0;
        }
      });

      while (startDate.getTime() < endDate.getTime()) {
        let sqlDate = dateUtils.Date_to_SQL(startDate);
        let found = false;
        currentActivity.forEach(a => {
          if (a.date === sqlDate) {
            found = true;
            ret.push({
              date: sqlDate,
              timeIn: a.timeIn,
              timeOut: a.timeOut,
              activity: a.activity
            });
          }
        });
        if (!found) {
          ret.push({
            date: dateUtils.Date_to_SQL(startDate),
            timeIn: "",
            timeOut: "",
            _id: sqlDate
          });
        }
        startDate.setDate(startDate.getDate() + 1);
      }
    }

    data.activity = ret;
    return data;
  };

  entryform = undefined;

  render() {
    return (
      <EntryForm
        setRef={ref => {
          this.entryform = ref;
        }}
        service={bibleWorkerReportService}
        match={this.props.match}
        history={this.props.history}
        initData={this.initData}
      />
    );
  }
}
