/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { linkbuttonService } from "../services/linkbuttonService";

export default class LinkbuttonForm extends Form {
  render() {
    return (
      <React.Fragment>
        <EntryForm
          service={linkbuttonService}
          match={this.props.match}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}
