import React, { Component } from "react";
import Dropzone from "./Dropzone";
import "./Attachments.css";
import Progress from "./Progress";
import utils from "../../../utils/utils";
import CheckCircleImage from "./baseline-check_circle_outline-24px.svg";
import TrashIcon from "./trash.svg";
import PencilIcon from "./pencil.svg";
import Dialog from "../dialog";

class Attachments extends Component {
  getFiles() {
    if (typeof this.props.value === "string") {
      return JSON.parse(this.props.value);
    } else {
      return this.props.value;
    }
  }

  constructor(props) {
    super(props);

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  onFilesAdded(files) {
    for (let i = 0; i < files.length; i++) {
      this.sendRequest(files[i]);
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          let files = [...this.getFiles()];
          let i = 0;
          for (i = 0; i < files.length; i++) {
            if (files[i].name === file.name) {
              files[i].percentage = (event.loaded / event.total) * 100;
              break;
            }
          }
          if (i >= files.length) {
            files.push({
              name: file.name,
              percentage: (event.loaded / event.total) * 100,
            });
          }
          this.props.onChange({
            currentTarget: { name: this.props.name, value: files },
          });
        }
      });

      req.upload.addEventListener("error", (event) => {
        let files = [...this.getFiles()];
        let i = 0;
        for (i = 0; i < files.length; i++) {
          if (files[i].name === file.name) {
            files[i].error = "error";
            break;
          }
        }
        this.props.onChange({
          currentTarget: { name: this.props.name, value: files },
        });
        reject(req.response);
      });

      req.onload = () => {
        let files = [...this.getFiles()];
        let i = 0;
        for (i = 0; i < files.length; i++) {
          if (files[i].name === file.name) {
            const index = i;
            setTimeout(() => {
              delete files[index].percentage;
              this.setState({ files });
            }, 2000);
            files[index]._id = req.response._id;
            break;
          }
        }
        if (i >= files.length) {
          let newFile = {
            name: file.name,
            _id: req.response._id,
            percentage: 100,
          };
          files.push(newFile);
          setTimeout(() => {
            delete newFile.percentage;
            this.setState({ files });
          }, 2000);
        }
        this.props.onChange({
          currentTarget: { name: this.props.name, value: files },
        });
        this.props.onBlur({
          currentTarget: { name: this.props.name, value: files },
        });
      };

      const formData = new FormData();
      formData.append("file", file, file.name);

      req.open("POST", utils.getDbUrl() + "/attachments.php");
      req.responseType = "json";
      req.send(formData);
    });
  }

  renderProgress(file) {
    if (file.percentage) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={file.percentage} />
          <img
            className="CheckIcon"
            alt="done"
            src={CheckCircleImage}
            style={{
              opacity: file._id ? 0.5 : 0,
            }}
          />
        </div>
      );
    }
  }

  static getHREF(file) {
    return (
      utils.getDbUrl() +
      "/attachments.php?filename=" +
      encodeURIComponent(file.name) +
      "&_id=" +
      file._id
    );
  }

  static getFirstHREF(attachments) {
    if (typeof attachments === "string") {
      try {
        attachments = JSON.parse(attachments);
      } catch (e) {
        //NOOP
      }
    }
    if (
      attachments &&
      attachments[0] &&
      attachments[0].name &&
      attachments[0]._id
    ) {
      return this.getHREF(attachments[0]);
    }
  }

  render() {
    return (
      <div className="Upload">
        <div className="Content">
          {!this.props.readOnly && this.getFiles().length === 0 && (
            <div>
              <Dropzone
                onFilesAdded={this.onFilesAdded}
                multiple={this.props.multiple}
              />
            </div>
          )}
          <div className="Files">
            <b>
              <u>{this.props.label} (10 MB file size limit):</u>
            </b>
            <br />
            {this.getFiles().length === 0 ? "None" : ""}
            {this.getFiles().map((file) => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">
                    {!this.props.readOnly && (
                      <React.Fragment>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            Dialog.showYesNo(
                              "Delete file",
                              "Do you really want to delete file " +
                                file.name +
                                "?",
                              (label) => {
                                if (label === "yes") {
                                  let files = this.getFiles().filter(
                                    (f) => f._id !== file._id
                                  );

                                  this.props.onChange({
                                    currentTarget: {
                                      name: this.props.name,
                                      value: files,
                                    },
                                  });
                                  this.props.onBlur({
                                    currentTarget: {
                                      name: this.props.name,
                                      value: files,
                                    },
                                  });
                                }
                              }
                            );
                          }}
                        >
                          <img src={TrashIcon} alt="" />
                        </button>
                        &nbsp;
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            let value = prompt("Rename file", file.name);
                            if (value) {
                              let files = this.getFiles();
                              for (let i = 0; i < files.length; i++) {
                                if (files[i]._id === file._id) {
                                  let posValue = value.lastIndexOf(".");
                                  let posFile = file.name.lastIndexOf(".");
                                  if (
                                    value.substring(posValue) ===
                                    file.name.substring(posFile)
                                  ) {
                                    files[i].name = value;
                                  } else {
                                    files[i].name =
                                      value + file.name.substring(posFile);
                                  }
                                }
                              }
                              this.props.onChange({
                                currentTarget: {
                                  name: this.props.name,
                                  value: files,
                                },
                              });
                              this.props.onBlur({
                                currentTarget: {
                                  name: this.props.name,
                                  value: files,
                                },
                              });
                            }
                          }}
                        >
                          <img src={PencilIcon} alt="" />
                        </button>
                        &nbsp;
                      </React.Fragment>
                    )}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={Attachments.getHREF(file)}
                    >
                      {file.name}
                    </a>
                  </span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default Attachments;
