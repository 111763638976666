/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";

const Complete = () => {
  return <span id="span_id_completed" time={new Date().getTime()}></span>;
};

export default Complete;
