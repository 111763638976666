/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { bibleWorkerReportService } from "../services/bibleWorkerReportService";
import Entries from "./entries";
import dateUtils from "../utils/dateUtils";
import draftStorage from "../utils/draftStorage";
import Dialog from "./common/dialog";

export default class BibleWorkerReport extends Component {
  static async showCreateDialog(history) {
    let data = draftStorage.getDraft(bibleWorkerReportService.getForm(), "new");
    if (data) {
      //draft already exists - skip combo box dialog
      history.push("/reports/bibleworkerreport/new");
    } else {
      let startDate = new Date();
      startDate.setUTCDate(startDate.getUTCDate() - 7 * 52);
      let endDate = new Date();
      endDate.setUTCDate(endDate.getUTCDate() + 7 * 4);
      let weeklyOptions = dateUtils.getWeeklyOptions(startDate, endDate);
      let biweeklyOptions = [];
      for (let i = 0; i < weeklyOptions.length; i += 2) {
        biweeklyOptions.push(weeklyOptions[i]);
      }
      Dialog.showComboOkCancel(
        "Generate Bible Worker Report",
        "Select month of new report to generate:",
        biweeklyOptions,
        biweeklyOptions && biweeklyOptions[biweeklyOptions.length - 5]._id,
        async biweek => {
          if (biweek !== undefined) {
            history.push("/reports/bibleworkerreport/new?startDate=" + biweek);
          }
        }
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-primary btn-space"
          onClick={async e => {
            e.preventDefault();
            BibleWorkerReport.showCreateDialog(this.props.history);
          }}
        >
          Generate Bible Worker Report
        </button>
        <Entries
          service={bibleWorkerReportService}
          customFilter2={this.customFilter}
        />
      </React.Fragment>
    );
  }
}
