/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import dateUtils from "./dateUtils";

export default class Validator {
  _isString = false;
  _isRequired = false;
  _label = undefined;

  static string = () => {
    return new Validator().string();
  };

  static label = (label) => {
    return new Validator().label(label);
  };

  string = () => {
    this._isString = true;
    return this;
  };

  required = () => {
    this._isRequired = true;
    return this;
  };

  label = (label) => {
    this._label = label;
    return this;
  };

  static validateField(name, value, service, hideLabel) {
    let fieldDefinition = service.getFieldDefinition(name);
    if (fieldDefinition === undefined) {
      return "";
    }
    let label = hideLabel ? "" : '"' + fieldDefinition.label + '": ';

    if (!value && fieldDefinition.required) {
      return label + "Field required";
    }

    if (fieldDefinition.type === "date") {
      if (
        dateUtils.SQL_to_MDY(value) === undefined &&
        dateUtils.MDY_to_SQL(value) === undefined
      ) {
        return label + "Invalid Date";
      }
    } else if (value && fieldDefinition.type === "time") {
      value = value.trim().toLowerCase();
      if (value.endsWith("a") || value.endsWith("p")) {
        value = value.substring(0, value.length - 1);
      } else if (value.endsWith("am") | value.endsWith("pm")) {
        value = value.substring(0, value.length - 2);
      }
      value = value.trim();
      let hourMinute = value.split(":", 2);
      let hour = hourMinute[0].trim();
      let minute = hourMinute.length === 2 ? hourMinute[1] : "0";
      if (isNaN(hour) || isNaN(minute)) {
        return label + "Hour or Minute not numeric";
      }
      hour = parseInt(hour);
      minute = parseInt(minute);
      if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
        return label + "Hour or Minute has invalid number range";
      }
    } else if (
      fieldDefinition.type === "currency" ||
      fieldDefinition.type === "miles" ||
      fieldDefinition.type === "number"
    ) {
      if (value && isNaN(value)) {
        return label + "Not a Number";
      }
    }

    return "";
  }

  static transformField(name, value, data, service) {
    let fieldDefinition = service.getFieldDefinition(name);
    if (value && fieldDefinition.type === "time") {
      let isPM = false;
      let value1 = value.trim().toLowerCase();
      if (value1.endsWith("a")) {
        value1 = value1.substring(0, value1.length - 1);
      } else if (value1.endsWith("p")) {
        value1 = value1.substring(0, value1.length - 1);
        isPM = true;
      } else if (value1.endsWith("am")) {
        value1 = value1.substring(0, value1.length - 2);
      } else if (value1.endsWith("pm")) {
        value1 = value1.substring(0, value1.length - 2);
        isPM = true;
      }
      value1 = value1.trim();
      let hourMinute = value1.split(":", 2);
      let hour = hourMinute[0].trim();
      let minute = hourMinute.length === 2 ? hourMinute[1] : "0";
      if (isNaN(hour) || isNaN(minute)) {
        return value;
      }
      hour = parseInt(hour);
      minute = parseInt(minute);
      if (hour < 1 || hour > 12 || minute < 0 || minute > 59) {
        return value;
      }
      value =
        String(hour) +
        ":" +
        (minute < 10 && minute >= 0 ? "0" : "") +
        String(minute) +
        (isPM ? "pm" : "am");
    } else if (value && fieldDefinition.type === "currency") {
      value = dateUtils.roundFraction(value, 2);
    } else if (value && fieldDefinition.type === "miles") {
      if (fieldDefinition.fromField) {
        let fromValue = data[fieldDefinition.fromField];
        let fromMiles = parseFloat(fromValue) * 10;
        let toMiles = parseFloat(value) * 10;
        if (fromMiles > toMiles) {
          let b = String(fromMiles);
          let e = String(toMiles);
          b = b.substring(b.length - e.length);
          toMiles = fromMiles - parseInt(b) + toMiles;
          if (parseInt(b) > parseInt(e)) {
            toMiles += Math.pow(10, e.length);
          }
        }

        value = dateUtils.roundFraction(toMiles / 10, 1);
      } else {
        value = dateUtils.roundFraction(value, 1);
      }
    }
    return value;
  }

  static validate = (data, service) => {
    let errors = {};

    let fieldDefinitions = service ? service.getFieldDefinitions() : [];

    let keys = Object.keys(fieldDefinitions);
    for (let k = 0; k < keys.length; k++) {
      let key = keys[k];
      if (!this.isFieldShown(fieldDefinitions[key].showOnly, data)) {
        continue;
      }

      let customView = fieldDefinitions[key].customview;
      if (customView) {
        data = { ...data };
        data[key] = customView(data, key);
      }

      let value = Validator.transformField(key, data[key], data, service);
      let error = Validator.validateField(key, value, service, false);

      if (error) {
        errors[key] = error;
      } else {
        delete errors[key];
      }

      if (fieldDefinitions[key].type === "rows") {
        let rowsValue = data[key];
        if (typeof rowsValue === "string") {
          rowsValue = JSON.parse(rowsValue);
        }
        let rowService = fieldDefinitions[key].service;
        let rowErrors = {};
        rowsValue &&
          rowsValue.forEach((row) => {
            let rowKeys = Object.keys(rowService.getFieldDefinitions());
            rowKeys.forEach((rowKey) => {
              let error = this.validateField(
                rowKey,
                row[rowKey],
                rowService,
                false
              );
              if (error) {
                rowErrors[rowKey] = error;
              }
            });
          });
        let rowErrorKeys = Object.keys(rowErrors);
        if (rowErrorKeys.length > 0) {
          errors[key] = "";
          for (let r = 0; r < rowErrorKeys.length; r++) {
            if (r > 0) {
              errors[key] += "\n";
            }
            errors[key] += rowErrors[rowErrorKeys[r]];
          }
        }
      }
    }

    if (
      this.isFieldShown("startDate", data) &&
      this.isFieldShown("endDate", data)
    ) {
      if (data["startDate"] && data["endDate"]) {
        let strStartDate = data.startDate ? data.startDate : data.startDate;
        let strEndDate = data.endDate ? data.endDate : data.endDate;
        let startEpoch = dateUtils.getEpoch(strStartDate);
        let endEpoch = dateUtils.getEpoch(strEndDate);
        if (startEpoch === undefined) {
          errors["startDate"] = "Invalid Date Format";
        }
        if (endEpoch === undefined) {
          errors["endDate"] = "Invalid Date Format";
        }
        if (endEpoch < startEpoch) {
          errors["startDate"] = "End Date cannot be before Start Date";
          errors["endDate"] = "End Date cannot be before Start Date";
        } else if (endEpoch - startEpoch > 365 * 24 * 3600 * 1000) {
          errors["startDate"] =
            "End Date cannot be more than a year later than Start Date";
          errors["endDate"] =
            "End Date cannot be more than a year later than Start Date";
        }
      }
    }
    return errors;
  };

  static isFieldShown = (showOnlyDef, data) => {
    if (showOnlyDef === undefined) {
      return true;
    }
    let posColon = showOnlyDef.indexOf(":");
    if (posColon === -1) {
      return false;
    }
    let fieldKey = showOnlyDef.substring(0, posColon);
    let fields = "," + showOnlyDef.substring(posColon + 1) + ",";
    let fieldValue = data[fieldKey];
    if (fields.indexOf("," + fieldValue + ",") === -1) {
      return false;
    } else {
      return true;
    }
  };
}
