/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Validator from "../utils/Validator";
import Form from "./common/form";
import Field from "./common/field";
import { toast } from "react-toastify";
import authService from "../services/authService";
import { workflowRuleService } from "../services/workflowRuleService";
import { userService } from "../services/userService";
import utils from "../utils/utils";
import draftStorage from "../utils/draftStorage";
import Dialog from "./common/dialog";
import ChangeLog from "./changeLog";
import Complete from "./common/complete";

export default class EntryForm extends Form {
  state = {
    data: {},
    globalValidate: false,
    title: "",
    complete: false,
  };

  schema = {
    _id: Validator.string(),
  };

  workflowFields = undefined;

  refs = {};

  setRef = (name, input) => {
    let refs = { ...this.refs };
    refs[name] = input;
    this.refs = refs;
  };

  setWorkflowFields = (action) => {
    this.workflowFields = action;
    if (action.comment !== undefined) {
      let data = { ...this.state.data };
      data._workflow[action.index].comment = action.comment;
      this.setState({ data });
    }
  };

  getInitialWorkflow = async () => {
    let rules = [];
    let profile = authService.getProfile();
    let workflowRulesArray = await workflowRuleService.getAllAsync();
    /*let currentUser = await userService.getAsync(
      authService.getProfile().token
    );*/
    //let manager = await userService.getAsync(currentUser.manager);
    for (let iRules = 0; iRules < workflowRulesArray.length; iRules++) {
      let workflow = workflowRulesArray[iRules];
      if (workflow.workflowkey !== this.props.service.getWorkflow()) {
        continue;
      }
      rules = [
        {
          name: profile.name,
          email: profile.email,
          status: "Draft",
          comment: "",
        },
        ...JSON.parse(workflow.rules),
      ];

      for (let i = 1; i < workflow.length; i++) {
        if (rules[i].user && (!rules[i].name || !rules[i].email)) {
          let u = await userService.getAsync(rules[i].user);
          rules[i].name = u.name;
          rules[i].email = u.email;
        }
      }
      break;
    }
    return rules;
  };

  setFocus() {
    let fieldNames = this.props.service.getFieldNames();
    for (let i = 0; i < fieldNames.length; i++) {
      let fieldName = fieldNames[i];
      let fieldDefinition = this.props.service.getFieldDefinition(fieldName);
      if (
        fieldDefinition.readonly !== true &&
        fieldDefinition.type !== "label" &&
        fieldDefinition.type !== "date" &&
        fieldDefinition.type !== "rows" &&
        fieldDefinition.type !== "column" &&
        this.refs[fieldName]
      ) {
        this.refs[fieldName].focus();
        break;
      }
    }
  }

  async componentDidMount() {
    let fieldDefinitions = this.props.service.getFieldDefinitions();
    for (const fieldName in fieldDefinitions) {
      if (fieldDefinitions[fieldName].initAsync) {
        await fieldDefinitions[fieldName].initAsync();
      }
    }
    if (this.props.setRef !== undefined) {
      this.props.setRef(this);
    }
    let fieldNames = this.props.service.getFieldNames();
    for (let i = 0; i < fieldNames.length; i++) {
      let fieldName = fieldNames[i];
      let fieldDefinition = this.props.service.getFieldDefinition(fieldName);
      if (fieldDefinition === undefined) {
        console.log("field not found: " + fieldName);
      }
      this.schema[fieldName] = new Validator();
      if (fieldDefinition.required === true) {
        this.schema[fieldName] = Validator.string().required();
      }
      this.schema[fieldName] = this.schema[fieldName].label(
        fieldDefinition.label
      );
    }

    const entryId = this.props.match.params.id;
    if (entryId === "new") {
      let data = draftStorage.getDraft(this.props.service.getForm(), "new");
      if (!data) {
        data = utils.getUrlVars();
        let fieldDefinitions = this.props.service.getFieldDefinitions();
        let keys = Object.keys(fieldDefinitions);
        for (let f = 0; f < keys.length; f++) {
          let initValue = fieldDefinitions[keys[f]].default;
          let name = keys[f];
          if (
            (initValue !== undefined && data[name] === undefined) ||
            data[name] === ""
          ) {
            if (initValue === "@Today") {
              let now = new window.Date();
              let month = now.getUTCMonth() + 1;
              if (month < 10) month = "0" + String(month);
              let day = now.getUTCDate();
              if (day < 10) day = "0" + String(day);
              data[name] = now.getUTCFullYear() + "-" + month + "-" + day;
            } else if (initValue === "@UserID") {
              data[name] = authService.getProfile().token;
            } else {
              data[name] = initValue;
            }
          }
        }
        if (this.props.service.definition.translateData) {
          data = this.props.service.definition.translateData(data);
        }

        if (this.props.initData) {
          data = await this.props.initData(data);
        }
      } else {
        let ago = draftStorage.getModifiedAgo(
          this.props.service.getForm(),
          "new"
        );
        if (
          ago &&
          window.location.href.indexOf("?suppressDraftWarning=true") === -1
        ) {
          Dialog.showMessage(
            "Draft exists for this form",
            "An existing draft for this form was opened. The draft was updated " +
              ago +
              '. To discard this draft, hit the "Cancel" button at the bottom.'
          );
        }
      }
      if (this.props.service.hasWorkflow()) {
        data._workflow = await this.getInitialWorkflow();
      }

      this.setState(
        {
          data,
          title: this.props.service.getCreateTitle(),
          complete: true,
        },
        () => this.setFocus()
      );
      return;
    }

    const entry = await this.props.service.getAsync(entryId);
    if (!entry)
      return this.props.history.replace(`${process.env.PUBLIC_URL}/not-found`);

    //check if we have a draft
    let data = draftStorage.getDraft(this.props.service.getForm(), entryId);
    if (!data) {
      data = this.mapToViewModel(entry);
    } else {
      let ago = draftStorage.getModifiedAgo(
        this.props.service.getForm(),
        entryId
      );
      if (ago) {
        Dialog.showMessage(
          "Draft exists for this item",
          "An existing draft for this form was opened. The draft was updated " +
            ago +
            '. To discard this draft, hit the "Cancel" button at the bottom.'
        );
      }
    }

    if (
      this.props.service.hasWorkflow() &&
      (data._workflow === undefined || data._workflow === "")
    ) {
      data._workflow = await this.getInitialWorkflow();
    }

    if (this.props.service.definition.translateData) {
      data = this.props.service.definition.translateData(data);
    }
    this.setState(
      {
        data,
        title: this.props.service.getEditTitle(),
        complete: true,
      },
      () => this.setFocus()
    );
  }

  mapToViewModel(entry) {
    let ret = {
      _id: entry._id,
    };
    let fieldNames = this.props.service.getFieldNames();
    for (let i = 0; i < fieldNames.length; i++) {
      ret[fieldNames[i]] = entry[fieldNames[i]];
    }

    if (entry._workflow) {
      ret._workflow = entry._workflow;
    }
    if (entry._author) {
      ret._author = entry._author;
    }
    return ret;
  }

  async doSubmit(errors) {
    let hasDateErrors = false;
    if (errors) {
      let keys = Object.keys(errors);
      keys.forEach((key) => {
        let fieldDefinition = this.props.service.getFieldDefinition(key);
        if (
          fieldDefinition.type === "date" ||
          fieldDefinition.type === "time"
        ) {
          hasDateErrors = true;
        }
      });
    }
    if (
      (!this.workflowFields ||
        hasDateErrors ||
        (this.workflowFields && this.workflowFields.action !== "draft")) &&
      errors &&
      Object.keys(errors).length > 0
    ) {
      let msg = "";
      Object.keys(errors).forEach((error) => {
        if (msg.length > 0) {
          msg += "\n";
        }
        msg += errors[error];
      });
      Dialog.showMessage(
        "Field validation failed",
        msg.split("\n").map((m, index) => (
          <React.Fragment key={index}>
            {index > 0 && <br />}
            {m}
          </React.Fragment>
        ))
      );
      this.setState({ globalValidate: true });
      return;
    }
    this.setState({ globalValidate: false });
    let data = { ...this.state.data };
    let fieldNames = this.props.service.getFieldNames();
    fieldNames.forEach((fieldName) => {
      let fieldDefinition = this.props.service.getFieldDefinition(fieldName);
      if (
        data[fieldName] === undefined ||
        !Validator.isFieldShown(fieldDefinition.showOnly, data)
      ) {
        if (fieldDefinition.type === "number") {
          data[fieldName] = 0;
        } else {
          data[fieldName] = "";
        }
      } else if (fieldDefinition.type === "number") {
        if (typeof data[fieldName] !== "number") {
          data[fieldName] =
            data[fieldName] === "" ? "" : parseInt(data[fieldName]);
        }
      }
      if (fieldDefinition.type === "custom") {
        if (data[fieldName] === undefined || data[fieldName] === "") {
          data[fieldName] = "{}";
        }
      }
    });

    let workflowFields = this.workflowFields;

    if (
      this.state.data._workflow !== undefined &&
      workflowFields &&
      this.state.data._workflow[0]
    ) {
      if (workflowFields.action === "draft") {
        let oldWorkflow = data._workflow;
        data._workflow = await this.getInitialWorkflow();
        if (
          oldWorkflow &&
          data._workflow &&
          oldWorkflow.length > 0 &&
          oldWorkflow.length === data._workflow.length
        ) {
          for (let i = 0; i < oldWorkflow.length; i++) {
            if (
              oldWorkflow[i].name === data._workflow[i].name &&
              oldWorkflow[i].email === data._workflow[i].email &&
              oldWorkflow[i].user === data._workflow[i].user
            ) {
              data._workflow[i].comment = oldWorkflow[i].comment;
            } else {
              console.log(
                "Workflow comments not retained for [" + i + "]",
                oldWorkflow,
                data._workflow
              );
            }
          }
        } else {
          console.log(
            "Workflow comments not retained",
            oldWorkflow,
            data._workflow
          );
        }
      } else if (workflowFields.action === "submit") {
        data._workflow[0].status = "Submitted";
      } else if (
        workflowFields.action === "approve" ||
        workflowFields.action === "approve_one"
      ) {
        data._workflow[workflowFields.index].status = "Approved";
      } else if (workflowFields.action === "accept") {
        data._workflow[workflowFields.index].status = "Accepted";
      } else if (workflowFields.action === "received") {
        data._workflow[workflowFields.index].status = "Received";
      } else if (workflowFields.action === "reject") {
        data._workflow[workflowFields.index].status = "Rejected";
      } else if (workflowFields.action === "cancel") {
        data._workflow[0].status = "Canceled";
        for (let i = 1; i < data._workflow.length; i++) {
          if (data._workflow[i].status === "Pending") {
            data._workflow[i].status = "Canceled";
            break;
          }
        }
      }
    }
    this.workflowFields = undefined;

    let success = await this.props.service.saveAsync(data);
    if (success !== false) {
      toast.success("Save successful");
      if (this.props.history) {
        this.props.history.push(this.props.service.getBaseUrl());
      }
      if (this.props.callback && success) {
        this.props.callback(success);
      }
    } else {
      toast.error("Save failed");
    }
  }

  setData = (data) => {
    let data1 = { ...data };
    this.setState({ data: data1 });
  };

  render() {
    let fieldnames = this.props.service.getFieldNames();
    let fieldOutput = [];
    let columns = undefined;
    for (let i = 0; i < fieldnames.length; i++) {
      let name = fieldnames[i];
      let label = this.props.service.getFieldLabel(name);
      let fieldDefinition = this.props.service.getFieldDefinition(name);
      if (fieldDefinition.required) {
        label += "*";
      }
      let out = undefined;
      if (!Validator.isFieldShown(fieldDefinition.showOnly, this.state.data)) {
        out = undefined;
      } else if (fieldDefinition.type === "column") {
        if (columns === undefined) {
          columns = [];
        }
        if (fieldDefinition.columnEnd) {
          out = (
            <div key={name + "_" + i} className="container">
              <div className="row">
                {columns.map((column, index) => (
                  <div key={index} className="col-md">
                    {column}
                  </div>
                ))}
              </div>
            </div>
          );
          columns = undefined;
        } else {
          columns.push([]);
        }
      } else {
        let field = (
          <Field
            id={this.props.match.params.id}
            data={this.state.data}
            hideLabel={false}
            history={this.props.history}
            key={name}
            label={label}
            name={name}
            onChange={this.handleChange}
            service={this.props.service}
            setRef={this.setRef}
            globalValidate={this.state.globalValidate}
          />
        );
        if (columns === undefined) {
          out = field;
        } else {
          columns[columns.length - 1].push(field);
        }
      }

      if (out !== undefined) {
        fieldOutput.push(out);
      }
    }

    return (
      <div>
        {this.props.callback ? (
          <React.Fragment>
            Note: Scroll dialog content if necessary to expose submit buttons
            <h1>{this.state.title}</h1>
          </React.Fragment>
        ) : (
          <h1 className="d-none d-lg-inline">{this.state.title}</h1>
        )}
        {this.props.insertHeader && this.props.insertHeader(this.state.data)}
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <div>{fieldOutput}</div>
          <div className="btn-toolbar">
            {this.state.data._workflow && this.state.data._workflow[0]
              ? this.state.data._workflow[0].status === "Draft"
                ? this.renderWorkflowButtons(this.setWorkflowFields)
                : null
              : this.props.service.hasWritePermissions(
                  undefined,
                  this.state.data._author
                )
              ? this.renderSubmitButton("Save")
              : null}
            &nbsp;
            {this.renderCancelButton()}
            &nbsp;
            {this.props.match.params.id !== "new" &&
              this.props.service.hasDeletePermissions(
                undefined,
                this.state.data && this.state.data._author
              ) &&
              this.renderDeleteButton(
                this.props.service,
                this.props.match.params.id
              )}
          </div>
          {this.props.service.hasWorkflow() && this.renderWorkflow()}
        </form>
        {this.props.service.definition.showChangeLog !== false && (
          <ChangeLog id={this.props.match.params.id} />
        )}
        {this.props.insertFooter && this.props.insertFooter(this.state.data)}
        {this.state.complete && <Complete />}
      </div>
    );
  }
}
