/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import { activityService } from "../services/activityService";
import EntryForm from "./entryForm";

export default class ActivityForm extends Form {
  initData = async data => {
    let a = await activityService.getAllAsyncLookup();
    let endingMiles = undefined;
    for (let i = 0; i < a.length; i++) {
      if (a[i].endingMiles && a[i].endingMiles !== "0.0") {
        endingMiles = a[i].endingMiles;
        break;
      }
    }
    if (endingMiles) {
      data.beginningMiles = endingMiles;
    }
    return data;
  };

  render() {
    return (
      <EntryForm
        service={activityService}
        match={this.props.match}
        history={this.props.history}
        initData={this.initData}
      />
    );
  }
}
