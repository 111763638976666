/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Entries from "./entries";
import Dialog from "./common/dialog";
import dateUtils from "../utils/dateUtils";
import { ministryGoalsService } from "../services/ministryGoalsService";
import authService from "../services/authService";
import draftStorage from "../utils/draftStorage";

export default class MinistryGoals extends Component {
  static async showCreateDialog(history) {
    let data = draftStorage.getDraft(ministryGoalsService.getForm(), "new");
    if (data) {
      //draft already exists - skip combo box dialog
      history.push("/reports/ministrygoals/new");
    } else {
      let yearOptionsAll = dateUtils.getYearOptions(-10, 1);
      let data = await ministryGoalsService.getAllAsync();
      let yearOptions = [];
      yearOptionsAll.forEach(entry => {
        let entry2 = { ...entry };
        for (let d = 0; d < data.length; d++) {
          if (
            entry._id === data[d].year &&
            data[d].userid === authService.getProfile().token
          ) {
            entry2.name = entry2.name + " (Open existing)";
            entry2._id = data[d]._id;
          }
        }
        yearOptions.push(entry2);
      });

      Dialog.showComboOkCancel(
        "Create Ministry Goals",
        "Select year of new goals:",
        yearOptions,
        yearOptions && yearOptions[yearOptions.length - 2]._id,
        async year => {
          if (year !== undefined) {
            if (year.length === 64) {
              history.push("/reports/ministrygoals/" + year);
            } else {
              history.push("/reports/ministrygoals/new?year=" + year);
            }
          }
        }
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {" "}
        <button
          className="btn btn-primary btn-space"
          onClick={async e => {
            e.preventDefault();
            MinistryGoals.showCreateDialog(this.props.history);
          }}
        >
          Create Ministry Goals
        </button>
        <Entries service={ministryGoalsService} />
      </React.Fragment>
    );
  }
}
