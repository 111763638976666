/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import dateUtils from "../../utils/dateUtils";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

export default class Date extends Component {
  state = {
    value: undefined
  };

  handleChange = ({ currentTarget: input }) => {
    this.setState({ value: input.value });
  };

  handleBlur = ({ currentTarget: input }) => {
    let sqlDate = dateUtils.MDY_to_SQL(input.value);
    if (sqlDate !== undefined) {
      this.setState({ value: dateUtils.SQL_to_MDY(sqlDate) });
    }
    this.props.onBlur({ currentTarget: input });
  };

  render() {
    let { name, label, error, readOnly } = this.props;
    let mdyDate = this.props.value && dateUtils.SQL_to_MDY(this.props.value);
    if (!this.state.value && mdyDate !== undefined) {
      if (this.state.value !== mdyDate) {
        this.setState({ value: mdyDate });
      }
    }
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor={name}>{label}</label>
          {this.props.readOnly ? (
            <input
              value={this.state.value}
              name={name}
              id={name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              className="form-control"
              readOnly={readOnly}
            />
          ) : (
            <DayPickerInput
              value={this.state.value}
              placeholder="M/D/YYYY"
              formatDate={(date, format) => {
                return dateUtils.SQL_to_MDY(dateUtils.Date_to_SQL(date));
              }}
              parseDate={(strDate, format) => {
                let parts = strDate.split("/");
                if (
                  parts === undefined ||
                  parts.length !== 3 ||
                  isNaN(parts[0]) ||
                  isNaN(parts[1]) ||
                  isNaN(parts[2]) ||
                  parts[2] === ""
                ) {
                  return new window.Date();
                } else {
                  let year = parseInt(parts[2]);
                  if (year < 1000) {
                    year += 2000;
                  }
                  return new window.Date(
                    year,
                    parseInt(parts[0]) - 1,
                    parseInt(parts[1])
                  );
                }
              }}
              className="form-control"
              onDayChange={date => {
                let value = "";
                if (date !== undefined) {
                  value = dateUtils.SQL_to_MDY(dateUtils.Date_to_SQL(date));
                }
                this.props.onBlur({ currentTarget: { name, value } });
              }}
            />
          )}
        </div>
        {error && (
          <React.Fragment>
            <div />
            <div className="alert alert-danger">{error}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
