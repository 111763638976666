/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import EntryService from "./entryService";
import { userService } from "./userService";
import dateUtils from "../utils/dateUtils";
import { churchService } from "./churchService";

export const baptismReportDefinition = {
  baseUrl: "/reports/baptismreport",
  viewTitle: "Baptism Report",
  editTitle: "Edit Baptism Report",
  columnNames: [
    "userid",
    "viewName",
    "viewAddress",
    "baptismType",
    "date",
    "dob",
    "churchMembership",
    "viewWorkflow",
  ],
  fieldNames: [
    "userid",
    "date",
    "firstName",
    "middleName",
    "lastName",
    "address",
    "city",
    "state",
    "zip",
    "email",
    "homePhone",
    "cellPhone",
    "dob",
    "maritalStatus",
    "churchMembership",
    "spouse",
    "occupation",
    "baptismType",
    "comment",
  ],
  createPermissions: ["pastor", "sysadmin"],
  deletePermissions: ["[author]", "baptismreport", "sysadmin"],
  readPermissions: ["[author]", "baptismreport", "sysadmin"],
  writePermissions: ["[author]", "baptismreport", "sysadmin"],
  workflow: "baptismreport",
  fields: {
    viewName: {
      label: "Name",
      type: "calculated",
      fields: ["middleName", "firstName", "lastName"],
      value: (data) => {
        if (data.middleName) {
          return (
            data.firstName +
            " " +
            data.middleName.substring(0, 1) +
            " " +
            data.lastName
          );
        } else {
          return data.firstName + " " + data.lastName;
        }
      },
    },
    viewAddress: {
      label: "Address",
      type: "calculated",
      fields: ["address", "city", "state", "zip"],
      value: (data) => {
        return (
          <React.Fragment>
            {data.address}
            <br />
            {data.city}, {data.state} {data.zip}
          </React.Fragment>
        );
      },
      display: "lg xl",
    },
    viewPhone: {
      label: "Phone",
      type: "calculated",
      fields: ["homePhone", "cellPhone"],
      value: (data) => {
        return (
          <React.Fragment>
            {data.homePhone && "H: " + data.homePhone}
            {data.homePhone && data.cellPhone && <br />}
            {data.cellPhone && "C: " + data.cellPhone}
          </React.Fragment>
        );
      },
    },
    viewWorkflow: {
      label: "Workflow",
      type: "workflow",
    },
    userid: {
      label: "Pastor",
      required: true,
      type: "select",
      length: 64,
      selections: userService,
      default: "@UserID",
      readonly: true,
    },
    date: {
      label: "Date of Baptism/POF",
      required: true,
      type: "date",
      default: dateUtils.getMonthOptions(0, 0)[0]._id,
      readonly: false,
    },
    firstName: {
      label: "First Name",
      required: true,
      type: "text",
      length: 80,
    },
    middleName: {
      label: "Middle Name",
      required: false,
      type: "text",
      length: 80,
    },
    lastName: {
      label: "Last Name",
      required: true,
      type: "text",
      length: 80,
    },
    address: {
      label: "Address",
      required: true,
      type: "text",
      length: 80,
    },
    city: {
      label: "City",
      required: true,
      type: "text",
      length: 80,
    },
    state: {
      label: "State",
      required: true,
      type: "text",
      length: 2,
    },
    zip: {
      label: "Zip",
      required: true,
      type: "text",
      length: 20,
    },
    email: {
      label: "E-Mail",
      required: false,
      display: "md lg xl",
      type: "text",
      length: 80,
    },
    homePhone: {
      label: "Home Phone",
      required: false,
      type: "text",
      length: 80,
    },
    cellPhone: {
      label: "Cell Phone",
      required: false,
      type: "text",
      length: 80,
    },
    dob: {
      label: "Date of Birth",
      type: "date",
      required: true,
    },
    maritalStatus: {
      label: "Marrital Status",
      type: "select",
      length: 80,
      required: true,
      selections: "single,married,separated,divorced,widowed",
    },
    churchMembership: {
      label: "Church Membership",
      type: "select_other",
      length: 80,
      required: true,
      selections: churchService,
    },
    spouse: {
      label: "Name of parents or spouse currently residing with",
      type: "text",
      length: 80,
      required: true,
    },
    occupation: {
      label: "Occupation",
      required: false,
      type: "text",
      length: 80,
    },
    baptismType: {
      label: "Baptism/PoF",
      required: true,
      type: "select",
      length: 80,
      selections: "Baptism,Profession of Faith",
    },
    comment: {
      label: "Comment",
      required: false,
      type: "text",
      length: 80,
    },
  },
};

export let baptismReportService = new EntryService(baptismReportDefinition);
