/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Dialog from "./dialog";

export default class Select extends Component {
  handleChange = ({ currentTarget: input }) => {
    if (input.value === "_new") {
      let dialog = Dialog.showForm(this.props.service, "new", (id) => {
        Dialog.removeDialog(dialog);
        if (id === undefined) {
          this.props.onChange({
            currentTarget: { name: input.name, value: "" },
          });
        } else {
          this.props.onChange({
            currentTarget: { name: input.name, value: id },
          });
          window.location.href =
            window.location.href + "?suppressDraftWarning=true";
        }
      });
    }

    this.props.onChange({
      currentTarget: { name: this.props.name, value: input.value },
    });
  };

  static getName(obj) {
    return (
      obj.name ||
      obj.firstName +
        " " +
        (obj.middleName ? obj.middleName.substring(0, 1) + ". " : " ") +
        obj.lastName
    );
  }

  render() {
    let {
      name,
      label,
      options,
      error,
      value,
      onBlur,
      readOnly,
      setRef,
    } = this.props;
    let foundInOptions = true;
    let textValue = value;
    if (value) {
      foundInOptions = false;
      options.forEach((option) => {
        if (option._id === value) {
          foundInOptions = true;
          textValue =
            option.name ||
            option.firstName +
              " " +
              (option.middleName
                ? option.middleName.substring(0, 1) + ". "
                : " ") +
              option.lastName;
        }
      });
    }

    let options1 = options;
    if (this.props.sorted === true) {
      options1 = options.sort((a, b) => {
        let aLower = a.name.toLowerCase();
        let bLower = b.name.toLowerCase();
        if (aLower > bLower) return 1;
        if (aLower < bLower) return -1;
        return 0;
      });
    }

    return (
      <React.Fragment>
        <div className={label && "form-group"}>
          {label && <label htmlFor={name}>{label}</label>}
          {readOnly ? (
            <React.Fragment>
              <input
                name={name}
                id={name}
                key={name + "_"}
                value={value ? value : ""}
                type="hidden"
              />
              {textValue}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span className="d-none d-print-block">{textValue}</span>
              <select
                name={name}
                id={name}
                key={name}
                value={foundInOptions ? value : "other"}
                onChange={this.handleChange}
                onBlur={(event) => {
                  onBlur && onBlur({ currentTarget: { name, value } });
                }}
                className="form-control d-print-none"
                disabled={readOnly}
                ref={(input) => setRef && setRef(name, input)}
              >
                <option value="" />
                {options1.map((option) => (
                  <option key={option._id} value={option._id}>
                    {Select.getName(option)}
                  </option>
                ))}
                {this.props.hasOthers === true && (
                  <option key="other" value="other">
                    * Other
                  </option>
                )}
              </select>
            </React.Fragment>
          )}
        </div>
        {!foundInOptions && (
          <React.Fragment>
            <div>
              <div className={label && "form-group"}>
                <label>&nbsp;</label>
                <label htmlFor={name}>Specify:</label>
                <input
                  name={name + "_other"}
                  id={name}
                  value={value === "other" ? "" : value}
                  onChange={this.handleChange}
                  onBlur={(event) => {
                    onBlur({ currentTarget: { name, value } });
                  }}
                  className="form-control"
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {error && <div className="alert alert-danger">{error}</div>}
      </React.Fragment>
    );
  }
}
