/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { userService } from "./userService";
import { churchService } from "./churchService";

export const evangelismFundsDefinition = {
  baseUrl: "/requests/evangelismfunds",
  viewTitle: "Evangelism Funds",
  editTitle: "Edit Evangelism Funds Request",
  createTitle: "New Evangelism Funds Request",
  hideNav: true,
  columnNames: [
    "userid",
    "church",
    "year",
    "startDate",
    "endDate",
    "targetGroup",
    "speaker",
    "viewWorkflow",
  ],
  fieldNames: [
    "labelTitle",
    "labelTitle2",
    "labelTitle3",
    "labelYear",
    "year",
    "labelChurchInformation",
    "userid",
    "contactPhone",
    "church",
    "labelConnectActivities",
    "startDate",
    "endDate",
    "targetGroup",
    "meetingLocation",
    "speaker",
    "newChurchPlant",
    "labelCommunityEvents",
    "communityEventsDescription",
    "communityEventsDates",
    "communityEventsRemarks",
    "labelHealthEvents",
    "healthEventsDescription",
    "healthEventsDates",
    "healthEventsRemarks",
    "labelMeetConnectActivities",
    "meetConnectActivitiesDescription",
    "meetConnectActivitiesDates",
    "meetConnectActivitiesRemarks",
    "labelRelateConnectEvents",
    "relateConnectEventsDescription",
    "relateConnectEventsDates",
    "relateConnectEventsRemarks",
    "labelMediaActivities",
    "mediaActivitiesDescription",
    "mediaActivitiesDates",
    "mediaActivitiesRemarks",
    "labelSeniorInactiveConnect",
    "seniorInactiveDescription",
    "seniorInactiveDates",
    "seniorInactiveRemarks",
    "labelProclaimActivities",
    "proclaimActivitiesDescription",
    "proclaimActivitiesDates",
    "proclaimActivitiesRemarks",
    "labelBibleWorker",
    "bibleWorkerDescription",
    "bibleWorkerDates",
    "bibleWorkerRemarks",
    "labelFundsNeeded",
    "dateFundsNeeded",
    "labelNote",
  ],
  workflow: "evangelismfunds",
  createPermissions: ["pastor", "sysadmin"],
  deletePermissions: ["[author]", "evangelismfunds", "sysadmin"],
  readPermissions: ["[author]", "evangelismfunds", "sysadmin"],
  writePermissions: ["[author]", "evangelismfunds", "sysadmin"],
  fields: {
    viewWorkflow: {
      label: "Workflow",
      type: "workflow",
    },
    labelTitle: {
      label: "SNEC Conference Evangelism Funds Request Form",
      type: "label",
    },
    labelTitle2: {
      label: "Evangelism Is About Building Relationships",
      type: "label",
    },
    labelTitle3: {
      label:
        '"The Savior mingled with people as one who desired their good. He showed sympathy for them, ministered to their needs, and won their confidence.Then He invited them, "Follow Me." (Ministry of Health and Healing, p. 73) Adapted from Ministry of Healing p.143.',
      type: "label",
    },
    labelYear: {
      label: "Evangelism Funds Request",
      type: "label",
      isSection: true,
    },
    year: {
      label: "For Year",
      required: true,
      type: "year",
    },
    labelChurchInformation: {
      label: "Church Information",
      type: "label",
      isSection: true,
    },
    userid: {
      label: "Name",
      required: true,
      type: "select",
      length: 64,
      selections: userService,
      default: "@UserID",
      readonly: true,
    },
    contactPhone: {
      label: "Contact Phone",
      type: "text",
      length: 80,
      required: true,
    },
    church: {
      label: "Church",
      type: "select_other",
      length: 64,
      selections: churchService,
      required: true,
    },
    labelConnectActivities: {
      label:
        "Connect Activities (Please fill where applicable ONLY) (for Church Plant: max: $10,000)",
      type: "label",
      isSection: true,
    },
    startDate: {
      label: "Start Date",
      required: true,
      type: "date",
    },
    endDate: {
      label: "End Date",
      required: true,
      type: "date",
    },
    targetGroup: {
      label: "Target Group",
      required: true,
      type: "text",
    },
    meetingLocation: {
      label: "Meeting Location",
      required: true,
      type: "text",
    },
    speaker: {
      label: "Leaders / Organizers / Presenters",
      required: true,
      type: "text",
    },
    newChurchPlant: {
      label: "Is this a new church plant?",
      required: true,
      type: "select",
      selections: "yes,no",
    },
    labelCommunityEvents: {
      label: "Community Events (max: $400)",
      type: "label",
      isSection: true,
    },
    communityEventsDescription: {
      label: "Pre-Work/Ongoing",
      type: "text",
    },
    communityEventsDates: {
      label: "Dates (Days, Weekends; Weeks, etc)",
      type: "text",
    },
    communityEventsRemarks: {
      label: "Provide Details",
      type: "textarea",
    },
    labelHealthEvents: {
      label: "Health Events (max: $400)",
      type: "label",
      isSection: true,
    },
    healthEventsDescription: {
      label: "Pre-Work/Ongoing",
      type: "text",
    },
    healthEventsDates: {
      label: "Dates (Days, Weekends; Weeks, etc)",
      type: "text",
    },
    healthEventsRemarks: {
      label: "Provide Details",
      type: "textarea",
    },
    labelMeetConnectActivities: {
      label: "Meet-n-Connect Activities (max: $400)",
      type: "label",
      isSection: true,
    },
    meetConnectActivitiesDescription: {
      label: "Pre-Work/Ongoing",
      type: "text",
    },
    meetConnectActivitiesDates: {
      label: "Dates (Days, Weekends; Weeks, etc)",
      type: "text",
    },
    meetConnectActivitiesRemarks: {
      label: "Provide Details",
      type: "textarea",
    },
    labelRelateConnectEvents: {
      label: "Relate-n-Connect Events (max: $400)",
      type: "label",
      isSection: true,
    },
    relateConnectEventsDescription: {
      label: "Pre-Work/Ongoing",
      type: "text",
    },
    relateConnectEventsDates: {
      label: "Dates (Days, Weekends; Weeks, etc)",
      type: "text",
    },
    relateConnectEventsRemarks: {
      label: "Provide Details",
      type: "textarea",
    },
    labelMediaActivities: {
      label: "Media Activities (max: $400)",
      type: "label",
      isSection: true,
    },
    mediaActivitiesDescription: {
      label: "Pre-Work/Ongoing",
      type: "text",
    },
    mediaActivitiesDates: {
      label: "Dates (Days, Weekends; Weeks, etc)",
      type: "text",
    },
    mediaActivitiesRemarks: {
      label: "Provide Details",
      type: "textarea",
    },
    labelSeniorInactiveConnect: {
      label: "Senior/Inactive Connect (max: $400)",
      type: "label",
      isSection: true,
    },
    seniorInactiveDescription: {
      label: "Pre-Work/Ongoing",
      type: "text",
    },
    seniorInactiveDates: {
      label: "Dates (Days, Weekends; Weeks, etc)",
      type: "text",
    },
    seniorInactiveRemarks: {
      label: "Provide Details",
      type: "textarea",
    },
    labelProclaimActivities: {
      label: "Proclaim Activities (max: $600)",
      type: "label",
      isSection: true,
    },
    proclaimActivitiesDescription: {
      label: "Pre-Work/Ongoing",
      type: "text",
    },
    proclaimActivitiesDates: {
      label: "Dates (Days, Weekends; Weeks, etc)",
      type: "text",
    },
    proclaimActivitiesRemarks: {
      label: "Provide Details",
      type: "textarea",
    },
    labelBibleWorker: {
      label: "Bible Worker",
      type: "label",
      isSection: true,
    },
    bibleWorkerDescription: {
      label: "Pre-Work/Ongoing",
      type: "text",
    },
    bibleWorkerDates: {
      label: "Dates (Days, Weekends; Weeks, etc)",
      type: "text",
    },
    bibleWorkerRemarks: {
      label: "Provide Details",
      type: "textarea",
    },

    labelFundsNeeded: {
      label: "",
      type: "label",
      isSection: true,
    },

    dateFundsNeeded: {
      label: "Date funds are needed",
      type: "date",
    },
    labelNote: {
      label:
        "Note: Requests for Evangelism are approved based on the funds available and may affect the desired request. To give you an idea, the policy used for last year was that anything that lasted for a week received $1000.00 and anything that went more than a week received up to $3000 (although there were some exceptions for special projects). The initial payment from the conference is an advance and will be set up as a receivable due from the church. It will not be cleared until an Evangelistic Meeting Report is completed and returned to the office 60 days after the event.",
      type: "label",
    },
  },
};

export let evangelismFundsService = new EntryService(evangelismFundsDefinition);
