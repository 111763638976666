/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import EntryService from "./entryService";
import { userService } from "./userService";
import sharedFields from "./sharedFields";

export const eventRequestDefinition = {
  baseUrl: "/requests/eventrequests",
  viewTitle: "Events",
  editTitle: "Edit Event Request",
  createTitle: "New Event Request",
  fieldNames: [
    "userid",
    "label1",
    "type",
    "startDate",
    "endDate",
    "totalDays",
    "comment",
    "labelOutOfDistrict",
    "meetingEvent",
    "location",
    "sponsor",
    "reason",
    "label2",
    "emergencyName",
    "emergencyAddress",
    "emergencyEmail",
    "emergencyPhone",
    "coverage",
  ],
  columnNames: ["userid", "viewDates", "totalDays", "type", "viewWorkflow"],
  workflow: "eventrequest",
  hideNav: true,
  readPermissions: ["[author]", "eventrequest", "admin", "sysadmin"],
  createPermissions: ["pastor", "sysadmin"],
  deletePermissions: ["[author]", "eventrequest", "admin", "sysadmin"],
  writePermissions: ["[author]", "eventrequest", "admin", "sysadmin"],
  fields: {
    viewWorkflow: {
      label: "Workflow",
      type: "workflow",
    },
    viewDates: {
      label: "Dates",
      type: "calculated",
      fields: ["startDate", "endDate"],
      value: (data) => {
        let strDate = "";
        if (data.startDate && data.endDate) {
          let startYear = parseInt(data.startDate.substr(0, 4));
          let startMonth = parseInt(data.startDate.substr(5, 7));
          let startDay = parseInt(data.startDate.substring(8, 10));
          let endYear = parseInt(data.endDate.substr(0, 4));
          let endMonth = parseInt(data.endDate.substr(5, 7));
          let endDay = parseInt(data.endDate.substring(8, 10));
          strDate =
            startMonth +
            "/" +
            startDay +
            "/" +
            startYear +
            " - " +
            endMonth +
            "/" +
            endDay +
            "/" +
            endYear;
        }
        return <React.Fragment>{data && strDate}</React.Fragment>;
      },
    },
    userid: {
      label: "Name",
      required: true,
      type: "select",
      length: 64,
      selections: userService,
      default: "@UserID",
      readonly: true,
    },
    label1: {
      label: "Event Information",
      isSection: true,
      type: "label",
    },
    type: {
      label: "Event",
      type: "select",
      length: 80,
      selections:
        "funeral|Funeral,wedding|Wedding,attendingConference|Attending a Conference,localChurchTrip|Local Church Trip,outofdistrict|Out Of District/Conference",
      required: true,
    },
    startDate: {
      label: "Start Date",
      required: true,
      type: "date",
    },
    endDate: {
      label: "End Date",
      required: true,
      type: "date",
    },
    totalDays: {
      label: "Total Days",
      required: true,
      type: "number",
    },
    comment: {
      label: "Comment",
      required: false,
      type: "text",
      length: 4096,
    },

    labelOutOfDistrict: {
      label: "Specify details for the Out Of District/Conference event",
      type: "label",
      showOnly: "type:outofdistrict",
      isSection: true,
    },
    meetingEvent: {
      label: "Meeting/Event",
      type: "text",
      length: 80,
      required: true,
      showOnly: "type:outofdistrict",
    },
    location: {
      label: "Location",
      type: "text",
      length: 80,
      required: true,
      showOnly: "type:outofdistrict",
    },
    sponsor: {
      label:
        "Sponsor (Atlantic Union Conference, NAD, General Conference, Seminar Company/Church etc)",
      type: "text",
      length: 80,
      required: true,
      showOnly: "type:outofdistrict",
    },
    reason: {
      label: "Reason for request to attend/participate",
      type: "text",
      length: 80,
      required: true,
      showOnly: "type:outofdistrict",
    },

    label2: {
      label: "For emergency I can be contacted through",
      isSection: true,
      type: "label",
    },
    emergencyName: {
      label: "Name",
      type: "text",
      length: 255,
    },
    emergencyAddress: {
      label: "Address",
      type: "text",
      length: 255,
    },
    emergencyEmail: {
      label: "E-Mail",
      type: "text",
      length: 255,
    },
    emergencyPhone: {
      label: "Phone",
      type: "text",
      length: 255,
    },
    coverage: sharedFields.coverage,
  },
};

export let eventRequestService = new EntryService(eventRequestDefinition);
